import { createFeature, createReducer, on } from "@ngrx/store";
import { ClassroomStoreInterface } from "../../interfaces";
import { classroomActions } from "./classroom.action";

const initialState: ClassroomStoreInterface = {
    currentClassroomStep: 1,
    classroomIdsData: null,
    classroomData: null,
    classrooms: null,
};

const classroomFeature = createFeature({
    name: "classroom",
    reducer: createReducer(
        initialState,
        on(classroomActions.clearClassroomState, () => initialState),
        on(classroomActions.setCurrentClassroomStep, (state, action) => ({
            ...state,
            currentClassroomStep: action.stepNumber,
        })),
        on(classroomActions.nextClassroomStep, (state) => ({
            ...state,
            currentClassroomStep: state.currentClassroomStep + 1,
        })),
        on(classroomActions.previousClassroomStep, (state) => ({
            ...state,
            currentClassroomStep: state.currentClassroomStep - 1,
        })),
        on(classroomActions.setClassrooms, (state, action) => ({
            ...state,
            classrooms: action.data,
        })),
        on(classroomActions.saveClassroomIdsData, (state, action) => {
            return {
                ...state,
                classroomIdsData: {
                    classroom: action?.data?.classroom || action?.data?.division?.id || "",
                    currentClassroomStep: +(
                        action.data?.currentClassroomStep ||
                        state.classroomIdsData?.currentClassroomStep ||
                        1
                    ),
                },
            };
        }),

        on(classroomActions.setClassroomData, (state, action) => ({
            ...state,
            classroomData: {
                ...state.classroomData,
                ...action.data,
            },
        })),
        on(classroomActions.getClassroomData, (state, action) => ({
            ...state,
        })),
    ),
});

export const {
    name: classroomFeatureKey,
    reducer: classroomReducer,
    selectCurrentClassroomStep,
    selectClassroomIdsData,
    selectClassroomData,
    selectClassrooms,
} = classroomFeature;
