import { CommonModule, Location } from "@angular/common";
import { Component } from "@angular/core";
import { ButtonDirective } from "src/app/core/directives";

@Component({
    selector: "sms-mobile-header",
    standalone: true,
    imports: [CommonModule, ButtonDirective],
    templateUrl: "./mobile-header.component.html",
    styleUrls: ["./mobile-header.component.scss"],
})
export class MobileHeaderComponent {
    constructor(public location: Location) {}
}
