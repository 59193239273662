<div class="flex justify-between items-center">
    <sms-search-input class="lg:w-[400px] w-full my-auto" [control]="searchValue" />
    <button
        class="lg:flex hidden my-auto"
        [fullWidth]="false"
        [size]="'small'"
        [loading]="isLoading$ | async"
        (click)="onConfirm()"
        smsButton
    >
        Confirm
    </button>
</div>
<div class="flex justify-start items-center gap-5 my-4 py-2 px-3">
    <div class="flex justify-start items-center gap-2">
        <div class="w-[6px] h-[6px] bg-error rounded-full"></div>
        <div class="text-sm font-satoshi text-gray-800">
            Absent - <span class="font-semibold">{{ absentCount }}</span>
        </div>
    </div>
    <div class="flex justify-start items-center gap-2">
        <div class="w-[6px] h-[6px] bg-success rounded-full"></div>
        <div class="text-sm font-satoshi text-gray-800">
            Present - <span class="font-semibold">{{ data.length - absentCount }}</span>
        </div>
    </div>
</div>
<div
    class="flex flex-col overflow-y-auto"
    [ngStyle]="{
        height: 'calc(100vh - 600px)',
    }"
>
    <ng-container *ngIf="isDataGetLoading; else elseTemplate">
        <div class="h-full w-full flex justify-center items-center">
            <p-progressSpinner
                styleClass="w-20 h-20"
                strokeWidth="4"
                fill="transparent"
                animationDuration=".5s"
            ></p-progressSpinner>
        </div>
    </ng-container>
    <ng-template #elseTemplate>
        <div
            class="flex justify-between items-center border-b border-b-gray-100 px-2 py-2"
            *ngFor="let item of filterdData; let index = index"
        >
            <div class="flex justify-start items-center gap-3 max-w-[calc(100%_-_66px)]">
                <sms-avatar
                    photo="{{ item.student.student_detail.photo }}"
                    fallbackLabel="{{ item.student.student_detail.full_name }}"
                    size="normal"
                ></sms-avatar>
                <p class="font-satoshi font-medium text-base text-gray-800 leading-4 truncate">
                    {{ item.student.student_detail.full_name }}
                </p>
            </div>
            <div
                class="flex justify-end items-center gap-2 w-[55px] font-satoshi font-medium text-sm"
                [ngClass]="{
                    'text-success': item.is_present,
                    'text-error': !item.is_present,
                }"
            >
                <sms-switch [checked]="item.is_present" (onChange)="onChange($event, index)"></sms-switch>
                {{ item.is_present ? "P" : "A" }}
            </div>
        </div>
    </ng-template>
</div>
<div class="flex justify-between items-center gap-2 mt-6 lg:hidden">
    <button class="w-full" [loading]="isLoading$ | async" (click)="onConfirm()" smsButton>Confirm</button>
</div>
