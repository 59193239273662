import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CreateUserInterface } from 'src/app/shared/interfaces';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
  deps: [HttpClient],
})
export class UserService {
  constructor(private httpClient: HttpClient) {}

  create(data: CreateUserInterface): Observable<CreateUserInterface> {
    return this.httpClient.post<CreateUserInterface>(
      environment.API_URL + '/accounts/admin-users/create/',
      data
    );
  }

  update(
    data: CreateUserInterface,
    id: string
  ): Observable<CreateUserInterface> {
    return this.httpClient.put<CreateUserInterface>(
      environment.API_URL + `/accounts/admin-users/${id}/`,
      data
    );
  }
}
