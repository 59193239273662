import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  CreateSectionInterface,
  SectionInterface,
} from 'src/app/shared/interfaces';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SectionService {
  constructor(private httpClient: HttpClient) {}

  getAll(): Observable<SectionInterface[]> {
    return this.httpClient.get<SectionInterface[]>(
      environment.API_URL + '/sections/'
    );
  }

  create(data: CreateSectionInterface): Observable<CreateSectionInterface> {
    return this.httpClient.post<CreateSectionInterface>(
      environment.API_URL + '/schools/admin/section-config/create/',
      data
    );
  }

  update(
    data: CreateSectionInterface,
    id: string
  ): Observable<CreateSectionInterface> {
    return this.httpClient.put<CreateSectionInterface>(
      environment.API_URL + `/schools/admin/section-config/${id}/`,
      data
    );
  }
}
