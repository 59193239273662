<div class="border border-solid border-gray-200 rounded-md px-3 py-4 relative">
  <ng-container *ngIf="!control.disabled; else elseTemplate">
    <div
      class="w-full h-full cursor-pointer border-2 border-dashed border-primary bg-primary-light rounded-[0.25rem] p-3 flex justify-center items-center flex-col"
      (click)="input.click()"
      [class.!border-error]="isError()"
      [class.!bg-red-50]="isError()"
      fileDragDrop
      (fileDropped)="onFileDropped($event)"
      [class.opacity-50]="control.disabled"
      [class.!cursor-auto]="control.disabled"
    >
      <input
        hidden
        type="file"
        #input
        [accept]="accept"
        [multiple]="multiple"
        [disabled]="control.disabled"
      />
      <i
        class="ph-fill ph-upload-simple text-lg text-primary"
        [class.!text-error]="isError()"
      ></i>
      <p
        class="text-base text-primary text-center"
        [class.!text-error]="isError()"
      >
        {{ helpText }}
      </p>
    </div>
    <label
      class="text-gray-400 text-xs font-medium absolute bg-background -top-2 left-4 transform-center px-[5px]"
      *ngIf="label"
      >{{ label }}
      <span *ngIf="isRequired" class="text-error">*</span>
    </label>
    <sms-display-validation-error
      [control]="control"
      [label]="label"
      *ngIf="isError()"
    ></sms-display-validation-error>
  </ng-container>
  <ng-template #elseTemplate>
    <label
      class="text-gray-400 text-xs font-medium absolute bg-background -top-2 left-4 transform-center px-[5px]"
      *ngIf="label"
      >{{ label }}
    </label>
  </ng-template>

  <sms-document-viewer
    [files]="control.value"
    class="mt-2"
    [hideDelete]="control.disabled"
    (removeFile)="removeFile($event)"
  ></sms-document-viewer>
</div>
