import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Message } from 'primeng/api';
import { NavigationExtras } from '@angular/router';
import { ErrorResponse } from '../../interfaces/other/response';
import { DeleteItemInterface, onClose } from '../../interfaces/';

export const appEventActions = createActionGroup({
  source: 'app-event',
  events: {
    'start loading': emptyProps(),
    'end loading': emptyProps(),
    'start full page loading': emptyProps(),
    'end full page loading': emptyProps(),
    'delete item': props<{ data: DeleteItemInterface; onClose: onClose }>(),
    'add toast': props<Message>(),
    redirect: props<{ navigate: any[]; otherOptions?: NavigationExtras }>(),
    'handle error': props<ErrorResponse>(),
    'toggle sidebar': emptyProps(),
    'empty action': emptyProps(),
    'close dialog': emptyProps(),
  },
});
