import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, forwardRef } from "@angular/core";
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { ButtonDirective, FormInputContorlDirective, SizeOptions } from "src/app/core/directives";

@Component({
    selector: "sms-file-upload-button",
    standalone: true,
    imports: [CommonModule, ButtonDirective, ReactiveFormsModule],
    templateUrl: "./file-upload-button.component.html",
    styleUrls: ["./file-upload-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FileUploadButtonComponent),
            multi: true,
        },
    ],
})
export class FileUploadButtonComponent<T> extends FormInputContorlDirective<T> {
    @Input() variant: any = "contained";
    @Input() accept: string;
    @Input() size: SizeOptions = "large";
    @Input() multiple: boolean;
    @Input() loading?: boolean;

    @ViewChild("inputField") inputField: ElementRef;

    openFileInput() {
        this.inputField.nativeElement.click();
    }

    onChange(event: Event) {
        let files = (event.target as HTMLInputElement).files;

        if (files) {
            const file = !this.multiple ? files.item(0) : Object.values(files);
            this.control.setValue(file);
        }
        this.inputField.nativeElement.value = "";
    }
}
