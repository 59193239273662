import { CommonModule } from "@angular/common";
import { Component, Input, type OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ButtonDirective } from "src/app/core/directives";
import { lovsActions } from "src/app/shared/store";

@Component({
    selector: "sms-export-excel",
    standalone: true,
    imports: [CommonModule, ButtonDirective],
    templateUrl: "./export-excel.component.html",
    styleUrls: ["./export-excel.component.scss"],
})
export class ExportExcelComponent implements OnInit {
    @Input() url: string;
    @Input() buttonClass: string;
    @Input() iconClass: string;
    @Input() fileName: string;
    isLoading: boolean = false;

    constructor(private store: Store) {}

    onClick() {
        this.isLoading = true;
        this.store.dispatch(
            lovsActions.downloadSampleExcelFile({
                data: { url: this.url, fileName: this.fileName },
                onSuccess: () => {
                    this.isLoading = false;
                },
            }),
        );
    }

    ngOnInit(): void {}
}
