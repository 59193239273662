import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateFeesInterface, FeesInterface } from '../../../interfaces';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
  deps: [HttpClient],
})
export class FeesService {
  constructor(private httpClient: HttpClient) {}

  getAdmissionFees(admissionId: string): Observable<FeesInterface> {
    return this.httpClient.get<FeesInterface>(
      environment.API_URL + '/schools/fees-for-admission/' + admissionId
    );
  }

  create(data: CreateFeesInterface): Observable<CreateFeesInterface> {
    return this.httpClient.post<CreateFeesInterface>(
      environment.API_URL + '/schools/admin/fees-config/create/',
      data
    );
  }

  // update(
  //   data: CreateFeesInterface,
  //   id: string
  // ): Observable<CreateFeesInterface> {
  //   return this.httpClient.post<CreateFeesInterface>(
  //     environment.API_URL + `/schools/admin/fees-config/${id}/`,
  //     data
  //   );
  // }
}
