import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef } from "@angular/core";
import { MenuItem } from "primeng/api";
import { TabMenuModule } from "primeng/tabmenu";
import { NoDataComponent } from "../no-data/no-data.component";

@Component({
    selector: "sms-tab-menu",
    standalone: true,
    imports: [CommonModule, TabMenuModule, NoDataComponent],
    templateUrl: "./tab-menu.component.html",
    styleUrls: ["./tab-menu.component.scss"],
})
export class TabMenuComponent implements OnChanges {
    @Input() items: MenuItem[] = [];
    @Input() tabsBody: TemplateRef<any>;
    activeItem: MenuItem | undefined;
    @Output() onTabChange: EventEmitter<MenuItem> = new EventEmitter();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["items"] && changes["items"].currentValue) {
            this.activeItem = changes["items"].currentValue[0];
        }
    }

    onActiveItemChange(event: MenuItem) {
        this.activeItem = event;

        if (this.onTabChange) {
            this.onTabChange.emit(this.activeItem);
        }
    }
}
