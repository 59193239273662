<div class="flex justify-between relative items-center w-full">
    <button
        class="absolute top-1/2 left-0 -translate-y-1/2 border-0 hover:bg-slate-100 bg-transparent p-2 rounded-full flex justify-center items-center cursor-pointer"
        (click)="location.back()"
        style="transform: translateY(-50%)"
        smsIconButton
    >
        <i class="ph ph-arrow-left text-2xl text-gray-300"></i>
    </button>
    <h1 class="text-heading text-base font-satoshi font-bold text-center w-full">
        <ng-content></ng-content>
    </h1>
</div>
