import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { Store } from "@ngrx/store";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ButtonDirective } from "src/app/core/directives";
import { appEventActions, selectIsMutationLoading } from "src/app/shared/store";
import { DividerComponent } from "../../divider/divider.component";

@Component({
    selector: "sms-delete-dialog",
    standalone: true,
    imports: [CommonModule, ButtonDirective, DividerComponent],
    templateUrl: "./delete-dialog.component.html",
    styleUrls: ["./delete-dialog.component.scss"],
})
export class DeleteDialogComponent {
    name: string;
    message: string;
    isLoading$ = this.store.select(selectIsMutationLoading);

    constructor(
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.name = this.config.data.name;
        this.message = this.config.data.message;
    }

    onClose = (success: boolean = false) => {
        this.ref.close(success);
    };

    onConfirm() {
        this.store.dispatch(
            appEventActions.deleteItem({
                data: {
                    url: this.config.data.url,
                    id: this.config.data.id,
                    name: this.config.data.name,
                },
                onClose: this.onClose,
            }),
        );
    }
}
