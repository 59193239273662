<div
    class="border-gray-100 border-solid border-0 p-2 flex flex-col gap-4"
    [routerLink]="isCard ? [teacher ? '/teacher/homework/' : '/student/homework/', item?.id] : []"
    [ngClass]="{ 'border-b-[1px] hover:bg-primary-light cursor-pointer': isCard }"
>
    <div class="flex justify-between items-start gap-2">
        <div class="flex justify-start items-start gap-2">
            <div class="flex justify-center items-center gap-2/5 p-2 bg-primary-light rounded-m w-12">
                <i
                    class="ph-duotone {{
                        item?.type === 'Resource' ? 'ph-book-open-text' : 'ph-notepad'
                    }} text-primary text-2xl"
                ></i>
            </div>
            <div class="flex flex-col gap-1">
                <p class="text-error m-0 font-semibold font-satoshi text-xs" *ngIf="item?.class_subject?.subject">
                    {{ item?.class_subject?.subject?.name }}
                </p>

                <h1
                    class="font-satoshi font-bold text-base leading-5 text-heading"
                    [ngClass]="{ 'line-clamp-1': isCard }"
                >
                    {{ item?.homework_id }} : {{ item?.title }}
                </h1>
                <p
                    class="m-0 text-xs font-medium leading-3 line-clamp-1 font-satoshi text-placeholder"
                    *ngIf="isCard"
                    [innerHtml]="item?.description"
                ></p>
                <p class="m-0 text-[10px] font-medium leading-3 text-primary" *ngIf="!isCard && item">
                    {{ item.created_on | dateTime }}
                </p>
                <div
                    class="mt-3 flex items-center text-success bg-success-light px-[8px] py-[2px] gap-1 rounded-md small-chip w-fit"
                    *ngIf="isCard && item && !!item?.attachments?.length"
                >
                    <i class="ph-duotone ph-paperclip text-sm"></i>
                    <p class="text-[10px] my-0">{{ item.attachments.length }} Attachments</p>
                </div>
            </div>
        </div>
        <div class="flex justify-center items-center gap-2/5 p-2 bg-primary-light rounded-m w-12" *ngIf="isCard">
            <i class="ph-fill ph-caret-right text-primary text-2xl"></i>
        </div>
    </div>
</div>
