import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import {
    AttendanceInterface,
    AttendanceStoreInterface,
    StudentMonthlyAttendanceInterface,
    TeacherAttendanceChangeRequestInterface,
    TeacherAttendanceInterface,
} from "../../interfaces";
import { attendanceActions } from "./attendance.action";

const initialState: AttendanceStoreInterface = {
    studentAttendancesCache: {},
    classroomAttendanceStatus: {},
    studentMonthlyAttendanceCache: {},
    teacherAttendanceCache: {},
    teachersAttendanceStatus: {},
    teachersAttendanceCrsCache: {},
};

const attendanceFeature = createFeature({
    name: "attendance",
    reducer: createReducer(
        initialState,
        on(attendanceActions.setStudentAttandanceData, (state, action) => ({
            ...state,
            studentAttendancesCache: {
                ...state.studentAttendancesCache,
                [action.data.params]: action.data.data,
            },
        })),
        on(attendanceActions.setClassroomAttandanceStatusData, (state, action) => ({
            ...state,
            classroomAttendanceStatus: {
                ...action.data,
            },
        })),

        on(attendanceActions.markStudentAttandanceDateStatus, (state, action) => ({
            ...state,
            classroomAttendanceStatus: {
                ...state.classroomAttendanceStatus,
                [action.date]: true,
            },
        })),
        on(attendanceActions.setStudentMonthlyAttandance, (state, action) => ({
            ...state,
            studentMonthlyAttendanceCache: {
                ...state.studentMonthlyAttendanceCache,
                [action.data.params]: action.data.data,
            },
        })),
        on(attendanceActions.setTeacherAttenanceForDate, (state, action) => ({
            ...state,
            teachersAttendanceStatus: {
                ...state.teachersAttendanceStatus,
                [action.data.params]: action.data.data,
            },
        })),
        on(attendanceActions.setTeachersAttendanceStatus, (state, action) => ({
            ...state,
            teachersAttendanceStatus: {
                ...state.teachersAttendanceStatus,
                ...action.data,
            },
        })),
        on(attendanceActions.setTeachersAttendanceCrs, (state, action) => ({
            ...state,
            teachersAttendanceCrsCache: {
                ...state.teachersAttendanceCrsCache,
                ...action.data,
            },
        })),
    ),
});

export const {
    name: attendanceFeatureKey,
    reducer: attendanceReducer,
    selectStudentAttendancesCache,
    selectClassroomAttendanceStatus,
    selectStudentMonthlyAttendanceCache,
    selectTeacherAttendanceCache,
    selectTeachersAttendanceStatus,
    selectTeachersAttendanceCrsCache,
} = attendanceFeature;

export const selectStudentAttendanceByData = (params: string) =>
    createSelector(
        selectStudentAttendancesCache,
        (data: { [key: string]: AttendanceInterface[] }) => data[params] || [],
    );

export const selectStudentMonthlyAttendance = (params: string) =>
    createSelector(
        selectStudentMonthlyAttendanceCache,
        (data: { [key: string]: StudentMonthlyAttendanceInterface }) => data[params] || [],
    );

export const selectTeacherAttendance = (params: string) =>
    createSelector(selectTeachersAttendanceStatus, (data: { [key: string]: TeacherAttendanceInterface }) => {
        return data[params] || null;
    });
export const selectTeacherAttendanceCr = (params: string) =>
    createSelector(
        selectTeachersAttendanceCrsCache,
        (data: { [key: string]: TeacherAttendanceChangeRequestInterface }) => {
            return data[params] || null;
        },
    );

export const selectAttendanceStatus = createSelector(
    selectClassroomAttendanceStatus,
    selectTeachersAttendanceStatus,
    (classroomAttendanceStatus, teachersAttendanceStatus) => ({
        classroomAttendanceStatus,
        teachersAttendanceStatus,
    }),
);
