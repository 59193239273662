import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { selectUserData } from "src/app/core/stores/auth/auth.reducer";
import { HomeworkStoreInterface } from "../../interfaces";
import { homeworkActions } from "./homework.action";
const initailState: HomeworkStoreInterface = {
    homeworksCache: {},
    homework: null,
};

const homeworkFeature = createFeature({
    name: "homework",
    reducer: createReducer(
        initailState,
        on(homeworkActions.setHomework, (state, action) => {
            return {
                ...state,
                homeworksCache: {
                    ...state.homeworksCache,
                    [action.data.id]: action.data,
                },
                homework: action.data,
            };
        }),
    ),
});

export const {
    name: homeworkFeatureKey,
    reducer: homeworkReducer,
    selectHomework,
    selectHomeworksCache,
} = homeworkFeature;

export const selectHomeworkWithUser = createSelector(selectHomework, selectUserData, (homeworkData, studentData) => ({
    homework: homeworkData,
    student: studentData,
}));
