import { CommonModule } from "@angular/common";
import { RouterLink } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";

import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";

import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";

import { ButtonDirective } from "src/app/core/directives";
import { UppercaseDirective } from "src/app/shared/directive";

import { TextareaComponent, TextInputComponent } from "src/app/shared/components/ui";

import {
    admissionActions,
    selectAdmissionData,
    selectAdmissionIdsData,
    selectIsMutationLoading,
} from "src/app/shared/store";

import { PostAdmissionStepResponse, PostSupplementryInfoInterface } from "src/app/shared/interfaces";

@Component({
    selector: "sms-supplementry-info",
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TextareaComponent,
        TextInputComponent,
        RouterLink,
        ButtonDirective,
        UppercaseDirective,
    ],
    templateUrl: "./supplementry-info.component.html",
    styleUrls: ["./supplementry-info.component.scss"],
})
export class SupplementryInfoComponent implements OnInit, OnChanges, OnDestroy {
    @Input() preview = false;
    formGroup!: FormGroup;
    admissionIdsData: PostAdmissionStepResponse | null = null;
    isLoading$ = this.store.select(selectIsMutationLoading);

    subscription: Subscription = new Subscription();
    constructor(
        private formBuilder: FormBuilder,
        private store: Store,
    ) {
        this.formGroup = this.formBuilder.group({
            previous_school: new FormControl(""),
            previous_class: new FormControl("", Validators.maxLength(10)),
            reason_of_leaving: new FormControl(""),
            history_of_disease: new FormControl(""),
            allergy_details: new FormControl(""),
            is_update: false,
        });
    }

    ngOnInit(): void {
        scrollTo(0, 0);
        this.subscription.add(
            this.store.select(selectAdmissionIdsData).subscribe((value) => (this.admissionIdsData = value)),
        );
        this.getStepData();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["preview"].currentValue) {
            if (this.formGroup) {
                this.formGroup.disable();
            }
        }
    }

    onSave() {
        if (this.preview) {
            this.store.dispatch(admissionActions.nextStep());
            return;
        }

        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            const data: PostSupplementryInfoInterface = {
                step: 3,
                admission: this.admissionIdsData!.admission,
                student: this.admissionIdsData!.student,
                supplementary_data: this.formGroup.getRawValue(),
                is_update: this.formGroup.getRawValue().is_update,
            };
            this.store.dispatch(admissionActions.saveSupplementryInfo({ data }));
        }
    }

    onBack() {
        this.store.dispatch(admissionActions.previousStep());
    }

    getStepData() {
        this.store.dispatch(admissionActions.getAdmissionData({ stepNumber: 3 }));
        this.subscription.add(
            this.store.select(selectAdmissionData).subscribe((value) => {
                if (value) {
                    this.formGroup.patchValue({
                        ...value,
                        is_update: true,
                    });
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
