import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { SchoolConfigItemInterface, ShcoolConfigStoreInterface } from "../../interfaces/school-config";
import { schoolConfigActions } from "./school-config.action";

const initialState: ShcoolConfigStoreInterface = {
    schoolConfigItems: {},
    qrCode: null,
};

const schoolConfigFeature = createFeature({
    name: "school-config",
    reducer: createReducer(
        initialState,
        on(schoolConfigActions.setConfigItemData, (state, action) => ({
            ...state,
            schoolConfigItems: {
                ...state.schoolConfigItems,
                [action.data.params]: action.data.data,
            },
        })),
        on(schoolConfigActions.setQRCode, (state, action) => ({
            ...state,
            qrCode: action.qrCode,
        })),
    ),
});

export const {
    name: schoolConfigFeatureKey,
    reducer: schoolConfigReducer,
    selectSchoolConfigItems,
    selectQrCode,
} = schoolConfigFeature;

export const selectSchoolConfigItem = (params: string) =>
    createSelector(
        selectSchoolConfigItems,
        (data: { [key: string]: SchoolConfigItemInterface }) => data[params] || null,
    );
