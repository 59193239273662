<div class="flex justify-between items-start gap-2">
    <div class="flex justify-start items-center gap-2">
        <div class="p-2 bg-primary-light rounded flex justify-center items-center h-9 w-9">
            <i class="ph-duotone ph-megaphone-simple text-primary text-xl"></i>
        </div>
        <div class="flex flex-col gap-1">
            <h1 class="font-satoshi font-bold text-base leading-5 text-heading">
                {{ item.announcement.title }}
            </h1>
            <p class="m-0 text-[10px] font-medium leading-3 text-primary">
                {{ item.announcement.created_on | dateTime }}
            </p>
        </div>
    </div>

    <div class="flex flex-col items-end">
        <ng-container *ngIf="showRead">
            <div class="text-end" [variant]="item.is_read ? 'success' : 'error'" [size]="'small'" smsChip>
                {{ item.is_read ? "Read" : "Unread" }}
            </div>
        </ng-container>
        <div class="text-placeholder text-xs mt-1 font-satoshi text-end">
            {{ item.announcement.created_by_name }}
        </div>
    </div>
</div>
