<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="grid grid-cols-1 gap-5 mt-3">
        <sms-alert>Note: You can only submit an Attendance Change Request once.</sms-alert>
        <sms-date-time [timeOnly]="true" label="In Time" formControlName="in_time"></sms-date-time>
        <sms-date-time [timeOnly]="true" label="Out Time" formControlName="out_time"></sms-date-time>
        <sms-textarea label="Change Request Comments" formControlName="comments"></sms-textarea>
        <ng-container *ngIf="config.data && config.data.data">
            <div class="flex flex-col gap1" *ngIf="config.data.data.action_by">
                <sms-label> {{ config.data.data.status }} By: </sms-label>
                <div class="text-base font-satoshi text-gray-900">
                    {{ config.data.data.action_by }}
                </div>
            </div>
            <div class="flex flex-col gap1" *ngIf="config.data.data.action_comments">
                <sms-label> Comments: </sms-label>
                <div class="text-base font-satoshi text-gray-900">
                    {{ config.data.data.action_comments }}
                    "sadasdsadsad"
                </div>
            </div>
            <div class="flex justify-start items-center gap-2">
                <sms-label> Status: </sms-label>
                <div [size]="'small'" [variant]="statusVariants[config.data.data.status]" smsChip>
                    {{ config.data.data.status }}
                </div>
            </div>
        </ng-container>
        <sms-alert *ngIf="formGroup.errors && formGroup.errors['timeRangeInvalid']" variant="warning"
            >Out Time should be greater then In Time.</sms-alert
        >
    </div>
    <sms-divider [horizontal]="true"></sms-divider>
    <div class="flex justify-end items-center gap-3">
        <button (click)="onClose()" smsButton size="small" type="button" variant="outlined">Close</button>
        <button *ngIf="!config?.data?.data" [loading]="isLoading$ | async" smsButton size="small" type="submit">
            Send For Approval
        </button>
    </div>
</form>
