import { Directive, ElementRef, HostBinding, Input, OnChanges, Renderer2, SimpleChanges } from "@angular/core";

type Color = "primary" | "primary-light" | string;
type Variant = "outlined" | "contained" | "text";
export type SizeOptions = "small" | "medium" | "large";

const colors: Record<string, string> = {
    "primary-contained": "bg-primary text-white",
    "primary-light-contained": "bg-primary-light text-primary-dark",
    "primary-outlined": "border-primary border-solid !border-1 text-primary bg-background hover:bg-primary-light",
    "primary-light-outlined": "bg-primary-light border-primary text-primary-dark",
    "primary-light-loader": "var(--app-primary-dark)",
    "error-contained": "bg-error text-white",
    "error-outlined": "border-error border-solid !border-1 text-error bg-background",
    "primary-text": "text-primary bg-background",
    "primary-light-text": "bg-background text-primary-dark",
    "primary-contained-loader": "#ffffff",
    "primary-outlined-loader": "var(--app-primary)",
};

const sizes: Record<string, string> = {
    small: "py-2 px-3 text-xs h-[2.25rem]",
    medium: "py-3 px-4 text-sm h-[3.25rem]",
    large: "py-3 px-4 text-sm h-[3.25rem]",
};

const baseClasses =
    "flex justify-center items-center gap-2/5 self-stretch rounded-lg font-black cursor-pointer disabled:opacity-50 disabled:!cursor-default font-satoshi";

function buildClasses(color: Color, variant: Variant, size: SizeOptions, fullWidth: boolean = false) {
    let classess = `${baseClasses} ${colors[`${color}-${variant}`] ? colors[`${color}-${variant}`] : `bg-[${color}]`}`;

    if (sizes[size]) {
        classess += ` ${sizes[size]}`;
    }

    if (fullWidth) {
        classess += " w-full";
    }

    return classess;
}

const getBorderWidth = (variant: Variant) => {
    return (variant === "outlined" ? 1 : 0) + "px";
};

const getLoader = (color: Color, variant: Variant) => {
    return `<svg
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  style="margin: auto; background: none; display: block; shape-rendering: auto;"
  width="25px"
  height="25px"
  viewBox="0 0 100 100"
  preserveAspectRatio="xMidYMid"
>
  <circle
    cx="50"
    cy="50"
    fill="none"
    stroke="${colors[`${color}-${variant}-loader`] || "#ffffff"}"
    stroke-width="7"
    r="37"
    stroke-dasharray="174.35839227423352 60.119464091411174"
  >
    <animateTransform
      attributeName="transform"
      type="rotate"
      repeatCount="indefinite"
      dur="1s"
      values="0 50 50;360 50 50"
      keyTimes="0;1"
    ></animateTransform>
  </circle>
</svg>`;
};

@Directive({
    selector: "[smsButton]",
    standalone: true,
})
export class ButtonDirective implements OnChanges {
    private _color: Color = "primary";
    private _variant: Variant = "contained";
    private _loading: boolean | null | string = null;
    private _size: SizeOptions = "medium";
    private _fullWidth: boolean = false;
    lastDisabled: boolean = false;
    @HostBinding("class")
    classes: string = buildClasses("primary", "contained", "medium", false);

    @HostBinding("style.border-width")
    borderWidth = getBorderWidth("contained");

    constructor(
        private _el: ElementRef,
        private _renderer: Renderer2,
    ) {}

    @Input()
    set color(value: Color) {
        this._color = value;
        this.classes = buildClasses(this._color, this._variant, this._size, this._fullWidth);
    }

    @Input()
    set variant(value: Variant) {
        this._variant = value;
        this.classes = buildClasses(this._color, this._variant, this._size, this._fullWidth);
        this.borderWidth = getBorderWidth(this._variant);
    }

    @Input()
    set loading(value: boolean | null | string) {
        if (value) {
            this.lastDisabled = this._el.nativeElement.disabled;
        }
        this._loading = value;
        console.log(this.lastDisabled);

        this._el.nativeElement.disabled = !!this._loading || this.lastDisabled;
    }

    @Input()
    set fullWidth(value: boolean) {
        this._fullWidth = value;
        this.classes = buildClasses(this._color, this._variant, this._size, this._fullWidth);
    }

    @Input()
    set size(value: SizeOptions) {
        this._size = value;
        this.classes = buildClasses(this._color, this._variant, this._size, this._fullWidth);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["loading"] && typeof changes["loading"].currentValue === "boolean") {
            if (changes["loading"].currentValue) {
                const div = this._renderer.createElement("div");
                div.innerHTML = getLoader(this._color, this._variant);
                this._renderer.appendChild(this._el.nativeElement, div);
            } else {
                if ((this._el.nativeElement as HTMLButtonElement).getElementsByTagName("div")[0]) {
                    this._el.nativeElement.lastChild.remove();
                }
            }
        }
    }
}
