import { CommonModule } from "@angular/common";
import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from "@angular/core";
import { TooltipModule } from "primeng/tooltip";
import { IconButtonDirective } from "src/app/core/directives";
import { getFileType, instanceOf } from "src/app/shared/helpers";
import { AttachmentInterface } from "src/app/shared/interfaces";
import { environment } from "src/environments/environment";
import { HeadingComponent } from "../heading/heading.component";

@Component({
    selector: "sms-document-viewer",
    standalone: true,
    imports: [CommonModule, IconButtonDirective, HeadingComponent, TooltipModule],
    templateUrl: "./document-viewer.component.html",
    styleUrls: ["./document-viewer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentViewerComponent implements OnChanges {
    @Input() files: string[] | AttachmentInterface[];
    @Input() label: string;
    @Input() hideDelete: boolean = false;
    @Output() removeFile: EventEmitter<number> = new EventEmitter();

    docs: any = [];

    typeIcons: Record<string, string> = {
        image: "ph-duotone ph-file-image",
        audio: "ph-duotone ph-file-audio",
        video: "ph-duotone ph-file-video",
        archive: "ph-duotone ph-file-zip",
        code: "ph-duotone ph-file-code",
        word: "ph-duotone ph-file-doc",
        powerpoint: "ph-duotone ph-file-ppt",
        excel: "ph-duotone ph-file-xls",
        pdf: "ph-duotone ph-file-doc",
        text: "ph-duotone ph-file-text",
        other: "ph-duotone ph-file",
        unknown: "ph-duotone ph-file",
    };

    getIconName(file: string) {
        return this.typeIcons[getFileType(file)];
    }

    getFileName(file: string): string {
        return `${file.split("/").pop()}`;
    }

    getFileType(file: string): string {
        return `${file?.split(".").pop()?.toLowerCase()}`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["files"] && changes["files"].currentValue) {
            if (Array.isArray(changes["files"].currentValue) || changes["files"].currentValue instanceof FileList) {
                this.docs = Object.values(changes["files"].currentValue).map((item: any) => {
                    let name = "N/A";
                    let type = "N/A";
                    let fileType = "N/A";
                    let url = null;
                    if (instanceOf<AttachmentInterface>(item, "original_name")) {
                        name = item.original_name;
                        type = item.extension.replace(".", "");
                        fileType = getFileType(type);

                        url = `${environment.MEDIA_FOLDER_URL}/${item.file}`;
                        return {
                            name,
                            type,
                            url,
                            icon: this.typeIcons[getFileType(type)],
                            fileType,
                        };
                    } else {
                        if (item instanceof File) {
                            name = item.name;
                        } else {
                            name = this.getFileName(item);
                            url = `${environment.MEDIA_FOLDER_URL}/${item}`;
                        }
                        type = this.getFileType(name);
                        fileType = getFileType(type);
                        return {
                            name,
                            type,
                            url,
                            icon: this.typeIcons[getFileType(type)],
                            fileType,
                        };
                    }
                });
            } else {
                let name = "N/A";
                let type = "N/A";
                let fileType = "N/A";
                let url = null;
                if (instanceOf<AttachmentInterface>(changes["files"].currentValue, "original_name")) {
                    name = changes["files"].currentValue.original_name;
                    type = changes["files"].currentValue.extension.replace(".", "");
                    fileType = getFileType(type);
                    url = `${environment.MEDIA_FOLDER_URL}/${changes["files"].currentValue.file}`;
                    this.docs = [
                        {
                            name,
                            type,
                            url,
                            icon: this.typeIcons[getFileType(type)],
                            fileType,
                        },
                    ];
                } else if (changes["files"].currentValue instanceof File) {
                    name = changes["files"].currentValue.name;
                } else {
                    name = this.getFileName(changes["files"].currentValue);
                    url = `${environment.MEDIA_FOLDER_URL}/${changes["files"].currentValue}`;
                }
                type = this.getFileType(name);
                fileType = getFileType(type);
                this.docs = [
                    {
                        name,
                        type,
                        url,
                        icon: this.typeIcons[getFileType(type)],
                        fileType,
                    },
                ];
            }
        }
    }

    onRemoveFile(event: MouseEvent, index: number) {
        event.preventDefault();
        this.removeFile.emit(index);
        let values = this.docs;
        values.splice(index, 1);
        this.docs = values;
        console.log(this.docs);
    }
}
