<div class="flex flex-col gap-2 my-5" *ngIf="attendanceStatus$ | async as attendanceStatus">
    <div class="flex justify-end items-center gap-2">
        <sms-export-excel
            *ngIf="!teacher && excelUrl"
            [iconClass]="'!text-primary'"
            [buttonClass]="'!rounded-full !text-gray-800'"
            [url]="getUrl()"
        >
        </sms-export-excel>
        <p-menu #menu1 [appendTo]="'body'" [model]="items" [popup]="true" />
        <div
            class="rounded-full border border-primary flex justify-between items-center gap-2 hover:bg-primary-light py-2 px-3 h-9 cursor-pointer"
            (click)="menu1.toggle($event)"
        >
            <div class="font-satoshi text-gray-800 font-bold text-xs leading-4">
                {{ activeDate | dateTime: "MMMM" }}' {{ activeDate | dateTime: "yy" }}
            </div>
            <i class="ph-fill ph-caret-down text-primary"></i>
        </div>
        <button
            (click)="goToCurrentMonth()"
            smsButton
            size="small"
            type="button"
            variant="contained"
            title="Current Month"
        >
            <i [class]="'ph-bold ph-arrow-clockwise '"></i>
        </button>
    </div>
    <div
        class="flex justify-start items-center gap-[6px] overflow-x-auto pb-1 date-container"
        id="one"
        #scrollContainer
    >
        <div
            class="flex cursor-pointer flex-col justify-center items-center gap-2 p-4 rounded-2xl bg-neutral-100 min-w-[60px] h-[88px]"
            *ngFor="let date of datesData"
            [ngClass]="{
                'bg-primary-light border-[1.5px] border-primary': date.date === activeDate.getDate(),
            }"
            (click)="changeDate(date.fullDate, true)"
        >
            <div
                class="font-satoshi text-2xl leading-4 text-center font-medium text-gray-900"
                [ngClass]="{
                    '!font-black': date.date === activeDate.getDate(),
                }"
            >
                {{ date.date }}
            </div>
            <div class="font-satoshi text-slate-500 text-base font-medium leading-3">{{ date.day }}</div>
            <div
                class="!w-[6px] !h-[6px] rounded-full mt-1"
                *ngIf="!hideStaus"
                [ngClass]="{
                    'bg-success': teacher
                        ? attendanceStatus.teachersAttendanceStatus[date.dateStr]
                        : attendanceStatus.classroomAttendanceStatus[date.dateStr],
                    'bg-error': teacher
                        ? !attendanceStatus.teachersAttendanceStatus[date.dateStr]
                        : !attendanceStatus.classroomAttendanceStatus[date.dateStr],
                }"
            ></div>
        </div>
    </div>
</div>
<ng-container [ngTemplateOutlet]="body" [ngTemplateOutletContext]="{ $implicit: activeDate }"></ng-container>
