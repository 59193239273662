<div class="float-label relative">
    <input
        class="!pr-10"
        id="{{ label }}"
        [type]="passwordVisible ? 'text' : 'password'"
        [min]="min"
        [autocomplete]="'off'"
        [max]="max"
        [placeholder]="placeholder"
        [formControl]="control"
        [class.invalid]="isError()"
        pInputText
    />
    <i
        class="ph text-lg absolute right-2 top-2 text-gray-500 cursor-pointer"
        [class.ph-eye-slash]="passwordVisible"
        [class.ph-eye]="!passwordVisible"
        [ngStyle]="{ opacity: control.disabled ? 0.38 : 1 }"
        (click)="switchVisibility()"
    ></i>

    <sms-label *ngIf="label" [required]="isRequired">{{ label }}</sms-label>
    <sms-help-text *ngIf="!isError() && helpText">{{ helpText }}</sms-help-text>
    <div class="text-neutral-500 text-xs mt-1" *ngIf="!control.valid && control.value.length && strongValidation">
        <div class="block">Password should contain</div>
        <ul class="list-disc mt-1 pl-7">
            <li *ngIf="!control.value?.match('^(?=.*[A-Z])')">At least one uppercase letter.</li>
            <li *ngIf="!control.value?.match('(?=.*[a-z])')">At least one lowercase letter.</li>
            <li *ngIf="!control.value?.match('(.*[0-9].*)')">At least one digit.</li>
            <li *ngIf="!control.value?.match('(?=.*[!@#$%^&*])')">At least one special character.</li>
            <li *ngIf="!control.value?.match('.{8,}')">At least 8 characters long.</li>
        </ul>
    </div>
    <sms-display-validation-error *ngIf="isError()" [control]="control" [label]="label"></sms-display-validation-error>
</div>
