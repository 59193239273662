import { createActionGroup, props } from "@ngrx/store";
import { GetLovDataInterface } from "src/app/shared/interfaces/settings/lovs";
import { onClose } from "../../interfaces";

export const lovsActions = createActionGroup({
    source: "lovs",
    events: {
        "get lov data": props<{ data: GetLovDataInterface }>(),
        "set lov data": props<{ data: any; key: string }>(),
        "download sample excel file": props<{ data: { url: string; fileName?: string }; onSuccess?: () => void }>(),
        "upload sample excel file": props<{ data: { data: FormData; url: string; onClose: onClose } }>(),
        "remove lov data": props<{ key: string }>(),
    },
});
