import { CommonModule, formatDate } from "@angular/common";
import { Component, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { Store } from "@ngrx/store";
import { AutoCompleteModule } from "primeng/autocomplete";
import { InputSwitchModule } from "primeng/inputswitch";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { Subscription, debounceTime } from "rxjs";
import { ButtonDirective } from "src/app/core/directives";
import { AvatarComponent, SearchInputComponent, SwitchComponent } from "src/app/shared/components/ui";
import { BACKEND_DATE_FORMAT } from "src/app/shared/constants";
import { AttendanceInterface } from "src/app/shared/interfaces";
import { attendanceActions, selectIsMutationLoading, selectStudentAttendanceByData } from "src/app/shared/store";
@Component({
    selector: "sms-attendance-list",
    standalone: true,
    imports: [
        CommonModule,
        AvatarComponent,
        SearchInputComponent,
        ButtonDirective,
        InputSwitchModule,
        ReactiveFormsModule,
        SwitchComponent,
        ProgressSpinnerModule,
        AutoCompleteModule,
    ],
    templateUrl: "./attendance-list.component.html",
    styleUrls: ["./attendance-list.component.scss"],
})
export class AttendanceListComponent implements OnChanges, OnInit, OnDestroy {
    @Input() activeItem: any;
    @Input() date: any;

    isDataGetLoading = false;

    absentCount: number = 0;
    data: AttendanceInterface[] = [];
    filterdData: AttendanceInterface[] = [];
    searchValue = new FormControl();

    susbcription: Subscription = new Subscription();

    isLoading$ = this.store.select(selectIsMutationLoading);

    constructor(
        private store: Store,
        @Inject(LOCALE_ID) private localeId: string,
    ) {}

    ngOnInit(): void {
        this.susbcription.add(
            this.searchValue.valueChanges.pipe(debounceTime(300)).subscribe((value) => {
                if (value) {
                    let regExp = new RegExp(value, "i");
                    this.filterdData = this.filterdData.filter((item) =>
                        regExp.test(item.student.student_detail.full_name),
                    );
                } else {
                    this.filterdData = this.data;
                }
            }),
        );
    }

    onConfirm() {
        this.store.dispatch(
            attendanceActions.markStudentAttandance({
                data: {
                    classroom: this.activeItem.id,
                    attendance_date: formatDate(this.date, BACKEND_DATE_FORMAT, this.localeId),
                    absent_count: this.absentCount,
                    present_count: this.data.length - this.absentCount,
                    absent_students: this.filterdData.reduce(
                        (prev: string[], curr) => (!curr.is_present ? [...prev, curr.student.id] : prev),
                        [],
                    ),
                },
            }),
        );
        this.store.dispatch(
            attendanceActions.setStudentAttandanceData({
                data: {
                    data: this.filterdData,
                    params: JSON.stringify({
                        attendance_date: formatDate(this.date, BACKEND_DATE_FORMAT, this.localeId),
                        classroom: this.activeItem.id,
                    }),
                },
            }),
        );
    }

    onChange(value: boolean, index: number) {
        this.filterdData[index].is_present = value;
        if (!value) {
            this.absentCount++;
        } else {
            this.absentCount--;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            (changes["activeItem"] && changes["activeItem"].currentValue) ||
            (changes["date"] && changes["date"].currentValue)
        ) {
            if (this.date || changes["date"].currentValue) {
                this.isDataGetLoading = true;

                let key = {
                    attendance_date: formatDate(
                        this.date || changes["date"].currentValue,
                        BACKEND_DATE_FORMAT,
                        this.localeId,
                    ),
                    classroom: this.activeItem.id,
                };
                this.store.dispatch(
                    attendanceActions.getStudentAttandanceDate({
                        params: key,
                    }),
                );

                this.susbcription.add(
                    this.store.select(selectStudentAttendanceByData(JSON.stringify(key))).subscribe((resp) => {
                        if (resp && resp.length) {
                            this.absentCount = 0;
                            this.data = [];
                            this.filterdData = [];
                            this.data = JSON.parse(JSON.stringify(resp));
                            this.filterdData = this.data;
                            this.searchValue.patchValue("");
                            this.absentCount = this.filterdData.reduce(
                                (prev: number, curr) => (!curr.is_present ? prev + 1 : prev),
                                0,
                            );
                            this.isDataGetLoading = false;
                        }
                    }),
                );
            }
        }
    }

    ngOnDestroy(): void {
        this.susbcription.unsubscribe();
    }
}
