<div class="py-5 text-xs text-gray-800 px-2">
    <div class="flex justify-start items-center gap-5">
        <div class="flex justify-center items-center gap-2/5 p-2 bg-primary-light rounded-m">
            <i class="ph-duotone ph-info text-primary text-2xl"></i>
        </div>
        <div [innerHTML]="config.data.content"></div>
    </div>
</div>
<div class="flex justify-end items-center gap-5">
    <button (click)="onClose()" smsButton size="small" variant="outlined">Close</button>
    <button (click)="onConfirm()" smsButton size="small" variant="contained">Confirm</button>
</div>
