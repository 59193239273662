import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, map, mergeMap, of, switchMap } from "rxjs";
import { StandardService } from "../../../services";
import { appEventActions } from "../../app-event";
import { standardActions } from "./standard.action";

@Injectable()
export class StandardEffects {
    constructor(
        private actions$: Actions,
        private standardService: StandardService,
    ) {}

    getStandards$ = createEffect(() =>
        this.actions$.pipe(
            ofType(standardActions.loadStandards),
            mergeMap(() =>
                this.standardService.getAllStandards().pipe(map((data) => standardActions.setStandards({ data }))),
            ),
        ),
    );

    getStandardsForAdmission$ = createEffect(() =>
        this.actions$.pipe(
            ofType(standardActions.loadStandardsForAdmission),
            mergeMap(() =>
                this.standardService
                    .getStandardsForAdmission()
                    .pipe(map((data) => standardActions.setStandards({ data }))),
            ),
        ),
    );

    getActiveStandards$ = createEffect(() =>
        this.actions$.pipe(
            ofType(standardActions.loadActiveStandards),
            mergeMap(() =>
                this.standardService
                    .getAllActiveStandards()
                    .pipe(map((data) => standardActions.setStandards({ data }))),
            ),
        ),
    );

    getInActiveStandards$ = createEffect(() =>
        this.actions$.pipe(
            ofType(standardActions.loadInactiveStandards),
            mergeMap(() =>
                this.standardService
                    .getAllInActiveStandards()
                    .pipe(map((data) => standardActions.setStandards({ data }))),
            ),
        ),
    );

    getStandardsForFees$ = createEffect(() =>
        this.actions$.pipe(
            ofType(standardActions.loadStandardsForFees),
            mergeMap(() =>
                this.standardService
                    .getStandardsForFees()
                    .pipe(map((data) => standardActions.setStandardsForFees({ data }))),
            ),
        ),
    );

    create$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(standardActions.create),
            switchMap((data) =>
                this.standardService.create(data.data).pipe(
                    switchMap(() => {
                        data.onClose(true);

                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Standard created successfully.",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });

    update$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(standardActions.update),
            switchMap((data) =>
                this.standardService.update(data.data, data.id).pipe(
                    switchMap(() => {
                        data.onClose(true);
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Standard updated successfully.",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });
}
