import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, of, switchMap } from "rxjs";
import { authActions } from "src/app/core/stores";
import { LocalStorageService, TeacherService } from "src/app/shared/services";
import { CURRENT_USER_DATA } from "../../constants";
import { appEventActions } from "../app-event";
import { teacherActions } from "./teacher.action";

@Injectable()
export class TeacherEffects {
    constructor(
        private actions$: Actions,
        private teacherService: TeacherService,
        private localStorageService: LocalStorageService,
    ) {}

    create$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(teacherActions.create),
            switchMap((data) =>
                this.teacherService.create(data.data).pipe(
                    switchMap(() => {
                        data.onClose(true);

                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Teacher created successfully.",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });

    update$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(teacherActions.update),
            switchMap((data) =>
                this.teacherService.update(data.data, data.id).pipe(
                    switchMap(() => {
                        if (!data.isProfileUpdate) {
                            data.onClose && data.onClose(true);
                            return of(
                                appEventActions.addToast({
                                    severity: "success",
                                    detail: "Teacher updated successfully.",
                                }),
                            );
                        }
                        return of(
                            teacherActions.getTeacherData({ id: data.id }),
                            appEventActions.redirect({
                                navigate: ["/teacher/settings"],
                            }),
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Profile updated successfully.",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });

    getTeacherData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(teacherActions.getTeacherData),
            switchMap((data) =>
                this.teacherService.getSingle(data.id).pipe(
                    switchMap((resp) => {
                        this.localStorageService.setItem(CURRENT_USER_DATA, resp);
                        return of(
                            authActions.teacherLoginSuccess({
                                data: resp,
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });

    reAssignTeacher$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(teacherActions.reAssignTeacher),
            switchMap((data) =>
                this.teacherService.reAssignTeacher(data.data).pipe(
                    switchMap(() => {
                        if (data.onClose) {
                            data.onClose(true);
                        }
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Teacher deleted successfully.",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });
}
