import { Injectable } from '@angular/core';
import { SaveAdminAdmissionService } from './save-admin-admission.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class GetAdminAdmissionService extends SaveAdminAdmissionService {
  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
  }
}
