<div class="p-10 flex flex-col h-screen justify-center items-center gap-5">
  <div class="flex flex-col justify-center items-center">
    <div
      class="flex justify-center rounded-full w-28 h-28 items-center bg-green-100"
    >
      <div
        class="bg-green-600 w-16 h-16 flex justify-center items-center rounded-full"
      >
        <i class="ph-bold ph-check text-white text-3xl"></i>
      </div>
    </div>
    <h1 class="text-neutral-800 font-semibold text-2xl font-satoshi mt-3">
      Payment Success!
    </h1>
    <p class="text-neutral-700 text-base font-satoshi text-center">
      Your payment has been successfully done.
    </p>
  </div>
  <div>
    <button
      smsButton
      size="small"
      [fullWidth]="true"
      class="!w-full block"
      [routerLink]="'/'"
    >
      <i class="ph-bold ph-arrow-left"></i>

      Back to Home
    </button>
  </div>
</div>
