<div class="grid grid-cols-1 h-full" [class.grid-cols-2]="announcement" [class.!grid-cols-1]="isMobile && announcement">
    <div
        class="flex flex-col gap-4 tb:h-[calc(100vh_-_205px)] h-[calc(100vh_-_126px)] tb:overflow-y-auto tb:px-2 tb:-mt-5"
        [class.hidden]="isMobile && announcement"
    >
        <div class="tb:hidden block">
            <sms-mobile-header>Announcements For Me</sms-mobile-header>
        </div>
        <sms-list-with-pagination
            #listRef
            [key]="'/app/announcements/my/list/'"
            [headerRef]="headerRef"
            [bodyRef]="bodyRef"
        >
        </sms-list-with-pagination>
        <ng-template #headerRef let-data>
            <div
                class="w-full text-primary cursor-pointer text-base flex justify-end items-center gap-2 mb-3"
                [class.!text-gray-400]="data.isAllRead"
                [class.!cursor-default]="data.isAllRead"
                (click)="!data.isAllRead && markAsRead()"
            >
                <i class="ph-bold ph-checks"></i>
                <span class="underline"> Mark all as read </span>
            </div>
        </ng-template>
        <ng-template #bodyRef let-item>
            <sms-announcement-card
                class="mb-3 block"
                [item]="item"
                [sidebar]="true"
                [selectAnnouncementId]="announcement && announcement!.id"
            ></sms-announcement-card>
        </ng-template>
    </div>
    <div class="tb:pl-5 tb:border-l border-gray-200" *ngIf="announcement">
        <div class="tb:hidden block mb-5">
            <sms-mobile-header>
                <div class="flex flex-col justify-center items-center">
                    <span class="!text-heading !font-bold !font-satoshi leading-5">Announcements</span>
                    <span class="text-gray-400 text-xs font-satoshi !font-normal">{{
                        announcement.announcement.title
                    }}</span>
                </div>
            </sms-mobile-header>
        </div>
        <sms-announcement-detail
            class="block h-[calc(100%_-_55px)] tb:h-full"
            [announcement]="announcement"
            [isMobile]="isMobile"
        ></sms-announcement-detail>
    </div>
</div>
