<div class="flex flex-col justify-between h-full" *ngIf="announcement">
    <div class="flex flex-col gap-8">
        <div class="flex flex-col gap-6">
            <sms-announcement-heading [item]="announcement"></sms-announcement-heading>
            <div class="text-sm font-satoshi text-gray-700 leading-4">
                <quill-view-html [content]="announcement.announcement.content" theme="snow"></quill-view-html>
            </div>
            <sms-document-viewer
                class="my-2 block"
                [label]="announcement.announcement.attachments.length + ' Attachment'"
                [files]="announcement.announcement.attachments"
                [hideDelete]="true"
            ></sms-document-viewer>
        </div>
    </div>
    <div class="flex flex-col gap-4 mt-5">
        <button *ngIf="!announcement.is_read && !isTeacher" (click)="markAsRead(announcement.id)" smsButton>
            Mark as Read
        </button>
        <button class="tb:hidden block" [variant]="'outlined'" (click)="clearAnnouncement()" smsButton>Back</button>
    </div>
</div>
