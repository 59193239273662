import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CreateTeacherInterface, TeacherInterface } from "src/app/shared/interfaces";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
    deps: [HttpClient],
})
export class TeacherService {
    constructor(private httpClient: HttpClient) {}

    create(data: FormData): Observable<CreateTeacherInterface> {
        return this.httpClient.post<CreateTeacherInterface>(environment.API_URL + "/admin/teachers/create/", data);
    }

    update(data: FormData, id: string): Observable<CreateTeacherInterface> {
        return this.httpClient.put<CreateTeacherInterface>(environment.API_URL + `/admin/teachers/${id}/`, data);
    }

    reAssignTeacher(data: { old_teacher: string; new_teacher: string }): Observable<unknown> {
        return this.httpClient.put<unknown>(environment.API_URL + `/admin/teachers/re-assign/`, data);
    }

    getSingle(id: string): Observable<TeacherInterface> {
        return this.httpClient.get<TeacherInterface>(environment.API_URL + `/admin/teachers/${id}/`);
    }
}
