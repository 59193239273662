import { CommonModule, formatDate } from "@angular/common";
import { Component, Inject, LOCALE_ID, type OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { ButtonDirective } from "src/app/core/directives";
import { BACKEND_DATE_FORMAT, BACKEND_DATE_TIME_FORMAT } from "src/app/shared/constants";
import { ChipDirective } from "src/app/shared/directive";
import {
    TeacherAttendanceChangeRequestInterface,
    TeacherAttendanceChangeRequestStatusEnum,
    TeacherAttendanceInterface,
} from "src/app/shared/interfaces";
import { attendanceActions, selectIsMutationLoading } from "src/app/shared/store";
import { AlertComponent, DateTimeComponent, DividerComponent, TextareaComponent } from "../../ui";
import { LabelComponent } from "../../ui/label/label.component";

@Component({
    selector: "sms-attendance-change-request-form",
    standalone: true,
    imports: [
        CommonModule,
        TextareaComponent,
        DateTimeComponent,
        ButtonDirective,
        ReactiveFormsModule,
        AlertComponent,
        DividerComponent,
        LabelComponent,
        ChipDirective,
    ],
    templateUrl: "./attendance-change-request-form.component.html",
    styleUrls: ["./attendance-change-request-form.component.scss"],
})
export class AttendanceChangeRequestFormComponent implements OnInit {
    formGroup: FormGroup;
    isCreated = false;

    isLoading$ = this.store.select(selectIsMutationLoading);

    subscription = new Subscription();

    statusVariants: Record<TeacherAttendanceChangeRequestStatusEnum, string> = {
        [TeacherAttendanceChangeRequestStatusEnum.OPEN]: "info",
        [TeacherAttendanceChangeRequestStatusEnum.APPROVED]: "success",
        [TeacherAttendanceChangeRequestStatusEnum.REJECTED]: "error",
    };

    constructor(
        private store: Store,
        private ref: DynamicDialogRef,
        public config: DynamicDialogConfig<{
            data: TeacherAttendanceChangeRequestInterface | null;
            attendance: TeacherAttendanceInterface | null;
        }>,
        private formBuilder: FormBuilder,
        @Inject(LOCALE_ID) private localID: string,
    ) {}

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group(
            {
                attendance_date: new FormControl("", [Validators.required]),
                teacher: new FormControl("", [Validators.required]),
                in_time: new FormControl("", [Validators.required]),
                out_time: new FormControl("", [Validators.required]),
                comments: new FormControl(""),
            },
            {
                validator: (formGroup: AbstractControl): { [key: string]: any } | null => {
                    const inTime = formGroup.get("in_time")?.value;
                    const outTime = formGroup.get("out_time")?.value;

                    if (inTime && outTime && inTime >= outTime) {
                        return { timeRangeInvalid: true };
                    }
                    return null;
                },
            },
        );

        if (this.config.data) {
            if (this.config.data.data) {
                this.formGroup.patchValue({ ...this.config.data.data });
                this.formGroup.disable();
                this.isCreated = true;
            } else {
                this.formGroup.patchValue({ ...this.config.data });
                if (this.config.data.attendance) {
                    this.formGroup.patchValue({
                        in_time: new Date(this.config.data.attendance.in_time),
                        out_time: new Date(this.config.data.attendance.out_time),
                    });
                }
            }
        }
    }

    onSubmit() {
        this.formGroup.markAllAsTouched();

        if (this.formGroup.valid) {
            let data = this.formGroup.getRawValue();

            this.store.dispatch(
                attendanceActions.createAttendanceChangeRequest({
                    data: {
                        ...data,
                        in_time: formatDate(
                            data.in_time.setDate(data.attendance_date.getDate()),
                            BACKEND_DATE_TIME_FORMAT,
                            this.localID,
                        ),
                        out_time: formatDate(
                            data.out_time.setDate(data.attendance_date.getDate()),
                            BACKEND_DATE_TIME_FORMAT,
                            this.localID,
                        ),
                        attendance_date: formatDate(data.attendance_date, BACKEND_DATE_FORMAT, this.localID),
                    },
                    onClose: this.onClose,
                }),
            );
        }
    }

    onClose = (success: boolean = false) => {
        this.ref.close(success);
    };
}
