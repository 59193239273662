import { Directive, HostBinding, Input } from '@angular/core';

const statusClassObject: Record<string, string> = {
  New: 'text-info bg-info-light',
  'In Progress': 'text-warning bg-warning-light',
  Shortlisted: 'text-success bg-success-light',
  Rejected: 'text-error bg-error-light',
  Selected: 'text-success bg-success-light',
  'Under Review': 'text-purple-500 bg-purple-50',
  'Approved for Interview': 'text-purple-500 bg-purple-50',
  Other: 'text-gray-500 bg-gray-50',
  'In Review': 'text-stone-500 bg-stone-100',
};

const baseClasses = 'w-min px-[0.41rem] py-[0.1rem] rounded text-center';

function getClasses(status: string) {
  return `${baseClasses} ${
    statusClassObject[status]
      ? statusClassObject[status]
      : statusClassObject['other']
  }`;
}

@Directive({
  selector: '[smsAdmissionStatus]',
  standalone: true,
})
export class AdmissionStatusDirective {
  _status: string = 'Other';

  @HostBinding('class')
  classes = getClasses('Other');

  @Input()
  set status(value: string) {
    this._status = value;
    this.classes = getClasses(value);
  }
  constructor() {}
}
