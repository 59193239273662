import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription, map, shareReplay } from "rxjs";
import { MobileHeaderComponent } from "src/app/shared/components/ui";
import { instanceOf } from "src/app/shared/helpers";
import { AnnouncementReciverInterface, MyAnnouncementsInterace } from "src/app/shared/interfaces";
import { announcementActions, selectAnnouncement } from "src/app/shared/store";
import { ListWithPaginationComponent } from "../../others/list-with-pagination/list-with-pagination.component";
import { AnnouncementCardComponent } from "../announcement-card/announcement-card.component";
import { AnnouncementDetailComponent } from "../announcement-detail/announcement-detail.component";

@Component({
    selector: "sms-announcements",
    standalone: true,
    imports: [
        CommonModule,
        MobileHeaderComponent,
        AnnouncementCardComponent,
        AnnouncementDetailComponent,
        ListWithPaginationComponent,
    ],
    templateUrl: "./announcements.component.html",
    styleUrls: ["./announcements.component.scss"],
})
export class AnnouncementsComponent implements OnInit, OnDestroy {
    myAnnouncements$: Observable<MyAnnouncementsInterace | null>;
    announcement: AnnouncementReciverInterface | null;
    isMobile: boolean = false;
    subscription: Subscription = new Subscription();

    @ViewChild("listRef") listRef: any;

    constructor(
        private store: Store,
        private breakpointObserver: BreakpointObserver,
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.breakpointObserver
                .observe([Breakpoints.XSmall, Breakpoints.Small])
                .pipe(
                    map((result) => {
                        this.isMobile = result.matches;
                        return result.matches;
                    }),
                    shareReplay(),
                )
                .subscribe((value) => {
                    if (value && !this.isMobile && this.announcement) {
                        this.store.dispatch(announcementActions.setOneAnnouncement({ data: null }));
                    }
                }),
        );

        // this.store.dispatch(announcementActions.getMyAnnouncements({ isMobile: this.isMobile }));
        // this.myAnnouncements$ = this.store.select(selectMyAnnouncements);
        this.subscription.add(
            this.store.select(selectAnnouncement).subscribe((value) => {
                if (value === null || instanceOf<AnnouncementReciverInterface>(value, "announcement")) {
                    this.announcement = value;
                    console.log(this.listRef);
                }
            }),
        );
    }

    markAsRead() {
        this.store.dispatch(
            announcementActions.markAsRead({
                id: "all-read",
                isMobile: this.isMobile,
                queryParams: {
                    pg: this.listRef.pg,
                    limit: this.listRef.limit,
                },
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
