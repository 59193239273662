import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, of, switchMap } from "rxjs";
import { downloadFile } from "src/app/core/helpers";
import { StudentService } from "../../services";
import { appEventActions } from "../app-event";
import { datagridActions } from "../datagrid";
import { studentActions } from "./student.action";

@Injectable()
export class StudentEffects {
    constructor(
        private studentService: StudentService,
        private actions$: Actions,
        private store: Store,
    ) {}

    updateStudent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(studentActions.updateStudent),
            switchMap((data) => {
                return this.studentService.updateStudent(data.data).pipe(
                    switchMap(() => {
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Student details updated successfully",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                );
            }),
        ),
    );

    downloadSampleExcel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(studentActions.downloadSampleExcel),
            switchMap(() => {
                this.store.dispatch(appEventActions.startLoading());
                return this.studentService.downloadSampleExcel().pipe(
                    switchMap((resp) => {
                        downloadFile(resp, "student-bulk-upload.xlsx");
                        return of(studentActions.setStepCount({ count: 2 }), appEventActions.endLoading());
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err), appEventActions.endLoading());
                    }),
                );
            }),
        ),
    );

    uploadStudentsExcel$ = createEffect(() =>
        this.actions$.pipe(
            ofType(studentActions.uploadStudentsExcel),
            switchMap((data) => {
                return this.studentService.uploadStudentsExcel(data.data).pipe(
                    switchMap((resp) => {
                        return of(
                            studentActions.onUploadStudentsSuccess({
                                data: resp.data,
                            }),
                            studentActions.setStepCount({ count: 3 }),
                        );
                    }),
                    catchError((err) => {
                        if (data.onClose) {
                            data.onClose(true);
                        }
                        return of(appEventActions.handleError(err));
                    }),
                );
            }),
        ),
    );

    uploadStudentData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(studentActions.uploadStudentData),
            switchMap((data) => {
                return data.data
                    ? this.studentService.uploadStudentData(data.data).pipe(
                          switchMap(() => {
                              return of(
                                  studentActions.setStepCount({ count: 1 }),
                                  appEventActions.redirect({
                                      navigate: ["/admin/student/all"],
                                  }),
                                  appEventActions.addToast({
                                      detail: "Student data imported successfully",
                                      severity: "success",
                                  }),
                              );
                          }),
                          catchError((err) => {
                              return of(appEventActions.handleError(err));
                          }),
                      )
                    : of(appEventActions.emptyAction());
            }),
        ),
    );

    getStudentData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(studentActions.getStudentData),
            exhaustMap((data) =>
                this.studentService.getSingleStudentData(data?.data?.student_id).pipe(
                    switchMap((response) => {
                        return of(studentActions.setStudentData({ data: response }));
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    downloadLCPDF$ = createEffect(() =>
        this.actions$.pipe(
            ofType(studentActions.downloadLc),
            exhaustMap((data) =>
                this.studentService.downloadLcPDF(data.data).pipe(
                    switchMap((response) => {
                        downloadFile(response.path);
                        data.onClose(true);
                        return of(
                            appEventActions.addToast({
                                detail: `LC downloaded successfully.`,
                                severity: "success",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    downloadBonafidePDF$ = createEffect(() =>
        this.actions$.pipe(
            ofType(studentActions.downloadBonafide),
            exhaustMap((data) =>
                this.studentService.downloadBonafidePDF(data.data).pipe(
                    switchMap((response) => {
                        downloadFile(response.path);
                        data.onClose(true);
                        return of(
                            appEventActions.addToast({
                                detail: ` Bonafide downloaded successfully.`,
                                severity: "success",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    dectivateStudent$ = createEffect(() =>
        this.actions$.pipe(
            ofType(studentActions.dectivateStudent),
            exhaustMap((data) =>
                this.studentService.dectivateStudent(data.data.student_id).pipe(
                    switchMap(() => {
                        return of(
                            appEventActions.addToast({
                                detail: `Student Deactivated Successfully.`,
                                severity: "success",
                            }),
                            datagridActions.getTableData(data.data.datagridParams),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    getStudentLcData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(studentActions.getStudentLcData),
            exhaustMap((data) =>
                this.studentService.getStudentLcData(data.data.student_id).pipe(
                    switchMap((response) => {
                        return of(studentActions.setStudentLcData({ data: response }));
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );
}
