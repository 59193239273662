import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, tap } from "rxjs";
import { appEventActions } from "src/app/shared/store/app-event";

@Injectable()
export class MutationLoadingInterceptor implements HttpInterceptor {
    constructor(private store: Store) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.method !== "GET") {
            this.store.dispatch(appEventActions.startLoading());
        }

        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) {
                        if (request.method !== "GET") {
                            this.store.dispatch(appEventActions.endLoading());
                        } else {
                            this.store.dispatch(appEventActions.endFullPageLoading());
                        }
                    }
                },
                () => {
                    if (request.method !== "GET") {
                        this.store.dispatch(appEventActions.endLoading());
                    } else {
                        this.store.dispatch(appEventActions.endFullPageLoading());
                    }
                },
            ),
        );
    }
}
