<div>
    <p-table
        #pTable
        [(selection)]="selectedData"
        [value]="data"
        [columns]="columns"
        [rowHover]="true"
        [totalRecords]="totalRecords"
        [rows]="rows"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[5, 10, 25, 50]"
        [paginator]="true"
        [filterDelay]="0"
        [lazy]="!!url"
        [scrollable]="scrollable"
        [loading]="loading"
        [showLoader]="true"
        [selectionMode]="checkboxSelection ? 'multiple' : null"
        [dataKey]="dataKey"
        [rowSelectable]="isRowSelectable"
        (onLazyLoad)="loadData($event)"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        responsiveLayout="scroll"
        scrollDirection="both"
        emptymessage="No data to show"
    >
        <ng-template pTemplate="caption">
            <div class="datagrid-header flex justify-between">
                <div class="w-[50%] md:w-[30%]">
                    <sms-text-input
                        (input)="onSearchInputChange($event)"
                        type="text"
                        leftIcon="ph-duotone ph-magnifying-glass"
                        placeholder="Search"
                        pInputText
                    ></sms-text-input>
                    <!-- (input)="pTable.filterGlobal($event.target.value, 'contains')" -->
                </div>
                <div class="flex justify-end items-center flex-wrap">
                    <ng-content select="[extraButtons]"> </ng-content>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th class="datagrid-th w-20" *ngIf="checkboxSelection">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th
                    *ngFor="let column of columns"
                    [class]="'datagrid-th ' + (column.headerClass ? column.headerClass : '')"
                    [style.min-width]="column.width"
                >
                    <div class="flex justify-between items-center w-full h-full">
                        <span>
                            {{ column.label }}
                        </span>
                        <!-- <i class="ph-bold ph-dots-three justify-center items-center text-3xl hover:bg-slate-200 rounded-full hover:text-neutral-900 w-9 h-9 cursor-pointer"
              style="transform: rotate(90deg)"></i> -->
                    </div>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td class="datagrid-td" *ngIf="checkboxSelection">
                    <p-tableCheckbox [disabled]="!_isRowSelectable(rowData)" [value]="rowData"></p-tableCheckbox>
                </td>
                <ng-container
                    [ngTemplateOutlet]="bodyRef ? bodyRef : defaultBody"
                    [ngTemplateOutletContext]="{ $implicit: rowData, columns: columns }"
                ></ng-container>
            </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="columns.length">
                    <div class="flex justify-center items-center w-full h-[350px]">
                        <sms-no-data></sms-no-data>
                    </div>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <ng-template #defaultBody let-rowData>
        <tr>
            <td class="datagrid-td" *ngFor="let column of columns">
                {{
                    column.getValues
                        ? column.getValues(rowData[column.key])
                        : column.type === "date"
                          ? (rowData[column.key] | dateTime: DATE_FROMAT) || "-"
                          : rowData[column.key] || "-"
                }}
            </td>
        </tr>
    </ng-template>
</div>
