import { InMemoryScrollingFeature, InMemoryScrollingOptions, Route, withInMemoryScrolling } from "@angular/router";
import { AdminAuthGuard, AuthGuard, RoleGuard } from "./core/gaurds";
import { AdminContainerComponent, MobileConainerComponent } from "./shared/components";

const scrollConfig: InMemoryScrollingOptions = {
    scrollPositionRestoration: "top",
    anchorScrolling: "enabled",
};

export const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

export const AppRoutes: Route[] = [
    {
        path: "",
        loadChildren: () => import("./features/public/public.routes").then((module) => module.PublicRoutes),
    },
    {
        path: "portal",
        loadChildren: () => import("./features/private/private.routes").then((module) => module.AuthRoutes),
    },
    {
        path: "admin",
        canActivate: [AdminAuthGuard],
        component: AdminContainerComponent,
        loadChildren: () => import("./features/private/private.routes").then((module) => module.AdminRoutes),
    },
    {
        path: "student",
        loadComponent() {
            return MobileConainerComponent;
        },
        canActivate: [AuthGuard, RoleGuard("Student")],
        data: {
            userType: "Student",
        },
        loadChildren: () => import("./features/private/private.routes").then((module) => module.StudentRoutes),
    },
    {
        path: "teacher",
        loadComponent() {
            return MobileConainerComponent;
        },
        canActivate: [AuthGuard, RoleGuard("Teacher")],
        data: {
            userType: "Teacher",
        },
        loadChildren: () => import("./features/private/private.routes").then((module) => module.TeacherRoutes),
    },
    {
        path: "parent",
        loadChildren: () => import("./features/private/private.routes").then((module) => module.ParentRoutes),
    },
];
