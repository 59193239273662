<div
  class="py-2 px-3 flex justify-start items-center gap-3 self-stretch rounded-lg"
  [ngClass]="this.wrapperClasses[this.variant]"
>
  <i
    class="text-base text-inherit"
    [ngClass]="this.iconsClasses[this.variant]"
  ></i>
  <p class="m-0 text-inherit text-xs sm:text-sm font-satoshi">
    <ng-content></ng-content>
  </p>
</div>
