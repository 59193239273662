import { createFeature, createReducer, on } from '@ngrx/store';
import { teacherActions } from './teacher.action';
import { TeacherStoreInterface } from 'src/app/shared/interfaces';

const initailState: TeacherStoreInterface = {
  teachers: [],
};

const teacherFeature = createFeature({
  name: 'teacher',
  reducer: createReducer(
    initailState,
    on(teacherActions.create, (state) => ({ ...state })),
    on(teacherActions.update, (state) => ({ ...state }))
  ),
});

export const { name: teacherFeatureKey, reducer: teacherReducer } =
  teacherFeature;
