import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import {
  AdmissionInterface,
  DownloadInstalmentPDF,
} from 'src/app/shared/interfaces';
import { IDCardInterface } from 'src/app/features/private/portals/student/interfaces';

@Injectable({
  providedIn: 'root',
})
export class GetAdmissionService {
  constructor(protected httpClient: HttpClient) {}

  getStepDetails<T>(stepNumber: number, admission: string): Observable<T> {
    return this.httpClient.get<T>(`${environment.API_URL}/admission/`, {
      params: {
        adm: admission,
        sp: stepNumber,
      },
    });
  }
  findPartialAdmissions(data: any) {
    return this.httpClient.get<AdmissionInterface[]>(
      `${environment.API_URL}/get-partial-admissions/`,
      {
        params: { ...data },
      }
    );
  }

  findSubmitedAdmissions(data: any) {
    return this.httpClient.get<AdmissionInterface[]>(
      `${environment.API_URL}/get-submitted-admissions/`,
      {
        params: data,
      }
    );
  }

  downloadAdmissionForm(form_number: string) {
    return this.httpClient.post<{ path: string }>(
      `${environment.API_URL}/admission-form-print/`,
      { form_number }
    );
  }

  downloadInstalmentRecipt(data: DownloadInstalmentPDF) {
    return this.httpClient.post<{ path: string }>(
      `${environment.API_URL}/print-receipt/`,
      data
    );
  }

  findPartialAdmissionByAdmissionId(admissionId: string) {
    return this.httpClient.get<AdmissionInterface>(
      `${environment.API_URL}/get-detail-by-admissionId/`,
      {
        params: { id: admissionId },
      }
    );
  }

  getAdmissionFeesData<AdmissionFeesInterface>(
    admission: string
  ): Observable<AdmissionFeesInterface> {
    return this.httpClient.get<AdmissionFeesInterface>(
      `${environment.API_URL}/schools/admission-fees-instalment/${admission}/`
    );
  }

  getIdCardData(student_id: string) {
    return this.httpClient.get<IDCardInterface>(
      `${environment.API_URL}/student-id-card/detail/`,
      {
        params: { student_id },
      }
    );
  }
}
