import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { CommonModule, Location } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { RouterOutlet } from "@angular/router";
import {
    bootstrapBellFill,
    bootstrapCaretLeftFill,
    bootstrapQuestionCircleFill,
    bootstrapSliders,
} from "@ng-icons/bootstrap-icons";
import { NgIconComponent, provideIcons } from "@ng-icons/core";
import { Store } from "@ngrx/store";
import { MenuItem } from "primeng/api";
import { BadgeModule } from "primeng/badge";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { MenuModule } from "primeng/menu";
import { PanelMenuModule } from "primeng/panelmenu";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { SidebarModule } from "primeng/sidebar";
import { TreeModule } from "primeng/tree";
import { Observable, Subscription, debounceTime, map, shareReplay } from "rxjs";
import { schoolConfig } from "src/app/core/config";
import { EnvPipe } from "src/app/core/pipes";
import {
    authActions,
    selectAdminUserData,
    selectIsAdminLoggedIn,
    selectMenuItems,
    selectTeacherData,
    selectUserData,
} from "src/app/core/stores";
import { AdminMenus, SCHOOL_LOGO_PATH } from "src/app/shared/constants";
import { selectIsFullPageLoading } from "src/app/shared/store";
import { AvatarComponent } from "../../avatar/avatar.component";
import { PowerdByComponent } from "../../branding";
import { BreadcrumbComponent } from "../../breadcrumb/breadcrumb.component";
import { TextInputComponent } from "../../inputs/text-input/text-input.component";

@Component({
    selector: "sms-admin-container",
    standalone: true,
    imports: [
        CommonModule,
        AvatarComponent,
        PanelMenuModule,
        TextInputComponent,
        NgIconComponent,
        BreadcrumbModule,
        TreeModule,
        BadgeModule,
        RouterOutlet,
        MenuModule,
        BreadcrumbComponent,
        SidebarModule,
        ReactiveFormsModule,
        EnvPipe,
        PowerdByComponent,
        ProgressSpinnerModule,
    ],
    viewProviders: [
        provideIcons({
            bootstrapCaretLeftFill,
            bootstrapQuestionCircleFill,
            bootstrapSliders,
            bootstrapBellFill,
        }),
    ],
    templateUrl: "./admin-container.component.html",
    styleUrls: ["./admin-container.component.scss"],
})
export class AdminContainerComponent implements OnDestroy, OnInit {
    isSidebarOpen: boolean = true;
    schoolName: string = schoolConfig.school_name;
    schoolLogo = SCHOOL_LOGO_PATH;
    items: MenuItem[] = AdminMenus;
    initialItems: MenuItem[] = AdminMenus;
    isAdminLoggedIn: boolean = true;
    userData: any;
    accountMenu: MenuItem[] = [
        {
            label: "Logout",
            icon: "ph-fill ph-sign-out text-[25px] w-[25px] h-[25px] ",
            command: () => {
                this.logOut();
            },
        },
    ];

    searchField: FormControl = new FormControl("");

    isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
        map((result) => {
            this.isSidebarOpen = !result.matches;
            return result.matches;
        }),
        shareReplay(),
    );

    isFullPageLoading$: Observable<boolean | null> = this.store.select(selectIsFullPageLoading);

    subscription: Subscription = new Subscription();

    constructor(
        public location: Location,
        private breakpointObserver: BreakpointObserver,
        private store: Store,
    ) {}

    ngOnInit() {
        this.subscription.add(
            this.store.select(selectMenuItems).subscribe((val) => {
                if (val) {
                    this.initialItems = JSON.parse(JSON.stringify(val));
                    this.items = JSON.parse(JSON.stringify(val));
                }
            }),
        );

        this.subscription.add(
            this.store.select(selectAdminUserData).subscribe((val) => {
                if (val) {
                    this.userData = { name: val.username, subname: val.email };
                }
            }),
        );

        this.subscription.add(
            this.store.select(selectIsAdminLoggedIn).subscribe((val) => {
                this.isAdminLoggedIn = val;
            }),
        );

        this.subscription.add(
            this.store.select(selectUserData).subscribe((val) => {
                if (val) {
                    this.userData = {
                        ...val,
                        name: val.name,
                        subname: val.admissionNumber,
                        photo: val.photo,
                    };
                    this.accountMenu = [
                        ...this.accountMenu,
                        {
                            label: "Add More Account",
                            icon: "ph-plus ph text-[25px] w-[25px] h-[25px] ",
                            routerLink: "/login",
                        },
                    ];
                }
            }),
        );

        this.subscription.add(
            this.store.select(selectTeacherData).subscribe((val) => {
                if (val) {
                    this.userData = {
                        ...val,
                        name: val.full_name,
                        subname: val.employee_id,
                        photo: val.photo,
                    };
                }
            }),
        );

        this.searchField.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            if (!value) {
                this.items = this.initialItems;
            } else {
                this.items = this.filterMenuItems(value, this.initialItems);
            }
        });
    }

    logOut() {
        if (!this.isAdminLoggedIn) {
            this.store.dispatch(authActions.removeUser({ data: this.userData }));
        } else {
            this.store.dispatch(authActions.adminLogout());
        }
    }

    filterMenuItems(query: string, items: MenuItem[]): MenuItem[] {
        return items.reduce((filteredItems: MenuItem[], menuItem) => {
            const filteredItem: MenuItem = {
                label: menuItem.label,
                icon: menuItem.icon,
            };

            if (menuItem.routerLink) {
                filteredItem.routerLink = menuItem.routerLink;
            }

            if (menuItem.items) {
                const filteredNestedItems = this.filterMenuItems(query, menuItem.items);
                if (filteredNestedItems.length > 0) {
                    filteredItem.items = filteredNestedItems;
                }
            }

            if (filteredItem.items && filteredItem.items.length > 0) {
                filteredItems.push(filteredItem);
            }

            if (menuItem.label?.toLowerCase().includes(query.toLowerCase())) {
                if (menuItem.items) {
                    filteredItem.items = menuItem.items;
                }
                filteredItems.push(filteredItem);
            }

            return filteredItems;
        }, []);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
