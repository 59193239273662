import { Injectable } from "@angular/core";
import { Messaging, getToken, onMessage } from "@angular/fire/messaging";
import { Store } from "@ngrx/store";
import { MessageService } from "primeng/api";
import { selectLoggedIn } from "src/app/core/stores";
import { environment } from "src/environments/environment";
import { PushNotificationAPIService } from "./api/";

@Injectable({
    providedIn: "root",
})
export class PushNotificationService {
    initiated: boolean = false;

    constructor(
        private readonly _messaging: Messaging,
        private messageService: MessageService,
        private pushNotificationAPIService: PushNotificationAPIService,
        private store: Store,
    ) {}

    register() {
        this.store.select(selectLoggedIn).subscribe((value) => {
            if (!value.isAdmin && !value.isUser) {
                this.initiated = false;
            }

            if ((value.isAdmin || value.isUser) && !this.initiated) {
                this.initiated = true;
                this._getDeviceToken();
                this._onMessage();
            }
        });
    }

    private _getDeviceToken(): void {
        getToken(this._messaging, { vapidKey: environment.VAPID_KEY })
            .then((token) => {
                console.log(token);
                this.pushNotificationAPIService
                    .updateToken({
                        token,
                    })
                    .subscribe(() => {});
            })
            .catch((error) => console.log("Token error", error));
    }

    private _onMessage(): void {
        if (this._messaging) {
            onMessage(this._messaging, {
                next: (payload) => {
                    console.log(payload.notification);

                    this.messageService.add({
                        summary: payload.notification?.title,
                        detail: payload.notification?.body,
                        icon: payload.notification?.image,
                        severity: "info",
                    });
                },
                error: (error) => console.log("Message error", error),
                complete: () => console.log("Done listening to messages"),
            });
        }
    }
}
