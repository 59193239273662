import { CommonModule } from "@angular/common";
import { Component, Input, forwardRef } from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { NgIconComponent } from "@ng-icons/core";
import { InputMaskModule } from "primeng/inputmask";
import { InputTextModule } from "primeng/inputtext";
import { KeyFilterModule } from "primeng/keyfilter";
import { FormInputContorlDirective } from "src/app/core/directives";
import { DisplayValidationErrorComponent } from "../../display-validation-error/display-validation-error.component";
import { HelpTextComponent } from "../../help-text/help-text.component";
import { LabelComponent } from "../../label/label.component";

type InputType =
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week";

@Component({
    selector: "sms-text-input",
    standalone: true,
    imports: [
        CommonModule,
        InputTextModule,
        NgIconComponent,
        ReactiveFormsModule,
        DisplayValidationErrorComponent,
        InputMaskModule,
        LabelComponent,
        HelpTextComponent,
        FormsModule,
        KeyFilterModule,
    ],
    templateUrl: "./text-input.component.html",
    styleUrls: ["./text-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TextInputComponent),
            multi: true,
        },
    ],
})
export class TextInputComponent<T> extends FormInputContorlDirective<T> {
    @Input() label?: string = "";
    @Input() value?: string;
    @Input() min?: string;
    @Input() max?: string;
    @Input() placeholder?: string = "";
    @Input() disabled: boolean = false;
    @Input() type?: InputType = "text";
    @Input() rightIcon?: string;
    @Input() leftIcon?: string;
    @Input() mask: boolean = false;
    @Input() maskPattern: string = "";
    @Input() helpText: string | null = null;
    @Input() required!: boolean;

    value1: string = "";
}
