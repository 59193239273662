import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  AnnouncementInterface,
  AnnouncementReciverInterface,
  MyAnnouncementsInterace,
} from '../../interfaces';

@Injectable({
  providedIn: 'root',
})
export class AnnouncementService {
  constructor(private httpClient: HttpClient) {}

  getMyAnnouncements(): Observable<MyAnnouncementsInterace> {
    return this.httpClient.get<MyAnnouncementsInterace>(
      environment.API_URL + '/app/announcements/my/list/'
    );
  }

  getOneAnnouncements(id: string): Observable<AnnouncementReciverInterface> {
    return this.httpClient.get<AnnouncementReciverInterface>(
      environment.API_URL + `/app/announcements/my/${id}/`
    );
  }

  getMyAnnouncementsCount(): Observable<{ unread_count: number }> {
    return this.httpClient
      .get<{ unread_count: number }>(
        environment.API_URL + `/app/announcements/my/unread_count/`
      )
      .pipe(shareReplay());
  }

  markAsRead(id: string): Observable<AnnouncementInterface> {
    return this.httpClient.put<AnnouncementInterface>(
      environment.API_URL + `/app/announcements/my/${id}/`,
      {}
    );
  }

  create(data: FormData): Observable<AnnouncementInterface> {
    return this.httpClient.post<AnnouncementInterface>(
      environment.API_URL + '/app/announcements/create/',
      data
    );
  }

  update(data: FormData, id: string): Observable<AnnouncementInterface> {
    return this.httpClient.put<AnnouncementInterface>(
      environment.API_URL + `/app/announcements/${id}/`,
      data
    );
  }
}
