import { createActionGroup, props } from "@ngrx/store";
import { ContentManagementComponentEnum } from "src/app/shared/enum/settings";
import { onClose } from "src/app/shared/interfaces";
import {
    ContentManagementInterface,
    CreateContentManagementInterface,
} from "src/app/shared/interfaces/settings/content-management";

export const contentManagementActions = createActionGroup({
    source: "content-management",
    events: {
        "get content management data": props<{
            component: ContentManagementComponentEnum;
        }>(),
        "set content management data": props<{
            data: {
                key: string;
                data: ContentManagementInterface;
            };
        }>(),
        create: props<{
            data: CreateContentManagementInterface;
            onClose: onClose;
        }>(),
        update: props<{
            data: CreateContentManagementInterface;
            id: string;
            onClose: onClose;
        }>(),
        "generate default content": props<{ onClose: onClose }>(),
    },
});
