import { createActionGroup, props } from '@ngrx/store';
import { DataGridDataKey, ResponseInterface } from 'src/app/shared/interfaces';

export const datagridActions = createActionGroup({
  source: 'datagrid',
  events: {
    'get table data': props<DataGridDataKey>(),
    'set new table data': props<{
      data: ResponseInterface<any[]>;
      key: DataGridDataKey;
    }>(),
  },
});
