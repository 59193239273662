<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="pt-4 flex flex-col gap-4">
        <sms-file-input [multiple]="true" formControlName="assignments" label="Assignments"></sms-file-input>
        <sms-textarea [rows]="3" formControlName="comments" label="Comments"></sms-textarea>
    </div>
    <sms-divider [horizontal]="true"></sms-divider>
    <div class="flex justify-end items-center gap-5">
        <button [size]="'small'" [variant]="'outlined'" (click)="onClose()" type="button" smsButton>Close</button>

        <button
            *ngIf="!formGroup.disabled"
            [loading]="isLoading$ | async"
            [size]="'small'"
            [variant]="'contained'"
            type="submit"
            smsButton
        >
            Submit
        </button>
    </div>
</form>
