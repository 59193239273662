import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { ButtonDirective } from "src/app/core/directives";
import { DateTimePipe } from "src/app/core/pipes";
import { FRONTEND_DATE_TIME_FORMAT } from "src/app/shared/constants";
import { ChipDirective } from "src/app/shared/directive";
import { PaymentMethodEnum, PaymentTypes } from "src/app/shared/interfaces";

import {
    AlertComponent,
    AutocompleteComponent,
    DividerComponent,
    RadioButtonComponent,
} from "src/app/shared/components/ui";

import { schoolConfig } from "src/app/core/config";
import { admissionActions, paymentActions, selectIsMutationLoading, selectOrderData } from "src/app/shared/store";
import { PaymentWarpperComponent } from "../../payment";

@Component({
    selector: "sms-pay-other-charges",
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ButtonDirective,
        RadioButtonComponent,
        DividerComponent,
        AlertComponent,
        ChipDirective,
        AutocompleteComponent,
        ReactiveFormsModule,
        DateTimePipe,
        PaymentWarpperComponent,
    ],
    providers: [DateTimePipe],
    templateUrl: "./pay-other-charges.component.html",
    styleUrls: ["./pay-other-charges.component.scss"],
})
export class PayOtherChargesComponent implements OnInit, OnDestroy {
    dateTimeFormat = FRONTEND_DATE_TIME_FORMAT;
    onlinePaymentEnabled = schoolConfig.online_payment_enabled;

    isLoading$ = this.store.select(selectIsMutationLoading);
    paymentMenthod: FormControl = new FormControl("", [Validators.required]);
    paymentMethodOptions = Object.values(PaymentMethodEnum);
    orderData: any = null;

    subscription: Subscription = new Subscription();

    constructor(
        private store: Store,
        private ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public router: Router,
    ) {
        if (this.config.data.paymentMethod) {
            this.paymentMenthod.patchValue(this.config.data.paymentMethod);
        }
    }

    ngOnInit(): void {
        if (this.onlinePaymentEnabled) {
            if (!this.config.data.is_paid) {
                this.store.dispatch(
                    paymentActions.createOrder({
                        data: {
                            onClose: this.onClose,
                            other_payment_id: this.config.data.id,
                            type: PaymentTypes.OTHER_CHARGE,
                            id: this.config.data.id,
                            payment_method: this.paymentMenthod.value,
                        },
                    }),
                );
            }
        } else {
            this.paymentMethodOptions = this.paymentMethodOptions.filter((item) => item !== PaymentMethodEnum.ONLINE);
        }
        this.subscription.add(
            this.store.select(selectOrderData).subscribe((orderData) => {
                this.orderData = orderData;
            }),
        );
    }

    payFees() {
        this.paymentMenthod.markAllAsTouched();
        if (this.paymentMenthod.valid) {
            if (this.paymentMenthod.getRawValue() !== PaymentMethodEnum.ONLINE) {
                this.store.dispatch(
                    admissionActions.payOtherCharges({
                        onClose: this.onClose,
                        data: {
                            payment_method: this.paymentMenthod.getRawValue(),
                            id: this.config.data.id,
                        },
                    }),
                );
            }
        }
    }

    downloadRecipt() {
        this.store.dispatch(
            admissionActions.downloadInstalmentRecipt({
                data: {
                    charge_id: this.config.data.id,
                    type: "form_fees",
                },
            }),
        );
    }

    onClose = (success: boolean = false) => {
        this.ref.close(success);
    };

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
