import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectIsLoggedIn } from '../stores';
import { map } from 'rxjs';
import { appEventActions } from 'src/app/shared/store';

export const AuthGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  return store.select(selectIsLoggedIn).pipe(
    map((value) => {
      if (!value) {
        store.dispatch(appEventActions.redirect({ navigate: ['/'] }));
        return false;
      }
      return true;
    })
  );
};
