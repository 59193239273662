<div>
    <h5 class="text-active text-sm font-semibold font-satoshi mb-1">
        {{ label ? label : "Uploaded Files" }}
    </h5>
    <div class="flex flex-col gap-2">
        <ng-container *ngIf="docs.length; else elseTemplate">
            <ng-container *ngFor="let item of docs; let index = index">
                <a
                    *ngIf="item.url"
                    href="{{ item.url }}"
                    download="{{ item.name }}"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <div
                        class="cursor-pointer p-2 rounded-lg border border-solid border-gray-100 shadow-xs flex justify-between items-center gap-4 w-full"
                    >
                        <div class="flex justify-start items-center gap-4 min-w-[calc(100%_-_50px)]">
                            <div class="flex justify-center items-center gap-2/5 p-2 px-3 bg-primary-light rounded-m">
                                <i class="{{ item.icon }} text-primary text-xl"></i>
                            </div>
                            <div
                                class="flex justify-center items-start flex-col"
                                [style]="{ width: 'calc(100% - 50px) !important' }"
                            >
                                <h2 class="font-satoshi font-extrabold text-sm text-active truncate w-full">
                                    {{ item.name }}
                                </h2>
                                <p class="font-satoshi font-medium text-xs text-placeholder">
                                    {{ item.fileType }}
                                </p>
                            </div>
                        </div>
                        <button
                            class="border-0 hover:bg-red-50 bg-transparent rounded-full flex justify-center items-center cursor-pointer py-2"
                            *ngIf="!hideDelete"
                            (click)="onRemoveFile($event, index)"
                            smsIconButton
                        >
                            <i class="ph-bold ph-trash text-error text-lg"></i>
                        </button>
                    </div>
                </a>
                <div
                    class="p-2 rounded-lg border border-solid border-gray-100 shadow-xs flex justify-between items-center gap-4 w-full"
                    *ngIf="!item.url"
                >
                    <div class="flex justify-start items-center gap-4 min-w-[calc(100%_-_50px)]">
                        <div class="flex justify-center items-center gap-2/5 p-2 px-3 bg-primary-light rounded-m">
                            <i class="{{ item.icon }} text-primary text-xl"></i>
                        </div>
                        <div
                            class="flex justify-center items-start flex-col"
                            [style]="{ width: 'calc(100% - 80px) !important' }"
                        >
                            <h2 class="font-satoshi font-extrabold text-sm text-active truncate w-full">
                                {{ item.name }}
                            </h2>
                            <p class="font-satoshi font-medium text-xs text-placeholder">
                                {{ item.fileType }}
                            </p>
                        </div>
                    </div>
                    <button
                        class="border-0 hover:bg-red-50 bg-transparent rounded-full flex justify-center items-center cursor-pointer py-2"
                        *ngIf="!hideDelete"
                        (click)="onRemoveFile($event, index)"
                        smsIconButton
                    >
                        <i class="ph-bold ph-trash text-error text-lg"></i>
                    </button>
                </div>
            </ng-container>
        </ng-container>
        <ng-template #elseTemplate> - </ng-template>
    </div>
</div>
