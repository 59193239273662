import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, type OnInit } from "@angular/core";
import { DialogModule } from "primeng/dialog";
import { DividerModule } from "primeng/divider";
import { schoolConfig } from "src/app/core/config";
import { ButtonDirective } from "src/app/core/directives";
import { HeadingComponent } from "../../ui";

@Component({
    selector: "sms-qr-code-viewer",
    standalone: true,
    imports: [CommonModule, DialogModule, ButtonDirective, DividerModule, HeadingComponent],
    templateUrl: "./qr-code-viewer.component.html",
    styleUrls: ["./qr-code-viewer.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QrCodeViewerComponent implements OnInit {
    visible: boolean = false;
    bank_details = schoolConfig.bank_details;
    ngOnInit(): void {}
}
