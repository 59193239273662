import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, of, switchMap, withLatestFrom } from "rxjs";
import { HomeworkService } from "../../services/homework";
import { appEventActions } from "../app-event";
import { homeworkActions } from "./homework.action";
import { selectHomeworksCache } from "./homework.reducer";

@Injectable()
export class HomeworkEffects {
    constructor(
        private actions$: Actions,
        private homeworkService: HomeworkService,
        private _location: Location,
        private store: Store,
    ) {}

    create$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(homeworkActions.create),
            switchMap((data) =>
                this.homeworkService.create(data.data).pipe(
                    switchMap((resp) => {
                        this._location.back();
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Homework added successfully.",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });

    update$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(homeworkActions.update),
            switchMap((data) =>
                this.homeworkService.update(data.data, data.id).pipe(
                    switchMap((resp) => {
                        this._location.back();
                        return of(
                            homeworkActions.getHomework({ id: resp.data.id, replace: true }),
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Homework updated successfully.",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });

    submitAssignment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(homeworkActions.submitAssignment),
            switchMap((data) =>
                this.homeworkService.submitAssignment(data.data).pipe(
                    switchMap((resp) => {
                        data.onClose();
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Assignment submitted successfully.",
                            }),
                            homeworkActions.getHomework({ id: resp?.data?.homework, replace: true }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });

    getHomework$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(homeworkActions.getHomework),
            withLatestFrom(this.store.select(selectHomeworksCache)),
            switchMap(([data, cache]) =>
                !cache[data.id] || data.replace
                    ? this.homeworkService.getHomework(data.id).pipe(
                          switchMap((data) => {
                              return of(homeworkActions.setHomework({ data }));
                          }),
                          catchError((err) => {
                              return of(appEventActions.handleError(err));
                          }),
                      )
                    : of(homeworkActions.setHomework({ data: cache[data.id] })),
            ),
        );
    });
}
