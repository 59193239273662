import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, of, switchMap, withLatestFrom } from "rxjs";
import { ADMIN_USER_DATA, CURRENT_USER_DATA, USERS_DATA, USER_TOKEN, USER_TYPE } from "src/app/shared/constants";
import { LocalStorageService } from "src/app/shared/services";
import { appEventActions } from "src/app/shared/store";
import { StudentDataInterface } from "../../interfaces";
import { AuthService } from "../../services";
import { authActions } from "./auth.action";
import { selectUserData, selectUsersData } from "./auth.reducer";

@Injectable()
export class AuthEffect {
    constructor(
        private actions$: Actions,
        private authService: AuthService,
        private localStorageService: LocalStorageService,
        private route: ActivatedRoute,
        private store: Store,
    ) {}

    studentLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.studentLogin),
            switchMap((data) =>
                this.authService.studentLogin(data.data).pipe(
                    switchMap((response) => {
                        let newData = {
                            ...response,
                            admissionNumber: data.data.adm_number,
                        };

                        let usersData = this.localStorageService.getItem(USERS_DATA);
                        if (usersData && usersData.length) {
                            if (
                                !usersData.find(
                                    (item: StudentDataInterface) => item.admissionNumber === data.data.adm_number,
                                )
                            ) {
                                usersData.push(newData);
                            }
                        } else {
                            usersData = [newData];
                        }
                        this.localStorageService.setItem(USERS_DATA, usersData);
                        this.localStorageService.setItem(CURRENT_USER_DATA, newData);
                        this.localStorageService.setItem(USER_TOKEN, newData.token);
                        this.localStorageService.setItem(USER_TYPE, "Student");
                        const redirectUrl = this.route.snapshot.queryParams["redirectUrl"] || "/student/home";
                        return of(
                            authActions.studentLoginSuccess({
                                data: newData,
                            }),
                            authActions.setUserType({
                                data: "Student",
                            }),
                            appEventActions.redirect({
                                navigate: [redirectUrl],
                            }),
                            authActions.setUsersData({ data: usersData }),
                        );
                    }),
                    catchError((err) => of(appEventActions.handleError(err))),
                ),
            ),
        ),
    );

    studentLogout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.studentLogout),
            switchMap(() => {
                this.localStorageService.clear();
                return of(
                    appEventActions.redirect({
                        navigate: ["/"],
                    }),
                );
            }),
        ),
    );

    teacherLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.teacherLogin),
            switchMap((data) =>
                this.authService.teacherLogin(data.data).pipe(
                    switchMap((response) => {
                        let newData = {
                            ...response,
                        };

                        this.localStorageService.setItem(CURRENT_USER_DATA, newData.data);
                        this.localStorageService.setItem(USER_TOKEN, newData.token);
                        this.localStorageService.setItem(USER_TYPE, "Teacher");
                        const redirectUrl = this.route.snapshot.queryParams["redirectUrl"] || "/teacher";
                        return of(
                            authActions.teacherLoginSuccess({
                                data: newData.data,
                            }),
                            authActions.setUserType({
                                data: "Teacher",
                            }),
                            appEventActions.redirect({
                                navigate: [redirectUrl],
                            }),
                        );
                    }),
                    catchError((err) => of(appEventActions.handleError(err))),
                ),
            ),
        ),
    );

    teacherLogout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.teacherLogout),
            switchMap(() => {
                this.localStorageService.clear();
                return of(
                    appEventActions.redirect({
                        navigate: ["/"],
                    }),
                );
            }),
        ),
    );

    setCurrentUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.setCurrentUser),
            switchMap((data) => {
                this.localStorageService.setItem<StudentDataInterface>(CURRENT_USER_DATA, data.data);
                this.localStorageService.setItem<string>(USER_TOKEN, data.data.token);
                return of(
                    authActions.studentLoginSuccess({ data: data.data }),
                    appEventActions.redirect({
                        navigate: ["/student/home"],
                    }),
                );
            }),
        ),
    );

    removeUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.removeUser),
            withLatestFrom(this.store.select(selectUsersData), this.store.select(selectUserData)),
            switchMap(([data, allProfile, userData]) => {
                let currentUser = data?.data ? data?.data : userData;
                let usersData = allProfile ? allProfile : [];
                usersData = usersData.filter((profile) => profile.admissionNumber !== currentUser!.admissionNumber);
                this.localStorageService.setItem<StudentDataInterface[]>(USERS_DATA, usersData);

                if (!usersData.length) {
                    return of(authActions.studentLogout());
                }

                return of(
                    authActions.setCurrentUser({ data: usersData[0] }),
                    authActions.setUsersData({ data: usersData }),
                );
            }),
        ),
    );

    adminLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.adminLogin),
            switchMap((data) =>
                this.authService.adminLogin(data.data).pipe(
                    switchMap((response) => {
                        this.localStorageService.setItem(ADMIN_USER_DATA, response);
                        this.localStorageService.setItem(USER_TOKEN, response.token);
                        this.localStorageService.setItem(USER_TYPE, "Admin");
                        const redirectUrl = this.route.snapshot.queryParams["redirectUrl"] || "/admin";
                        return of(
                            authActions.adminLoginSuccess({
                                data: response,
                            }),
                            appEventActions.redirect({
                                navigate: [redirectUrl],
                            }),
                        );
                    }),
                    catchError((err) => of(appEventActions.handleError(err))),
                ),
            ),
        ),
    );

    adminLogout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.adminLogout),
            switchMap(() => {
                this.localStorageService.clear();
                return of(
                    appEventActions.redirect({
                        navigate: ["/portal/login"],
                    }),
                );
            }),
        ),
    );

    requestForgotPasswordOTP$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.requestForgotPasswordOTP),
            switchMap((data) =>
                this.authService.requestForgotPasswordOTP(data.data).pipe(
                    switchMap((response) => {
                        return of(
                            authActions.setForgotPasswordStep({
                                setpNumber: 2,
                            }),
                            authActions.setForgotPasswordData({
                                data: response,
                            }),
                            appEventActions.addToast({
                                severity: "success",
                                detail: "OTP for forgot password sent successfully",
                            }),
                        );
                    }),
                    catchError((err) => of(appEventActions.handleError(err))),
                ),
            ),
        ),
    );

    verifyOTP$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.verifyOTP),
            switchMap((data) =>
                this.authService.verifyOTP(data.data).pipe(
                    switchMap((response) => {
                        if (response.verified) {
                            return of(
                                authActions.setForgotPasswordStep({
                                    setpNumber: 3,
                                }),
                                authActions.setIsOTPValid({
                                    data: true,
                                }),
                            );
                        } else {
                            return of(
                                appEventActions.addToast({
                                    severity: "error",
                                    detail: "Please enter an valid OTP",
                                }),
                                authActions.setIsOTPValid({
                                    data: false,
                                }),
                            );
                        }
                    }),
                    catchError((err) => of(appEventActions.handleError(err))),
                ),
            ),
        ),
    );

    updatePassword$ = createEffect(() =>
        this.actions$.pipe(
            ofType(authActions.updatePassword),
            switchMap((data) =>
                this.authService.updatePassword(data.data).pipe(
                    switchMap(() => {
                        return of(
                            authActions.setForgotPasswordStep({
                                setpNumber: 1,
                            }),
                            authActions.setForgotPasswordData({
                                data: null,
                            }),
                            authActions.setIsOTPValid({
                                data: false,
                            }),
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Your password has be updated successfully. Please login with your new password",
                            }),
                            appEventActions.redirect({
                                navigate: ["/login"],
                            }),
                        );
                    }),
                    catchError((err) => of(appEventActions.handleError(err))),
                ),
            ),
        ),
    );
}
