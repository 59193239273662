import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ContentManagementComponentEnum } from 'src/app/shared/enum/settings';
import {
  ContentManagementInterface,
  CreateContentManagementInterface,
} from 'src/app/shared/interfaces/settings/content-management';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
  deps: [HttpClient],
})
export class ContentManagementService {
  constructor(private httpClient: HttpClient) {}

  getSingleContentManagementByComponentName(
    component_name: ContentManagementComponentEnum
  ): Observable<ContentManagementInterface> {
    return this.httpClient.get<ContentManagementInterface>(
      environment.API_URL +
        `/schools/admin/content-management/get/${component_name}`
    );
  }

  create(
    data: CreateContentManagementInterface
  ): Observable<CreateContentManagementInterface> {
    return this.httpClient.post<CreateContentManagementInterface>(
      environment.API_URL + '/schools/admin/content-management/create/',
      data
    );
  }

  update(
    data: CreateContentManagementInterface,
    id: string
  ): Observable<CreateContentManagementInterface> {
    return this.httpClient.put<CreateContentManagementInterface>(
      environment.API_URL + `/schools/admin/content-management/${id}/`,
      data
    );
  }

  generateDefaultContent(): Observable<{}> {
    return this.httpClient.get(
      environment.API_URL + `/schools/admin/content-management/generate-data/`,
      {}
    );
  }
}
