<div
    class="flex justify-between items-center cursor-pointer hover:bg-primary-light gap-3 border-b border-b-gray-100 px-3 py-4"
    [routerLink]="item.routerLink"
    [queryParams]="item.queryParams"
>
    <div class="flex justify-center items-center gap-3">
        <div class="flex justify-center items-center gap-2/5 p-2 bg-primary-light rounded-m w-12">
            <i class="{{ item.icon }} text-primary text-2xl"></i>
        </div>
        <div>
            <div class="text-base font-bold font-satoshi leading-3 text-heading">{{ item.heading }}</div>
            <ng-content></ng-content>
        </div>
    </div>
    <div class="flex justify-center items-center gap-2/5 p-2 bg-primary-light rounded-m w-12">
        <i class="ph-duotone ph-caret-right text-primary text-2xl"></i>
    </div>
</div>
