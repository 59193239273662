import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BatchInterface, CreateBatchInterface } from '../../../interfaces';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
  deps: [HttpClient],
})
export class BatchService {
  constructor(private httpClient: HttpClient) {}

  getAllBatches(): Observable<BatchInterface[]> {
    return this.httpClient.get<BatchInterface[]>(
      environment.API_URL + '/batches/'
    );
  }

  getAdmissionBatch(admissionId: string): Observable<BatchInterface> {
    return this.httpClient.get<BatchInterface>(
      environment.API_URL + '/schools/batch-for-admission/' + admissionId
    );
  }

  create(data: CreateBatchInterface): Observable<CreateBatchInterface> {
    return this.httpClient.post<CreateBatchInterface>(
      environment.API_URL + '/schools/admin/batch-config/create/',
      data
    );
  }

  update(
    data: CreateBatchInterface,
    id: string
  ): Observable<CreateBatchInterface> {
    return this.httpClient.put<CreateBatchInterface>(
      environment.API_URL + `/schools/admin/batch-config/${id}/`,
      data
    );
  }
}
