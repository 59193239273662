import { createFeature, createReducer, on } from '@ngrx/store';
import { batchActions } from './batch.action';
import { BatchStoreInterface } from 'src/app/shared/interfaces';

const initailState: BatchStoreInterface = {
  batches: [],
  admissionBatch: null,
};

const batchFeature = createFeature({
  name: 'batch',
  reducer: createReducer(
    initailState,
    on(batchActions.setBatches, (state, action) => ({
      ...state,
      batches: action.data,
    })),
    on(batchActions.setAdmissionBatch, (state, action) => {
      return {
        ...state,
        admissionBatch: action.data,
      };
    }),
    on(batchActions.create, (state) => ({ ...state })),
    on(batchActions.update, (state) => ({ ...state }))
  ),
});

export const {
  name: batchFeatureKey,
  reducer: batchReducer,
  selectBatches,
  selectAdmissionBatch,
} = batchFeature;
