import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";

@Component({
    selector: "sms-no-data",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./no-data.component.html",
    styleUrls: ["./no-data.component.scss"],
})
export class NoDataComponent {
    @Input() message: string;
}
