import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LabelComponent } from '../../label/label.component';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { DisplayValidationErrorComponent } from '../../display-validation-error/display-validation-error.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormInputContorlDirective } from 'src/app/core/directives';
import { YesNoOptions } from 'src/app/shared/constants';
import { HelpTextComponent } from '../../help-text/help-text.component';

@Component({
  selector: 'sms-radio-button',
  standalone: true,
  imports: [
    CommonModule,
    RadioButtonModule,
    LabelComponent,
    ReactiveFormsModule,
    DisplayValidationErrorComponent,
    HelpTextComponent,
  ],
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioButtonComponent),
      multi: true,
    },
  ],
})
export class RadioButtonComponent<T>
  extends FormInputContorlDirective<T>
  implements OnChanges
{
  @Input() label?: string = '';
  @Input() disabled?: boolean = false;
  @Input() helpText?: string;
  @Input() containerClass?: string = '';
  @Input() rows?: boolean = false;
  @Input() yesNo?: boolean = true;
  @Input() options: { label: string; value: unknown }[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['yesNo'] && changes['yesNo'].currentValue) {
      this.options = YesNoOptions;
    }
  }
}
