import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
    ClassroomInterface,
    PostClassroomInfoInterface,
    PostClassroomStepResponse,
    PostClassroomStudentInterface,
    PostClassroomTeacherInterface,
} from "../../interfaces";
import { PostClassroomSubjectInterface } from "../../interfaces/classroom/classroom-subject.interfce";

@Injectable({
    providedIn: "root",
})
export class ClassroomService {
    constructor(protected httpClient: HttpClient) {}

    saveClassroomData(
        data:
            | PostClassroomInfoInterface
            | PostClassroomSubjectInterface
            | PostClassroomTeacherInterface
            | PostClassroomStudentInterface,
    ): Observable<PostClassroomStepResponse & { division_id: string }> {
        return this.httpClient.post<PostClassroomStepResponse & { division_id: string }>(
            environment.API_URL + "/admin/classrooms/create/",
            data,
        );
    }

    getStepDetails<T>(division_id: string): Observable<T> {
        return this.httpClient.get<T>(`${environment.API_URL}/admin/classrooms/${division_id}`, {
            params: {
                division_id: division_id,
            },
        });
    }

    getTeachersClassrooms(teacherId: string, classTeacherOnly: boolean = false): Observable<ClassroomInterface[]> {
        return this.httpClient.get<ClassroomInterface[]>(`${environment.API_URL}/teachers-classrooms/${teacherId}`, {
            params: {
                class_teacher_only: classTeacherOnly,
            },
        });
    }

    getStudentsClassrooms(studentId: string): Observable<ClassroomInterface[]> {
        return this.httpClient.get<ClassroomInterface[]>(`${environment.API_URL}/students-classrooms/${studentId}`, {});
    }
}
