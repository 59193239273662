<div
    class="border-slate-100 shadow-s border-solid border-[1px] cursor-pointer rounded-lg p-4 flex flex-col gap-3 hover:bg-primary-light"
    [class.bg-slate-100]="selectAnnouncementId === item.id"
    (click)="onSelectAnnouncement(item)"
>
    <sms-announcement-heading [item]="item"> </sms-announcement-heading>
    <p
        class="m-0 text-placeholder text-sm font-medium font-satoshi line-clamp-5"
        [innerHtml]="item.announcement.content"
    ></p>
    <div class="flex justify-between items-center" [class.!justify-end]="!item.announcement.attachments.length">
        <div
            class="flex justify-start items-center text-primary gap-1 font-medium text-xs"
            *ngIf="item.announcement.attachments.length"
        >
            <i class="ph-bold ph-paperclip"></i>

            <span class="font-satoshi underline">
                {{ item.announcement.attachments.length }}
                Attachment
            </span>
        </div>
        <span class="font-satoshi font-medium text-sm underline text-primary"> Read More </span>
    </div>
</div>
