import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { selectIsAdminLoggedIn } from '../stores';
import { map } from 'rxjs';
import { appEventActions } from 'src/app/shared/store';

export const AdminAuthGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  return store.select(selectIsAdminLoggedIn).pipe(
    map((value) => {
      if (!value) {
        store.dispatch(
          appEventActions.redirect({ navigate: ['/portal/login'] })
        );
        return false;
      }
      return true;
    })
  );
};
