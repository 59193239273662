import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'sms-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Input({ required: false }) variant: 'warning' | 'info' = 'info';

  wrapperClasses: Record<string, string> = {
    info: 'text-gray-900 bg-gray-100',
    warning: 'text-error bg-error-light font-semibold',
  };

  iconsClasses: Record<string, string> = {
    info: 'ph-fill ph-info',
    warning: 'ph-fill ph-warning',
  };
}
