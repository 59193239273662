<div class="flex items-center flex-col gap-5">
    <div class="flex justify-center bg-error-light items-center p-5 rounded-full">
        <i class="ph-bold ph-trash text-error text-5xl"></i>
    </div>

    <p class="text-gray-400 text-base font-medium font-satoshi text-center">
        <span class="text-error font-semibold">Caution:</span>
        {{
            message
                ? message
                : "Deleting this " +
                  name +
                  " is irreversible. Are you sure you want to proceed? This action cannot be undone."
        }}
    </p>
</div>
<sms-divider [horizontal]="true"></sms-divider>
<div class="flex justify-end items-center w-full gap-3">
    <button (click)="onClose()" smsButton size="small" color="error" variant="outlined">Close</button>
    <button [loading]="isLoading$ | async" (click)="onConfirm()" smsButton size="small">Confirm</button>
</div>
