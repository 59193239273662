export function getFileType(fileExtension: string): string {
  if (!fileExtension) {
    return 'unknown';
  }

  if (
    ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg', 'tiff'].includes(fileExtension)
  ) {
    return 'image';
  } else if (['mp3', 'wav', 'ogg', 'aac'].includes(fileExtension)) {
    return 'audio';
  } else if (['mp4', 'avi', 'mkv', 'mov', 'wmv'].includes(fileExtension)) {
    return 'video';
  } else if (['zip', 'rar', 'tar', 'gz', '7z'].includes(fileExtension)) {
    return 'archive';
  } else if (
    [
      'html',
      'css',
      'js',
      'ts',
      'json',
      'xml',
      'java',
      'cpp',
      'c',
      'h',
      'py',
      'rb',
      'php',
      'swift',
      'go',
      'sh',
      'sql',
      'pl',
      'perl',
      'r',
      'scala',
      'vb',
      'lua',
      'matlab',
      'rust',
    ].includes(fileExtension)
  ) {
    return 'code';
  } else if (['doc', 'docx'].includes(fileExtension)) {
    return 'word';
  } else if (['ppt', 'pptx'].includes(fileExtension)) {
    return 'powerpoint';
  } else if (['xls', 'xlsx', 'csv', 'xlx'].includes(fileExtension)) {
    return 'excel';
  } else if (['pdf'].includes(fileExtension)) {
    return 'pdf';
  } else if (['txt'].includes(fileExtension)) {
    return 'text';
  } else {
    return 'other';
  }
}
