import { createActionGroup, props } from "@ngrx/store";
import { HomeworkInterface } from "../../interfaces";

export const homeworkActions = createActionGroup({
    source: "homework",
    events: {
        create: props<{
            data: FormData;
        }>(),
        update: props<{
            data: FormData;
            id: string;
        }>(),
        "submit assignment": props<{
            data: FormData;
            onClose: VoidFunction;
        }>(),
        "get homework": props<{ id: string; replace?: boolean }>(),
        "set homework": props<{ data: HomeworkInterface }>(),
    },
});
