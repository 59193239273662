import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Message, MessageService } from "primeng/api";
import { DialogService } from "primeng/dynamicdialog";
import { Observable, catchError, map, mergeMap, of, switchMap, tap } from "rxjs";
import { authActions } from "src/app/core/stores";
import { GenericApiService } from "../../services";
import { appEventActions } from "./app-event.action";

@Injectable()
export class AppEventEffects {
    constructor(
        private actions$: Actions,
        private messageService: MessageService,
        private router: Router,
        private dialogService: DialogService,
        private genericApiService: GenericApiService,
    ) {}

    addToast$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appEventActions.addToast),
            switchMap((data) => {
                return this.showToast(data).pipe(map(() => appEventActions.emptyAction()));
            }),
        ),
    );

    handleRedirect$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(appEventActions.redirect),
                tap((data) => {
                    this.router.navigate(data.navigate, data.otherOptions);
                }),
            ),
        { dispatch: false },
    );

    handleError$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appEventActions.handleError),
            switchMap((data) => {
                let errorMsg = "Server Error";

                console.log(data);
                if (data.error) {
                    if ("logout" in data.error && data.error["logout"]) {
                        if ("teacher" in data.error && data.error["teacher"]) {
                            return of(authActions.teacherLogout());
                        } else if ("student" in data.error && data.error["student"]) {
                            return of(
                                authActions.removeUser({
                                    data: null,
                                }),
                            );
                        }
                    } else if (typeof data.error === "object") {
                        if ("msg" in data.error) {
                            errorMsg += ` :- ${data.error["msg"]}`;
                        } else if ("error" in data.error) {
                            errorMsg += ` :- ${data.error["error"]}`;
                        } else {
                            let errors: Record<string, any> = {};
                            for (const [key, value] of Object.entries(data.error)) {
                                if (value) {
                                    errors[key] = value;
                                }
                            }

                            if (Object.keys(errors).length) {
                                let key = Object.keys(errors)[0];
                                if (!Array.isArray(errors[key]) && Object.keys(errors[key]).length) {
                                    let subKey = Object.keys(errors[key])[0];

                                    errorMsg += ` :- ${(errors[key] as any)[subKey][0]}(${key}.${subKey})`;
                                } else if (errors[key][0]) {
                                    errorMsg += ` :- ${errors[key][0]}(${key})`;
                                }
                            }
                        }
                    } else {
                        errorMsg += ":- Something went worng";
                    }
                } else {
                    console.log(data, "<=====datattatatattatt");
                    errorMsg = `Client Error :- ${data?.message}`;
                }

                return this.showToast({
                    severity: "error",
                    summary: "Opps, there is an Error",
                    detail: errorMsg,
                }).pipe(map(() => appEventActions.emptyAction()));
            }),
        ),
    );

    showToast(data: Message) {
        this.messageService.add({
            ...data,
        });
        return new Observable();
    }

    closeDialog$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(appEventActions.closeDialog),
                tap(() => {
                    this.dialogService.dialogComponentRefMap.forEach((item) => item.instance.close());
                }),
            ),
        {
            dispatch: false,
        },
    );

    deleteItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(appEventActions.deleteItem),
            mergeMap((data) =>
                this.genericApiService.deleteItem(data.data).pipe(
                    mergeMap(() => {
                        data.onClose(true);
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: `${data.data.name} deleted successfully.`,
                            }),
                        );
                    }),
                    catchError((err) => {
                        console.log(err);

                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );
}
