import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { PaymentService } from '../../services';
import { switchMap, of, catchError } from 'rxjs';
import { appEventActions } from '../app-event';
import { paymentActions } from './payment.action';

@Injectable()
export class PaymentEffects {
  constructor(
    private actions$: Actions,
    private paymentService: PaymentService
  ) {}

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(paymentActions.createOrder),
      switchMap((data) =>
        this.paymentService.createOrder(data.data).pipe(
          switchMap((resp) => {
            return of(
              paymentActions.setOrderData({
                data: {
                  ...data.data,
                  order_id: resp.order_id,
                  amount: resp.amount,
                  total_amount: resp.total_amount,
                },
              }),
              appEventActions.emptyAction()
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    );
  });
}
