import { ActionReducer, MetaReducer } from "@ngrx/store";
import { authActions } from "src/app/core/stores";

export function metaReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state, action) {
        if (
            [
                authActions.studentLogout().type,
                authActions.adminLogout().type,
                authActions.teacherLogout().type,
            ].includes(action.type as any)
        ) {
            state = undefined;
        }
        return reducer(state, action);
    };
}

export const metaReducers: MetaReducer<any>[] = [metaReducer];
