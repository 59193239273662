import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CreateEnquiryInterface } from '../interface';

@Injectable({
  providedIn: 'root',
})
export class EnquiryService {
  constructor(private httpClient: HttpClient) {}

  createEnquiry(data: CreateEnquiryInterface) {
    return this.httpClient.post(
      environment.API_URL + '/enquiries/create/',
      data
    );
  }
}
