import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { LovsStoreInterface } from "src/app/shared/interfaces/settings/lovs";
import { lovsActions } from "./lovs.action";

const initailState: LovsStoreInterface = {
    data: {},
};

const lovsFeature = createFeature({
    name: "lovs",
    reducer: createReducer(
        initailState,
        on(lovsActions.setLovData, (state, action) => ({
            ...state,
            data: {
                ...state.data,
                [action.key]: action.data,
            },
        })),
        on(lovsActions.removeLovData, (state, action) => ({
            ...state,
            data: {
                ...state.data,
                [action.key]: undefined,
            },
        })),
    ),
});

export const { name: lovsFeatureKey, reducer: lovsReducer, selectData } = lovsFeature;

export const selectLovData = (props: { url: string }) =>
    createSelector(selectData, (data: Record<string, any>) => {
        return data[props.url];
    });
