export const BLOOD_GROUPS = ["A+", "A-", "B+", "B-", "AB+", "AB-", "O+", "O-"];
export const MONTHS: Record<number, { label: string; value: number }> = {
    1: { label: "January", value: 1 },
    2: { label: "February", value: 2 },
    3: { label: "March", value: 3 },
    4: { label: "April", value: 4 },
    5: { label: "May", value: 5 },
    6: { label: "June", value: 6 },
    7: { label: "July", value: 7 },
    8: { label: "August", value: 8 },
    9: { label: "September", value: 9 },
    10: { label: "October", value: 10 },
    11: { label: "November", value: 11 },
    12: { label: "December", value: 12 },
};
