<div class="flex flex-col items-center gap-3">
    <div class="flex justify-center items-center p-5 bg-info-light rounded-full">
        <i class="ph-bold ph-info text-info text-2xl"></i>
    </div>
    <h4
        class="cursor-pointer font-satoshi text-heading text-center font-semibold text-base underline"
        (click)="onCopy()"
        pTooltip="Click to Copy"
    >
        {{ admissionNumber }}
    </h4>
    <p class="text-sm text-gray-400 text-center font-satoshi">
        This unique identifier will be essential for future reference. Please keep it secure for future use.
    </p>
    <div class="flex justify-center items-center gap-3">
        <button (click)="onClose()" smsButton size="small" variant="outlined">Close</button>
        <button (click)="onCopy()" smsButton size="small">Copy <i class="ph-bold ph-clipboard"></i></button>
    </div>
</div>
