import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { FRONTEND_DATE_FORMAT } from 'src/app/shared/constants';

@Pipe({
  name: 'dateTime',
  standalone: true,
})
export class DateTimePipe implements PipeTransform {
  timeRegExp = new RegExp(/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/);

  constructor(private datePipe: DatePipe) {}
  transform(
    value: Date | string | number,
    format: string = FRONTEND_DATE_FORMAT,
    ...args: unknown[]
  ): any {
    if (value) {
      if (typeof value == 'string' && value.match(this.timeRegExp)) {
        const [hours, minutes] = value.split(':');
        let period = 'AM';
        let parsedHours = parseInt(hours, 10);
        if (parsedHours >= 12) {
          period = 'PM';
          if (parsedHours > 12) {
            parsedHours -= 12;
          }
        } else if (parsedHours === 0) {
          parsedHours = 12;
        }

        return `${parsedHours}:${minutes} ${period}`;
      }

      return this.datePipe.transform(value, format);
    }
    return value;
  }
}
