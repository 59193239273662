import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { FormInputContorlDirective } from 'src/app/core/directives';
import { DisplayValidationErrorComponent } from '../../display-validation-error/display-validation-error.component';
import { LabelComponent } from '../../label/label.component';
export function escapeRegExp(value: string) {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

@Component({
  selector: 'sms-autocomplete',
  standalone: true,
  imports: [
    CommonModule,
    AutoCompleteModule,
    ReactiveFormsModule,
    DisplayValidationErrorComponent,
    LabelComponent,
  ],
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutocompleteComponent),
      multi: true,
    },
  ],
})
export class AutocompleteComponent<T>
  extends FormInputContorlDirective<T>
  implements OnChanges
{
  @Input({ required: true }) options: any[] | null = [];
  @Input() field?: string;
  @Input() label?: string = '';
  @Input() value?: string;
  @Input() placeholder?: string = '';
  @Input() multiple?: boolean = false;

  filterdOptions = this.options as any[];

  filterData(event: any) {
    if (this.field) {
      const filtered: any[] = [];
      const searchRegex = new RegExp(escapeRegExp(event.query.trim()), 'i');

      for (const option of this?.options || []) {
        if (option[this.field] && searchRegex.test(option[this.field])) {
          filtered.push(option);
        }
      }

      this.filterdOptions = filtered;
    } else {
      const filtered: any[] = [];
      const searchRegex = new RegExp(escapeRegExp(event.query.trim()), 'i');

      for (const option of this?.options || []) {
        if (option && searchRegex.test(option)) {
          filtered.push(option);
        }
      }

      this.filterdOptions = filtered;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const { currentValue } = changes['options'];
    if (currentValue) {
      this.filterdOptions = currentValue;
    }
  }
}
