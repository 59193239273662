import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DeleteItemInterface } from "src/app/shared/interfaces";
import { GetLovDataInterface } from "src/app/shared/interfaces/settings/lovs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class GenericApiService<T = unknown> {
    endPoint: string;

    constructor(protected httpClient: HttpClient) {}

    setEndpoint(endPoint: string) {
        this.endPoint = endPoint;
    }

    getAll(data: GetLovDataInterface): Observable<T[]> {
        return this.httpClient.get<T[]>(`${environment.API_URL}${data.url}`, {
            params: data?.queryParams || {},
        });
    }

    updateItem(data: { url: string; data: T }): Observable<T[]> {
        return this.httpClient.post<T[]>(`${environment.API_URL}${data.url}`, data.data);
    }

    deleteItem(data: DeleteItemInterface) {
        return this.httpClient.delete(`${environment.API_URL}${data.url}/${data.id}/`);
    }

    downloadSampleExcel(url: string): Observable<{ file: string } | string> {
        return this.httpClient.get<{ file: string } | string>(`${environment.API_URL}${url}`);
    }

    uploadStudentsExcel(data: FormData, url: string): Observable<any> {
        return this.httpClient.post(`${environment.API_URL}${url}`, data);
    }
}
