<ng-container *ngIf="control.disabled; else elseTemplate">
  <div class="flex flex-col gap-1">
    <sms-label>{{ label }}</sms-label>
    <p class="text-base font-satoshi text-gray-900 px-1">
      {{ control.value || "-" }}
    </p>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <span class="p-float-label">
    <textarea
      pInputTextarea
      id="{{ label }}"
      [rows]="rows"
      [cols]="cols"
      [placeholder]="placeholder"
      [formControl]="control"
      [class.invalid]="isError()"
    ></textarea>

    <sms-label *ngIf="label" class="absolute block" [required]="isRequired">{{
      label
    }}</sms-label>
    <sms-display-validation-error
      [control]="control"
      [label]="label"
      *ngIf="isError()"
    ></sms-display-validation-error>
  </span>
</ng-template>
