import { CommonModule, formatDate } from "@angular/common";
import { RouterLink } from "@angular/router";
import { Store } from "@ngrx/store";
import { InputMaskModule } from "primeng/inputmask";
import { Subscription } from "rxjs";
import { ButtonDirective } from "src/app/core/directives";
import {
    AlertComponent,
    AutocompleteComponent,
    CheckboxComponent,
    DateTimeComponent,
    FileInputComponent,
    RadioButtonComponent,
    TextInputComponent,
} from "src/app/shared/components/ui";

import { UppercaseDirective } from "src/app/shared/directive";
import { GenderOptions, PostChildrenInfoInterface, StandardInterface } from "src/app/shared/interfaces";

import { Component, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";

import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";

import {
    admissionActions,
    appEventActions,
    lovsActions,
    selectAdmissionData,
    selectAdmissionIdsData,
    selectData,
    selectIsMutationLoading,
    selectStandards,
    standardActions,
} from "src/app/shared/store";

import { BACKEND_DATE_FORMAT, BLOOD_GROUPS } from "src/app/shared/constants";

import { CasteInterface, MotherTongueInterface } from "src/app/shared/interfaces/settings/lovs";

@Component({
    selector: "sms-children-info",
    standalone: true,
    imports: [
        CommonModule,
        CheckboxComponent,
        FileInputComponent,
        TextInputComponent,
        RadioButtonComponent,
        DateTimeComponent,
        AutocompleteComponent,
        ReactiveFormsModule,
        RouterLink,
        ButtonDirective,
        InputMaskModule,
        AlertComponent,
        UppercaseDirective,
    ],
    templateUrl: "./children-info.component.html",
    styleUrls: ["./children-info.component.scss"],
})
export class ChildrenInfoComponent implements OnInit, OnChanges, OnDestroy {
    @Input() preview = false;
    formGroup!: FormGroup;
    today = new Date();

    casteDataKey = "/castes/";
    casteData: CasteInterface[];

    motherTongueDataKey = "/mother-tongue/";
    aadharHelpText = "Please Enter a valid aadhar number, if aadhar number is not available then add '9' 12 times";
    motherTongueData: MotherTongueInterface[];
    genderOptions = Object.values(GenderOptions);
    standards$ = this.store.select(selectStandards);
    isLoading$ = this.store.select(selectIsMutationLoading);
    bloodGroupOptions = BLOOD_GROUPS;

    gender = [
        {
            label: "Male",
            value: "Male",
        },
        {
            label: "Female",
            value: "Female",
        },
    ];

    subscription: Subscription = new Subscription();
    constructor(
        private formBuilder: FormBuilder,
        private store: Store,
        @Inject(LOCALE_ID) private localeId: string,
    ) {
        this.formGroup = this.formBuilder.group({
            standard: new FormControl("", Validators.required),
            batch: new FormControl("", Validators.required),
            first_name: new FormControl("", Validators.required),
            aadhaar_number: new FormControl(""),
            middle_name: new FormControl("", Validators.required),
            last_name: new FormControl("", Validators.required),
            dob: new FormControl("", Validators.required),
            place_of_birth: new FormControl("", Validators.required),
            nationality: new FormControl("", Validators.required),
            mother_tongue: new FormControl("", Validators.required),
            gender: new FormControl("", Validators.required),
            caste: new FormControl("", Validators.required),
            sub_caste: new FormControl("", Validators.required),
            blood_group: new FormControl("", Validators.required),
            transport_facility_required: new FormControl(null, Validators.required),
            admission: new FormControl(null),
            student: new FormControl(null),
            phone: new FormControl("", Validators.required),
            is_update: false,
        });
    }

    ngOnInit(): void {
        scrollTo(0, 0);
        this.getSelectData();
        this.subscription.add(
            this.store
                .select(selectAdmissionIdsData)
                .subscribe((value) => value && this.formGroup.patchValue({ ...value })),
        );
        this.getStepData();
        this.subscription.add(
            this.formGroup.get("standard")!.valueChanges.subscribe((value) => {
                if (value) {
                    if (value.is_aadhaar_required) {
                        this.formGroup.get("aadhaar_number")?.setValidators([Validators.required]);
                    } else {
                        this.formGroup.get("aadhaar_number")?.setValidators([]);
                    }
                }
                this.formGroup.get("batch")!.setValue("");
            }),
        );
        this.subscription.add(
            this.formGroup.get("caste")!.valueChanges.subscribe((value) => {
                this.formGroup.get("sub_caste")!.setValue("");
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["preview"].currentValue) {
            if (this.formGroup) {
                this.formGroup.disable();
            }
        }
    }

    convertDateFormat(ddmmyyyy: string) {
        // Assuming the input date is in "DD/MM/YYYY" format
        var parts = ddmmyyyy.split("/");

        // Rearrange the parts to "MM/DD/YYYY" format
        var mmddyyyy = parts[1] + "/" + parts[0] + "/" + parts[2];

        return mmddyyyy;
    }

    onSave() {
        if (this.preview) {
            this.store.dispatch(admissionActions.nextStep());
            return;
        }

        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            let standard: StandardInterface = this.formGroup.getRawValue().standard;
            const start = new Date(this.convertDateFormat(standard.age_criteria_start_threshold));
            const end = new Date(this.convertDateFormat(standard.age_criteria_end_threshold));
            const date = new Date(this.formGroup.getRawValue().dob);

            if (!(date.getTime() >= start.getTime() && date.getTime() <= end.getTime())) {
                this.store.dispatch(
                    appEventActions.addToast({
                        severity: "error",
                        detail: `We regret to inform you that ${
                            this.formGroup.getRawValue().first_name
                        } is not eligible for admission. Admission to this standard is open for students born between ${
                            standard.age_criteria_start_threshold
                        } and ${standard.age_criteria_end_threshold} only.`,
                    }),
                );
                return;
            }
            const data: PostChildrenInfoInterface = {
                step: 1,
                standard: this.formGroup.getRawValue().standard.id,
                batch: this.formGroup.getRawValue().batch.id,
                student: this.formGroup.getRawValue().student,
                admission: this.formGroup.getRawValue().admission,
                phone: this.formGroup.getRawValue().phone,
                is_update: this.formGroup.getRawValue().is_update,
                student_data: {
                    ...this.formGroup.getRawValue(),
                    caste: this.formGroup.getRawValue().caste.id,
                    sub_caste: this.formGroup.getRawValue().sub_caste.id,
                    dob: formatDate(this.formGroup.getRawValue().dob, BACKEND_DATE_FORMAT, this.localeId),
                },
            };

            this.store.dispatch(admissionActions.saveChildrenInfo({ data }));
        }
    }

    getStepData() {
        this.store.dispatch(admissionActions.getAdmissionData({ stepNumber: 1 }));
        this.subscription.add(
            this.store.select(selectAdmissionData).subscribe((value) => {
                if (value) {
                    this.formGroup.patchValue({
                        standard: value?.standard,
                        batch: value?.batch,
                        first_name: value?.student?.first_name,
                        aadhaar_number: value?.student?.aadhaar_number,
                        middle_name: value?.student?.middle_name,
                        last_name: value?.student?.last_name,
                        dob: new Date(value?.student?.dob),
                        place_of_birth: value?.student?.place_of_birth,
                        nationality: value?.student?.nationality,
                        mother_tongue: value?.student?.mother_tongue,
                        gender: value?.student?.gender,
                        caste: value?.student?.caste,
                        phone: value?.phone,
                        sub_caste: value?.student?.sub_caste,
                        blood_group: value?.student?.blood_group,
                        transport_facility_required: value?.student?.transport_facility_required,
                        is_update: true,
                    });
                }
            }),
        );
    }

    getSelectData() {
        this.store.dispatch(standardActions.loadStandardsForAdmission());
        this.store.dispatch(lovsActions.getLovData({ data: { url: "/castes/" } }));
        this.store.dispatch(lovsActions.getLovData({ data: { url: "/mother-tongue/" } }));
        this.subscription.add(
            this.store.select(selectData).subscribe((data) => {
                this.casteData = data[this.casteDataKey];
                this.motherTongueData = data[this.motherTongueDataKey];
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
