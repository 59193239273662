import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { SidebarModule } from "primeng/sidebar";
import { Subscription } from "rxjs";
import { appEventActions, selectIsSidebarOpen } from "src/app/shared/store";

@Component({
    selector: "sms-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"],
    standalone: true,
    imports: [CommonModule, SidebarModule],
})
export class SidebarComponent implements OnInit {
    sidebarVisible: boolean = false;

    subscription = new Subscription();
    constructor(private store: Store) {}

    ngOnInit() {
        this.subscription.add(
            this.store.select(selectIsSidebarOpen).subscribe((value) => {
                this.sidebarVisible = value;
            }),
        );
    }
    toggleSidebarOpen() {
        this.store.dispatch(appEventActions.toggleSidebar());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
