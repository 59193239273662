import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, of, switchMap, withLatestFrom } from "rxjs";
import { SchoolConfigService } from "../../services/school-config";
import { appEventActions } from "../app-event";
import { schoolConfigActions } from "./school-config.action";
import { selectSchoolConfigItems } from "./school-config.reducer";

@Injectable()
export class SchoolConfigEffect {
    constructor(
        private actions$: Actions,
        private schoolConfigService: SchoolConfigService,
        private store: Store,
    ) {}

    getConfigItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(schoolConfigActions.getConfigItem),
            withLatestFrom(this.store.select(selectSchoolConfigItems)),
            switchMap(([data, schoolConfigItems]) => {
                return schoolConfigItems[JSON.stringify(data.params)]
                    ? of(appEventActions.emptyAction())
                    : this.schoolConfigService.getSchoolConfigItem(data.params).pipe(
                          switchMap((resp) => {
                              return of(
                                  schoolConfigActions.setConfigItemData({
                                      data: {
                                          params: JSON.stringify(data.params),
                                          data: resp,
                                      },
                                  }),
                              );
                          }),
                      );
            }),
        ),
    );

    saveConfigItem$ = createEffect(() =>
        this.actions$.pipe(
            ofType(schoolConfigActions.saveConfigItem),
            switchMap((data) =>
                this.schoolConfigService.saveSchoolConfigItem(data.data).pipe(
                    switchMap(() => {
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: data.message,
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );
    saveAttendanceSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(schoolConfigActions.saveAttendanceSettings),
            switchMap((data) =>
                this.schoolConfigService.saveAttendanceSettings(data.data, data.id).pipe(
                    switchMap(() => {
                        if (data.onSuccess) {
                            data.onSuccess();
                        }
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: data.message,
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    genrateQRCode$ = createEffect(() =>
        this.actions$.pipe(
            ofType(schoolConfigActions.genrateQRCode),
            switchMap((data) =>
                this.schoolConfigService.genrateQRCode(data.id).pipe(
                    switchMap((val) => {
                        return of(schoolConfigActions.setQRCode({ qrCode: val.qr }));
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );
}
