import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { QuillModule } from "ngx-quill";
import Quill from "quill";
import { DisplayValidationErrorComponent } from "../../display-validation-error/display-validation-error.component";
import { LabelComponent } from "../../label/label.component";

const parchment = Quill.import("parchment");
const block = parchment.query("block");
block.tagName = "DIV";
// or class NewBlock extends Block {} NewBlock.tagName = 'DIV'
Quill.register(block /* or NewBlock */, true);

@Component({
    selector: "sms-rich-text-editor",
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, QuillModule, LabelComponent, DisplayValidationErrorComponent],
    templateUrl: "./rich-text-editor.component.html",
    styleUrls: ["./rich-text-editor.component.scss"],
})
export class RichTextEditorComponent {
    blurred = false;
    focused = false;
    @Input() control: FormControl;
    @Input() label: string;
    @Input() required: boolean = false;

    isError() {
        return this.control && this.control.touched && this.control.errors && Object.keys(this.control.errors).length;
    }
}
