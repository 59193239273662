<div>
    <sms-payment-warpper [orderData]="orderData" [redirect]="router.url">
        <ng-container *ngIf="!!config.data">
            <div class="flex flex-col gap-3 py-3">
                <sms-alert *ngIf="onlinePaymentEnabled"
                    >Note:- GST Amount is calculated on Transaction Fee (Transaction Fee is 2% of Total Amount to be
                    Paid)</sms-alert
                >
                <sms-heading variant="secondary">Payment Details</sms-heading>
                <div class="flex justify-between items-center gap-4">
                    <div class="font-satoshi text-sm text-gray-800">Instalment Amount</div>
                    <div class="font-satoshi text-sm text-gray-900 font-medium">&#8377; {{ config.data.amount }}</div>
                </div>
                <ng-container *ngIf="config.data.total_amount_breakup">
                    <div
                        class="flex justify-between items-center gap-4"
                        *ngFor="let item of config.data.total_amount_breakup"
                    >
                        <div class="font-satoshi text-sm text-gray-800">
                            {{ item.description }}
                        </div>
                        <div class="font-satoshi text-sm text-gray-900 font-medium">&#8377; {{ item.amount }}</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="onlinePaymentEnabled">
                    <div class="flex justify-between items-center gap-4">
                        <div class="font-satoshi text-sm text-gray-800">Transaction Fee (2%)</div>
                        <div class="font-satoshi text-sm text-gray-900 font-medium">
                            &#8377; {{ config.data.payment_detail.transaction_fee }}
                        </div>
                    </div>
                    <div class="flex justify-between items-center gap-4">
                        <div class="font-satoshi text-sm text-gray-800">GST Amount</div>
                        <div class="font-satoshi text-sm text-gray-900 font-medium">
                            &#8377; {{ config.data.payment_detail.gst_amount }}
                        </div>
                    </div>
                </ng-container>
                <div class="flex justify-between items-center gap-4 border-t border-0 border-dashed border-gray-200">
                    <div class="font-satoshi text-sm text-gray-800">Total Amount</div>
                    <div class="font-satoshi text-sm text-gray-900 font-medium">
                        &#8377; {{ config.data.payment_detail.total_amount }}
                    </div>
                </div>

                <div class="flex justify-between items-center gap-4">
                    <div class="font-satoshi text-sm text-gray-800">Due Date</div>
                    <div class="font-satoshi text-sm text-gray-900 font-medium">
                        {{
                            config.data.is_first_instalment
                                ? "Pay after admission"
                                : "Before " + getFormatedDate(config.data)
                        }}
                    </div>
                </div>
                <div class="flex justify-between items-center gap-4">
                    <div class="font-satoshi text-sm text-gray-800">Payment Status</div>
                    <div [variant]="!config.data.is_paid ? 'warn' : 'success'" smsChip>
                        {{ !config.data.is_paid ? "Due" : "Paid" }}
                    </div>
                </div>
                <div class="flex justify-between items-center gap-4">
                    <div class="font-satoshi text-sm text-gray-800">Payment Date</div>
                    <div class="font-satoshi text-sm text-gray-900 font-medium">
                        {{ config.data.payment_date ? (config.data.payment_date | dateTime: dateTimeFormat) : "-" }}
                    </div>
                </div>
                <div class="flex justify-between items-center gap-4">
                    <div class="font-satoshi text-sm text-gray-800">Payment By</div>
                    <div class="font-satoshi text-sm text-gray-900 font-medium">
                        {{ config.data?.paid_by?.full_name || "-" }}
                    </div>
                </div>
                <div class="flex justify-between items-center gap-4" *ngIf="!config.data.paymentMethod">
                    <sms-autocomplete
                        *ngIf="config.data.is_active"
                        [options]="paymentMenthodOptions"
                        [formControl]="paymentMenthod"
                        label="Payment Mode"
                    ></sms-autocomplete>
                    <ng-container *ngIf="config.data.is_paid">
                        <div class="font-satoshi text-sm text-gray-800">Payment Mode</div>
                        <div class="font-satoshi text-sm text-gray-900 font-medium">
                            {{ config.data.payment_method || "-" }}
                        </div>
                    </ng-container>
                </div>
            </div>
            <ng-container>
                <sms-divider [horizontal]="true"></sms-divider>
                <div class="flex justify-end items-center gap-3">
                    <button [variant]="'outlined'" (click)="onClose()" smsButton size="small" type="button">
                        Close
                    </button>
                    <!-- *ngIf="config.data.is_active && paymentMenthod.value !== 'Online'" -->
                    <button
                        *ngIf="config.data.is_active"
                        [loading]="isLoading$ | async"
                        (click)="payFees()"
                        smsButton
                        size="small"
                        type="button"
                    >
                        Pay Now
                    </button>
                    <!-- <button
                        *ngIf="onlinePaymentEnabled && config.data.is_active && paymentMenthod.value === 'Online'"
                        [loading]="isLoading$ | async"
                        smsButton
                        size="small"
                        type="submit"
                    >
                        Pay Now
                    </button> -->

                    <button
                        *ngIf="config.data.is_paid"
                        (click)="downloadAdmissionForm(config.data.id, 'instalment')"
                        smsButton
                        size="small"
                        type="button"
                    >
                        Download Instalment Recipt
                    </button>
                </div></ng-container
            >
        </ng-container>
    </sms-payment-warpper>
</div>
