import { createFeature, createReducer, on } from '@ngrx/store';
import { userActions } from './user.action';
import { UserStoreInterface } from 'src/app/shared/interfaces';

const initailState: UserStoreInterface = {
  users: [],
};

const userFeature = createFeature({
  name: 'user',
  reducer: createReducer(
    initailState,
    on(userActions.create, (state) => ({ ...state })),
    on(userActions.update, (state) => ({ ...state }))
  ),
});

export const { name: userFeatureKey, reducer: userReducer } = userFeature;
