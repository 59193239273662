import { CommonModule } from "@angular/common";
import { Component, type OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { Store } from "@ngrx/store";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { distinctUntilChanged, throttleTime } from "rxjs";
import { ButtonDirective } from "src/app/core/directives";
import { lovsActions, selectIsMutationLoading } from "../../../store";
import { DividerComponent, FileUploadButtonComponent } from "../../ui";
@Component({
    selector: "sms-bulk-import",
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, ButtonDirective, DividerComponent, FileUploadButtonComponent],
    templateUrl: "./bulk-import.component.html",
    styleUrls: ["./bulk-import.component.scss"],
})
export class BulkImportComponent implements OnInit {
    fileField = new FormControl();
    isLoading$ = this.store.select(selectIsMutationLoading);
    clickedButton: null | string = null;

    constructor(
        private store: Store,
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig<{ downloadUrl: string; uploadUrl: string }>,
    ) {
        this.fileField.valueChanges.pipe(distinctUntilChanged(), throttleTime(500)).subscribe((value) => {
            if (value) {
                if (this.config.data) {
                    this.clickedButton = "upload";
                    const formData = new FormData();

                    formData.append("file", value);
                    this.store.dispatch(
                        lovsActions.uploadSampleExcelFile({
                            data: { data: formData, url: this.config.data?.uploadUrl, onClose: this.onClose },
                        }),
                    );
                }
            }
        });
    }

    downloadSmpleFile() {
        if (this.config.data) {
            this.clickedButton = "download";

            this.store.dispatch(lovsActions.downloadSampleExcelFile({ data: { url: this.config.data?.downloadUrl } }));
        }
    }

    ngOnInit(): void {}

    onClose = (success: boolean = false) => {
        this.ref.close(success);
    };
}
