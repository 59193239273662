import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { AppEventComponent } from "./shared/components/app-event/app-event.component";
import { PrimeNGModule } from "./shared/modules/primeng/primeng.module";

@Component({
    selector: "app-root",
    standalone: true,
    imports: [CommonModule, RouterOutlet, PrimeNGModule, AppEventComponent],
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    publicUrls = ["/", "/create-enquiry", "/admission", "/components", "/login", "/register"];
    privateUrls = ["/student"];
    userData = null;
    ref: any = null;

    constructor() {}
}
