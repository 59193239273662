import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
    StudentBonafideInterface,
    StudentImportResponseInterface,
    StudentInterface,
    StudentLcInterface,
    StudentLeavingCertificateInterface,
} from "../../interfaces";

@Injectable({
    providedIn: "root",
})
export class StudentService {
    constructor(private httpClient: HttpClient) {}

    updateStudent(data: any): Observable<any> {
        return this.httpClient.put<any>(`${environment.API_URL}/admin/student-api/${data.id}/`, data);
    }

    uploadStudentsExcel(data: FormData): Observable<{ data: StudentImportResponseInterface[]; message: string }> {
        return this.httpClient.post<{ data: StudentImportResponseInterface[]; message: string }>(
            `${environment.API_URL}/students-bulk-upload-preview/`,
            data,
        );
    }

    downloadSampleExcel(): Observable<string> {
        return this.httpClient.get<string>(`${environment.API_URL}/students-bulk-upload-preview/`);
    }

    uploadStudentData(data: StudentInterface[]): Observable<any> {
        return this.httpClient.put<any>(`${environment.API_URL}/students-bulk-upload/`, { data });
    }

    getSingleStudentData<StudentInterface>(student_id: string): Observable<StudentInterface> {
        return this.httpClient.get<StudentInterface>(`${environment.API_URL}/admin/student-api/${student_id}/`);
    }

    downloadLcPDF(data: StudentLeavingCertificateInterface) {
        return this.httpClient.post<{ path: string }>(`${environment.API_URL}/leaving-certificate-print/`, data);
    }

    downloadBonafidePDF(data: StudentBonafideInterface) {
        return this.httpClient.post<{ path: string }>(`${environment.API_URL}/bonafide-print/`, data);
    }

    dectivateStudent(student_id: string) {
        return this.httpClient.put<{ path: string }>(`${environment.API_URL}/admin/student-deactivate-api/${student_id}/`, {
            is_active: false,
        });
    }

    getStudentLcData(student_id: string): Observable<StudentLcInterface> {
        return this.httpClient.get<StudentLcInterface>(
            `${environment.API_URL}/leaving-certificate-data/${student_id}/`,
        );
    }
}
