import { createFeature, createReducer, on } from "@ngrx/store";
import { SectionStoreInterface } from "src/app/shared/interfaces";
import { sectionActions } from "./section.action";

const initailState: SectionStoreInterface = {
    sections: [],
};

const sectionFeature = createFeature({
    name: "section",
    reducer: createReducer(
        initailState,
        on(sectionActions.setSections, (state, action) => {
            return {
                ...state,
                sections: action.data,
            };
        }),
        on(sectionActions.create, (state) => ({ ...state })),
        on(sectionActions.update, (state) => ({ ...state })),
    ),
});

export const { name: sectionFeatureKey, reducer: sectionReducer, selectSections } = sectionFeature;
