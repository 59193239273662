import { createActionGroup, props } from "@ngrx/store";
import { GetSchoolConfigItemParams, PostSchoolConfigItem, SchoolConfigItemInterface } from "../../interfaces";

export const schoolConfigActions = createActionGroup({
    source: "school-config",
    events: {
        "get config item": props<{
            params: GetSchoolConfigItemParams;
        }>(),
        "set config item data": props<{
            data: {
                params: string;
                data: SchoolConfigItemInterface;
            };
        }>(),
        "save config item": props<{
            data: PostSchoolConfigItem;
            message: string;
        }>(),
        "save attendance settings": props<{
            data: PostSchoolConfigItem;
            message: string;
            id: string | null;
            onSuccess: any;
        }>(),
        "genrate QR Code": props<{ id: string }>(),
        "set QR code": props<{ qrCode: string }>(),
    },
});
