<ng-container *ngIf="control.disabled; else elseTemplate">
    <div class="flex flex-col gap-1">
        <sms-label>{{ label }}</sms-label>
        <p class="text-base font-satoshi text-gray-900 px-1">
            {{ control.getRawValue() || "-" }}
        </p>
    </div>
</ng-container>
<ng-template #elseTemplate>
    <div class="float-label relative">
        <input
            id="{{ label }}"
            *ngIf="!mask"
            [type]="type"
            [min]="min"
            [autocomplete]="'off'"
            [max]="max"
            [placeholder]="placeholder"
            [class.!pl-10]="!!leftIcon"
            [class.!pr-10]="!!rightIcon"
            [formControl]="control"
            [class.invalid]="isError()"
            [attr.inputmode]="type === 'number' ? 'numeric' : ''"
            pInputText
            autocomplete="false"
        />
        <p-inputMask
            id="{{ label }}"
            *ngIf="mask"
            [mask]="maskPattern"
            [placeholder]="placeholder ? placeholder : maskPattern"
            [class.!pl-10]="!!leftIcon"
            [class.!pr-10]="!!rightIcon"
            [formControl]="control"
            [autocomplete]="'off'"
            [class.invalid]="isError()"
            [attr.inputmode]="type === 'number' ? 'numeric' : ''"
        ></p-inputMask>

        <i
            class="{{ rightIcon }} text-lg absolute right-1 top-1/2 translate-center text-gray-500"
            *ngIf="rightIcon"
            [ngStyle]="{ opacity: control.disabled ? 0.38 : 1 }"
        ></i>
        <i
            class="{{ leftIcon }} text-lg absolute left-5 top-1/2 translate-center text-gray-500"
            *ngIf="leftIcon"
            [ngStyle]="{ opacity: control.disabled ? 0.38 : 1 }"
        ></i>

        <sms-label *ngIf="label" [required]="isRequired">{{ label }}</sms-label>
        <sms-help-text *ngIf="!isError() && helpText">{{ helpText }}</sms-help-text>
        <sms-display-validation-error
            *ngIf="isError()"
            [control]="control"
            [label]="label"
        ></sms-display-validation-error>
    </div>
</ng-template>
