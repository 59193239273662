<div *ngIf="!horizontal" class="h-full bg-gray-200 w-[0.0625rem] mx-4">
  <span class="text-gray-500 px-2">
    <ng-content> </ng-content>
  </span>
</div>
<div *ngIf="horizontal" class="w-full bg-gray-200 h-[0.0625rem] my-4 relative">
  <div class="absolute translate-center top-1/2 left-1/2">
    <span class="text-gray-500 px-2 bg-[#ffffff60]">
      <ng-content></ng-content>
    </span>
  </div>
</div>
