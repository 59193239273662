import { CommonModule } from "@angular/common";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { DialogService } from "primeng/dynamicdialog";
import { Subscription, map } from "rxjs";

import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";

import { schoolConfig } from "src/app/core/config";
import { ButtonDirective } from "src/app/core/directives";
import { DateTimePipe, OrdinalSuffixPipe } from "src/app/core/pipes";

import {
    AlertComponent,
    ConfirmDialogComponent,
    DialogComponent,
    FullPageErrorComponent,
    HeadingComponent,
} from "src/app/shared/components/ui";

import {
    AgreeDeclarationInterface,
    FeesInstallmentInterface,
    FeesInterface,
    PostAdmissionStepResponse,
} from "src/app/shared/interfaces";

import { QuillModule } from "ngx-quill";
import { ContentManagementComponentEnum } from "src/app/shared/enum/settings";
import {
    admissionActions,
    feesActions,
    selectAdmissionFees,
    selectAdmissionIdsData,
    selectIsFullPageLoading,
    selectIsMutationLoading,
    selectOrderData,
} from "src/app/shared/store";
import { contentManagementActions, selectContentData } from "src/app/shared/store/settings/content-management";
import { PaymentWarpperComponent } from "../../payment";

@Component({
    selector: "sms-fees-structure",
    standalone: true,
    imports: [
        CommonModule,
        HeadingComponent,
        AlertComponent,
        ButtonDirective,
        OrdinalSuffixPipe,
        FullPageErrorComponent,
        DialogComponent,
        ConfirmDialogComponent,
        PaymentWarpperComponent,
        QuillModule,
    ],
    providers: [DateTimePipe],
    templateUrl: "./fees-structure.component.html",
    styleUrls: ["./fees-structure.component.scss"],
})
export class FeesStructureComponent implements OnInit, OnDestroy {
    admissionFeesData: FeesInterface | null = null;
    isLoading$ = this.store.select(selectIsMutationLoading);
    isFullPageLoading$ = this.store.select(selectIsFullPageLoading);
    admissionIdsData!: PostAdmissionStepResponse | null;
    orderData: any = null;
    subscription: Subscription = new Subscription();

    contentData: string | undefined = undefined;

    @ViewChild("submitButton") submitButton: ElementRef;

    constructor(
        private store: Store,
        private dateTimePipe: DateTimePipe,
        private dialogService: DialogService,
        public router: Router,
    ) {
        this.store.dispatch(
            contentManagementActions.getContentManagementData({
                component: ContentManagementComponentEnum.ADMISSION_FEE_NOTES,
            }),
        );
    }

    ngOnInit(): void {
        scrollTo(0, 0);
        this.subscription.add(
            this.store
                .select(selectAdmissionIdsData)
                .pipe(
                    map((value) => {
                        if (value) {
                            this.admissionIdsData = value;
                            this.store.dispatch(feesActions.getAdmissionFees({ admissionId: value.admission }));
                        }
                        return value;
                    }),
                )
                .subscribe(() => {
                    this.store.select(selectAdmissionFees).subscribe((value) => (this.admissionFeesData = value));
                }),
        );

        this.subscription.add(
            this.store.select(selectOrderData).subscribe((orderData) => {
                this.orderData = orderData;

                if (orderData) {
                    setTimeout(() => {
                        if (this.submitButton) {
                            this.submitButton.nativeElement.click();
                        }
                    }, 500);
                }
            }),
        );

        this.store
            .select(selectContentData({ key: ContentManagementComponentEnum.ADMISSION_FEE_NOTES }))
            .subscribe((data) => {
                this.contentData = data?.content;
            });
    }

    onSave() {
        this.show();
    }

    onBack() {
        this.store.dispatch(admissionActions.previousStep());
    }

    getFormatedDate(item: FeesInstallmentInterface) {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const createdDate = new Date(currentYear, item.due_month - 1, item.due_date);
        return this.dateTimePipe.transform(createdDate);
    }

    show() {
        let ref = this.dialogService.open(ConfirmDialogComponent, {
            width: "90%",
            contentStyle: { overflow: "auto" },
            header: "Proceed to confirm",
            data: {
                content: `Please note: Your child's interview will be confirmed upon payment of the Rs. ${
                    this.admissionFeesData!.form_fees || 0
                }/- form fee. Please pay admission form fee at ${
                    schoolConfig.school_name
                }'s admin office before interview.`,
            },
            baseZIndex: 10000,
            maximizable: false,
            dismissableMask: true,
            styleClass: "small-responsive-dialog",
        });

        this.subscription.add(
            ref.onClose.subscribe((value) => {
                if (value) {
                    const data: AgreeDeclarationInterface = {
                        step: 6,
                        admission: this.admissionIdsData?.admission as string,
                        student: this.admissionIdsData?.student as string,
                    };
                    this.store.dispatch(
                        admissionActions.paymentCompleted({
                            data,
                        }),
                    );
                }
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
