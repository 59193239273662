<ng-container *ngIf="control.disabled; else elseTemplate">
    <div class="flex flex-col gap-1">
        <sms-label>{{ label }}</sms-label>

        <p class="text-base font-satoshi text-gray-900 px-1">
            {{ (field ? control.getRawValue() && control.getRawValue()[field] : control.getRawValue()) || "-" }}
        </p>
    </div>
</ng-container>
<ng-template #elseTemplate>
    <div class="p-field w-full">
        <span class="p-float-label w-full">
            <p-autoComplete
                class="w-full block"
                #autocomplete
                [suggestions]="filterdOptions"
                [field]="field"
                [placeholder]="placeholder"
                [formControl]="control"
                [showEmptyMessage]="true"
                [inputStyleClass]="isError() ? 'invalid' : ''"
                [multiple]="multiple"
                [forceSelection]="true"
                (completeMethod)="filterData($event)"
                (click)="autocomplete.handleDropdownClick($event)"
                appendTo="body"
                emptyMessage="No options to show"
            >
                <!-- (onFocus)="autocomplete.handleDropdownClick($event)" -->
            </p-autoComplete>
            <div
                class="absolute rounded right-[1px] hover:bg-slate-100 top-[2px] cursor-pointer h-[40px] bg-background w-10 flex justify-center items-center"
                [ngStyle]="{ opacity: control.disabled ? 0.38 : 1 }"
                [class.cursor-default]="control.disabled"
                (click)="!control.disabled && autocomplete.handleDropdownClick($event)"
            >
                <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M13.6922 1.94219L7.44219 8.19219C7.38415 8.2503 7.31522 8.2964 7.23934 8.32785C7.16347 8.3593 7.08214 8.37549 7 8.37549C6.91787 8.37549 6.83654 8.3593 6.76067 8.32785C6.68479 8.2964 6.61586 8.2503 6.55782 8.19219L0.307816 1.94219C0.220309 1.85478 0.160705 1.74337 0.136548 1.62207C0.112392 1.50076 0.12477 1.37502 0.172115 1.26076C0.21946 1.14649 0.299644 1.04884 0.402515 0.980175C0.505386 0.911506 0.626319 0.874903 0.750004 0.875H13.25C13.3737 0.874903 13.4946 0.911506 13.5975 0.980175C13.7004 1.04884 13.7805 1.14649 13.8279 1.26076C13.8752 1.37502 13.8876 1.50076 13.8635 1.62207C13.8393 1.74337 13.7797 1.85478 13.6922 1.94219Z"
                        fill="#6B7280"
                    />
                </svg>
            </div>
            <sms-label *ngIf="label" [required]="isRequired">{{ label }}</sms-label>

            <sms-display-validation-error
                *ngIf="isError()"
                [control]="control"
                [label]="label"
            ></sms-display-validation-error>
        </span>
    </div>
</ng-template>
