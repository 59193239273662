import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { DateTimePipe } from "src/app/core/pipes";
import { HomeworkInterface } from "src/app/shared/interfaces";

@Component({
    selector: "sms-homework-card",
    standalone: true,
    imports: [CommonModule, RouterLink, DateTimePipe],
    templateUrl: "./homework-card.component.html",
    styleUrls: ["./homework-card.component.scss"],
})
export class HomeworkCardComponent {
    @Input() item?: HomeworkInterface;
    @Input() isCard: boolean = true;
    @Input() teacher: boolean = true;
}
