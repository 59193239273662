<div class="relative bg-neutral-100 rounded-xl py-3 px-4">
    <i class="ph ph-magnifying-glass absolute translate-center top-1/2 left-5 text-gray-400 text-lg"></i>

    <input
        class="w-full outline-none bg-transparent pl-6 placeholder:text-gray-300 text-gray-500 text-base font-satoshi"
        [formControl]="control"
        type="text"
        placeholder="Search Student"
    />
    <i
        class="ph ph-x absolute translate-center top-1/2 right-1 text-gray-300 text-lg"
        *ngIf="control.value"
        (click)="control.reset()"
    ></i>
</div>
