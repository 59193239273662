import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, mergeMap, of, switchMap } from "rxjs";
import { downloadFile } from "src/app/core/helpers";
import { GenericApiService } from "../../services";
import { appEventActions } from "../app-event";
import { lovsActions } from "./lovs.action";

@Injectable()
export class LovsEffects {
    constructor(
        private actions$: Actions,
        private genericApiService: GenericApiService,
    ) {}

    getLovsData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(lovsActions.getLovData),
            mergeMap((data) => {
                return this.genericApiService.getAll(data.data).pipe(
                    mergeMap((res) => {
                        return of(
                            lovsActions.setLovData({
                                data: res,
                                key: data.data.url,
                            }),
                        );
                    }),
                );
            }),
        ),
    );

    downloadSampleExcelFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(lovsActions.downloadSampleExcelFile),
            switchMap((data) => {
                return this.genericApiService.downloadSampleExcel(data.data.url).pipe(
                    switchMap((resp) => {
                        downloadFile(
                            typeof resp === "string" ? resp : resp.file,
                            data.data.fileName || "sample-file.xlsx",
                        );
                        if (data.onSuccess) {
                            data.onSuccess();
                        }
                        return of(appEventActions.emptyAction());
                    }),
                    catchError((err) => {
                        if (data.onSuccess) {
                            data.onSuccess();
                        }
                        return of(appEventActions.handleError(err));
                    }),
                );
            }),
        ),
    );

    uploadSampleExcelFile$ = createEffect(() =>
        this.actions$.pipe(
            ofType(lovsActions.uploadSampleExcelFile),
            switchMap((payload) =>
                this.genericApiService.uploadStudentsExcel(payload.data.data, payload.data.url).pipe(
                    switchMap(() => {
                        payload.data.onClose(true);
                        return of(
                            appEventActions.addToast({
                                detail: "Data imported successfully",
                                severity: "success",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );
}
