import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
    selector: "sms-display-validation-error",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./display-validation-error.component.html",
    styleUrls: ["./display-validation-error.component.scss"],
})
export class DisplayValidationErrorComponent {
    @Input() control?: FormControl | undefined;
    @Input() label?: string | undefined;

    errorMessageObject: {
        [key: string]: (field: string, errorObj?: any) => string;
    } = {
        required: (field: string) => {
            return `${field} is required`;
        },
        invalidLatitude: (_: string) => {
            return `Latitude is not valid`;
        },
        invalidLongitude: (field: string) => {
            return `Longitude is not valid`;
        },
        email: (field: string) => {
            return `${field} must be a valid email`;
        },
        minlength: (field: string, errorObj?: any) => {
            return `${field} must be greater then or equals to ${errorObj.requiredLength} charechters long`;
        },
        maxlength: (field: string, errorObj?: any) => {
            return `${field} must be less then or equals to ${errorObj.requiredLength} charechters long`;
        },
        min: (field: string, errorObj?: any) => {
            return `${field} must be greater then or equals to ${errorObj.min}`;
        },
        max: (field: string, errorObj?: any) => {
            return `${field} must be less then or equals to ${errorObj.max}`;
        },
        minTime: (field: string, errorObj?: any) => {
            return `${field} must be greater then ${errorObj.minTime}`;
        },
        fileMaxSize: (_: string, errorObj?: any) => {
            return `File Size must be less then ${errorObj.requiredSize}`;
        },
        fileMinSize: (_: string, errorObj?: any) => {
            return `File Size must be greater then ${errorObj.requiredSize}`;
        },
    };

    getErrorMessages = (): string | null => {
        let messages = [];
        if (this.control) {
            if (this.control.errors) {
                for (const key of Object.keys(this.control.errors)) {
                    messages.push(
                        this.errorMessageObject[key]
                            ? this.errorMessageObject[key](this.label || "This Field", this.control.errors[key])
                            : "",
                    );
                }
            }
        }

        return messages[0] ? messages[0] : null;
    };
}
