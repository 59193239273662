import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { DialogService } from "primeng/dynamicdialog";
import { catchError, exhaustMap, of, switchMap, withLatestFrom } from "rxjs";
import { downloadFile } from "src/app/core/helpers";
import { StudentDataInterface } from "src/app/core/interfaces";
import { authActions, selectUserData } from "src/app/core/stores";
import { ShowFormNumberComponent } from "src/app/features/public/admission/components/show-form-number/show-form-number.component";
import { AdmissionService } from "src/app/features/public/admission/service";
import { AdmissionFeesInterface, AdmissionInterface } from "src/app/shared/interfaces";
import { appEventActions, paymentActions } from "src/app/shared/store";
import { CURRENT_USER_DATA } from "../../constants";
import { LocalStorageService } from "../../services";
import { admissionActions } from "./admission.action";
import { selectAdmissionData, selectAdmissionIdsData } from "./admission.reducer";

@Injectable()
export class AdmissionEffects {
    constructor(
        private actions$: Actions,
        private admissionService: AdmissionService,
        private store: Store,
        private dialogService: DialogService,
        private localStorageService: LocalStorageService,
    ) {}

    reciptNameObject: Record<string, string> = {
        instalment: "Instalment",
        form_fees: "Form Fees",
        deposit_amount: "Deposit Amount",
    };

    saveChildrenInfoData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.saveChildrenInfo),
            withLatestFrom(this.store.select(selectAdmissionData)),
            switchMap(([data, admissionData]) =>
                this.admissionService.saveAdmissionData(data.data).pipe(
                    switchMap((res) => {
                        if (!admissionData)
                            this.dialogService.open(ShowFormNumberComponent, {
                                width: "80%",
                                closable: false,
                                showHeader: false,
                                data: res.form_number,
                                resizable: false,
                                dismissableMask: true,
                                styleClass: "small-responsive-dialog",
                            });
                        return of(
                            admissionActions.nextStep(),
                            admissionActions.saveAdmissionIdsData({
                                data: {
                                    ...res,
                                    currentStep: 2,
                                },
                            }),

                            appEventActions.addToast({
                                detail: "Student info saved successfully",
                                severity: "success",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    saveParentsInfoData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.saveSupplementryInfo),
            switchMap((data) =>
                this.admissionService.saveAdmissionData(data.data).pipe(
                    switchMap((data) => {
                        return of(
                            admissionActions.nextStep(),
                            admissionActions.saveAdmissionIdsData({
                                data: {
                                    ...data,
                                    currentStep: 3,
                                },
                            }),
                            appEventActions.addToast({
                                detail: "Supplementry info saved successfully",
                                severity: "success",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    saveSupplementryInfoData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.saveParentsInfo),
            switchMap((data) =>
                this.admissionService.saveAdmissionData(data.data).pipe(
                    switchMap((data) => {
                        return of(
                            admissionActions.saveAdmissionIdsData({
                                data: {
                                    ...data,
                                    currentStep: 4,
                                },
                            }),
                            admissionActions.nextStep(),
                            appEventActions.addToast({
                                detail: "Parents info saved successfully",
                                severity: "success",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    saveDocuemntsAttachedData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.saveDocumentsAttached),
            switchMap((data) =>
                this.admissionService.saveDocumentAttached(data.data).pipe(
                    switchMap((resp) => {
                        if (data.documentsOnly) {
                            let curData = this.localStorageService.getItem<StudentDataInterface>(CURRENT_USER_DATA);
                            if (curData) {
                                curData.is_document_added = true;
                                this.localStorageService.setItem(CURRENT_USER_DATA, curData);
                            }
                            return of(
                                authActions.setDocumentAdded(),
                                admissionActions.clearState(),
                                appEventActions.redirect({
                                    navigate: ["/student/home"],
                                }),
                                appEventActions.addToast({
                                    detail: "Documents saved successfully",
                                    severity: "success",
                                }),
                            );
                        } else {
                            return of(
                                admissionActions.nextStep(),
                                admissionActions.saveAdmissionIdsData({
                                    data: {
                                        ...resp,
                                        currentStep: 5,
                                    },
                                }),
                                appEventActions.addToast({
                                    detail: "Documents saved successfully",
                                    severity: "success",
                                }),
                            );
                        }
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    aggreDeclarationForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.agreeDeclarationForm),
            switchMap((data) =>
                this.admissionService.saveAdmissionData(data.data).pipe(
                    switchMap((data) => {
                        return of(
                            admissionActions.saveAdmissionIdsData({
                                data: {
                                    ...data,
                                    currentStep: 6,
                                    declaration_agreed: true,
                                },
                            }),
                            admissionActions.nextStep(),
                            appEventActions.addToast({
                                detail: "Declaration Acknowledged successfully",
                                severity: "success",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    paymentCompleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.paymentCompleted),
            switchMap((data) =>
                this.admissionService.saveAdmissionData(data.data).pipe(
                    switchMap(() => {
                        return of(
                            // paymentActions.createOrder({
                            //   data: {
                            //     id: resp.form_fees_id,
                            //     type: PaymentTypes.OTHER_CHARGE,
                            //     other_payment_id: resp.form_fees_id,
                            //     payment_method: PaymentMethodEnum.ONLINE,
                            //     initialForm: true,
                            //   },
                            // })
                            admissionActions.clearState(),
                            appEventActions.addToast({
                                detail: "Application Completed, your application is under review admin will contact you.",
                                severity: "success",
                            }),
                            appEventActions.redirect({
                                navigate: ["/"],
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    getStepData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.getAdmissionData),
            withLatestFrom(this.store.select(selectAdmissionIdsData)),
            switchMap(([data, admissionData]) =>
                admissionData?.admission
                    ? this.admissionService
                          .getStepDetails<AdmissionInterface>(data.stepNumber, admissionData?.admission as string)
                          .pipe(
                              exhaustMap((response) => {
                                  return of(
                                      !response
                                          ? appEventActions.emptyAction()
                                          : admissionActions.setAdmissionData({
                                                stepNumber: data.stepNumber,
                                                data: response,
                                            }),
                                  );
                              }),
                              catchError((err) => {
                                  return of(appEventActions.handleError(err));
                              }),
                          )
                    : of(appEventActions.emptyAction()),
            ),
        ),
    );

    findPartialAdmissions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.findPartialAdmissions),
            switchMap((data) =>
                this.admissionService.findPartialAdmissions(data.data).pipe(
                    switchMap((response) => {
                        return !response.length
                            ? of(
                                  appEventActions.addToast({
                                      severity: "info",
                                      detail: "No Partially filled admission requests found with the provided Form Number & Date of Birth combination.",
                                  }),
                                  admissionActions.setPartialAdmissions({ data: [] }),
                              )
                            : of(
                                  admissionActions.setPartialAdmissions({
                                      data: response,
                                  }),
                              );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    setPartialAdmissionForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.setPartialAdmissionForm),
            switchMap((data) => {
                return of(
                    admissionActions.setCurrentStep({
                        stepNumber: data.data.admission_step,
                    }),
                    admissionActions.setPartialAdmissions({
                        data: [],
                    }),
                    admissionActions.saveAdmissionIdsData({
                        data: {
                            admission: data.data.id,
                            student: data.data.student.id,
                            form_number: data.data.form_number,
                            currentStep: data.data.admission_step,
                            declaration_agreed: data.data.declaration_agreed,
                        },
                    }),
                    appEventActions.redirect({
                        navigate: ["/admission/new"],
                    }),
                );
            }),
        ),
    );

    setInterviewScheduledAdmissionForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.findInterviewScheduledAdmissions),
            switchMap((data) =>
                this.admissionService.findSubmitedAdmissions(data.data).pipe(
                    switchMap((response) => {
                        return !response.length
                            ? of(
                                  appEventActions.addToast({
                                      severity: "info",
                                      detail: "No submitted admission requests found with the provided Form Number & Date of Birth combination.",
                                  }),
                                  admissionActions.setInterviewScheduledAdmissions({ data: [] }),
                              )
                            : of(
                                  admissionActions.setInterviewScheduledAdmissions({
                                      data: response,
                                  }),
                              );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    downloadAdmissionForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.downloadAdmissionForm),
            switchMap((data) =>
                this.admissionService.downloadAdmissionForm(data.form_number).pipe(
                    switchMap((response) => {
                        downloadFile(response.path);

                        return of(
                            appEventActions.addToast({
                                detail: "Admission Form downloaded successfully.",
                                severity: "success",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    downloadInstalmentPDF$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.downloadInstalmentRecipt),
            exhaustMap((data) =>
                this.admissionService.downloadInstalmentRecipt(data.data).pipe(
                    switchMap((response) => {
                        downloadFile(response.path);
                        return of(
                            appEventActions.addToast({
                                detail: `${this.reciptNameObject[data.data.type]} receipt downloaded successfully.`,
                                severity: "success",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    findPartialAdmissionByAdmissionId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.findPartialAdmissionByAdmissionId),
            switchMap((data) =>
                this.admissionService.findPartialAdmissionByAdmissionId(data.admissionId).pipe(
                    switchMap((response) => {
                        return of(
                            admissionActions.setPartialAdmissionData({
                                data: response,
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    setPartialAdmissionData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.setPartialAdmissionData),
            switchMap((data) => {
                return of(
                    admissionActions.setCurrentStep({
                        stepNumber: data.data.admission_step,
                    }),
                    admissionActions.saveAdmissionIdsData({
                        data: {
                            admission: data.data.id,
                            student: data.data.student.id,
                            form_number: data.data.form_number,
                            currentStep: data.data.admission_step,
                            declaration_agreed: data.data.declaration_agreed,
                        },
                    }),
                );
            }),
        ),
    );

    getAdmissionFeesData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.getAdmissionFeesData),
            withLatestFrom(this.store.select(selectUserData)),
            switchMap(([data, userData]) =>
                this.admissionService
                    .getAdmissionFeesData(data.data ? data.data.admissionId : (userData?.admission_id as string))
                    .pipe(
                        exhaustMap((response) => {
                            return of(
                                !response
                                    ? appEventActions.emptyAction()
                                    : admissionActions.setAdmissionFeesData({
                                          data: response as AdmissionFeesInterface,
                                      }),
                            );
                        }),
                        catchError((err) => {
                            return of(appEventActions.handleError(err));
                        }),
                    ),
            ),
        ),
    );

    payInstalment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.payInstalment),
            switchMap((data) =>
                this.admissionService.payInstalment(data.data).pipe(
                    switchMap((resp) => {
                        if (data.onClose) {
                            data.onClose(true);
                        } else {
                            if (document.getElementsByClassName("p-sidebar-mask").length) {
                                (document.getElementsByClassName("p-sidebar-mask")[0] as HTMLDivElement)?.click();
                            }
                        }
                        return of(
                            paymentActions.closePaymentBox(),
                            admissionActions.getAdmissionFeesData({
                                data: {
                                    admissionId: resp.admission,
                                },
                            }),
                            appEventActions.addToast({
                                detail: "Instalment Paid Successfully",
                                severity: "success",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    payOtherCharges$ = createEffect(() =>
        this.actions$.pipe(
            ofType(admissionActions.payOtherCharges),
            switchMap((data) =>
                this.admissionService.payOtherCharges(data.data).pipe(
                    switchMap((res) => {
                        if (data.onClose) {
                            data.onClose(true);
                        }
                        if (document.getElementsByClassName("p-component-overlay").length) {
                            (document.getElementsByClassName("p-component-overlay")[0] as HTMLDivElement)?.click();
                        }
                        if (document.getElementById("container")) {
                            document.getElementById("container")?.click();
                        }

                        this.store.dispatch(
                            admissionActions.getAdmissionFeesData({
                                data: {
                                    admissionId: res.admission,
                                },
                            }),
                        );
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Admission Form Fees Paid Sucessfully",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    getIdCardData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(admissionActions.getIdCardData),
            switchMap((data) =>
                this.admissionService.getIdCardData(data.student_id).pipe(
                    switchMap((resp) => {
                        return of(admissionActions.setIdCardData({ data: resp }));
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });
}
