import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  CreateSectionInterface,
  SectionInterface,
  onClose,
} from 'src/app/shared/interfaces';

export const sectionActions = createActionGroup({
  source: 'section',
  events: {
    'get sections': emptyProps(),
    'set sections': props<{ data: SectionInterface[] }>(),
    create: props<{
      data: CreateSectionInterface;
      onClose: onClose;
    }>(),
    update: props<{
      data: CreateSectionInterface;
      id: string;
      onClose: onClose;
    }>(),
  },
});
