import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { RouterLink, RouterLinkActive, RouterOutlet } from "@angular/router";
import { Store } from "@ngrx/store";
import { AvatarModule } from "primeng/avatar";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { Observable, Subscription, map, shareReplay } from "rxjs";
import { IconButtonDirective } from "src/app/core/directives";
import { EnvPipe } from "src/app/core/pipes";
import { selectMenuItems } from "src/app/core/stores";
import { selectIsFullPageLoading } from "src/app/shared/store";
import { AdminContainerComponent } from "../admin-container/admin-container.component";

@Component({
    selector: "sms-mobile-conainer",
    standalone: true,
    imports: [
        CommonModule,
        RouterOutlet,
        AvatarModule,
        IconButtonDirective,
        RouterLink,
        RouterLinkActive,
        EnvPipe,
        AdminContainerComponent,
        ProgressSpinnerModule,
    ],
    templateUrl: "./mobile-conainer.component.html",
    styleUrls: ["./mobile-conainer.component.scss"],
})
export class MobileConainerComponent implements OnInit, OnDestroy {
    subscription: Subscription = new Subscription();
    isMobileResolution = true;
    isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
        map((result) => {
            return result.matches;
        }),
        shareReplay(),
    );

    menuItems$ = this.store.select(selectMenuItems);
    isFullPageLoading = false;

    constructor(
        private store: Store,
        private breakpointObserver: BreakpointObserver,
    ) {
        if (window.innerWidth < 768) {
            this.isMobileResolution = true;
        } else {
            this.isMobileResolution = false;
        }
    }

    ngOnInit(): void {
        this.subscription.add(
            this.store.select(selectIsFullPageLoading).subscribe((value) => {
                this.isFullPageLoading = !!value;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
