import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, of, switchMap, withLatestFrom } from "rxjs";
import { AttendanceService } from "../../services";
import { appEventActions } from "../app-event";
import { attendanceActions } from "./attendance.action";
import { selectStudentAttendancesCache, selectStudentMonthlyAttendanceCache } from "./attendance.reducer";

@Injectable()
export class AttendanceEffects {
    constructor(
        private actions$: Actions,
        private attendanceService: AttendanceService,
        private store: Store,
    ) { }

    getStudentAttandanceDate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attendanceActions.getStudentAttandanceDate),
            withLatestFrom(this.store.select(selectStudentAttendancesCache)),
            switchMap(([data, attendanceData]) => {
                return attendanceData[JSON.stringify(data.params)]
                    ? of(appEventActions.emptyAction())
                    : this.attendanceService.getStudentAttandanceDate(data.params).pipe(
                        switchMap((resp) => {
                            return of(
                                attendanceActions.setStudentAttandanceData({
                                    data: {
                                        params: JSON.stringify(data.params),
                                        data: resp,
                                    },
                                }),
                            );
                        }),
                    );
            }),
        ),
    );

    markAttandance$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attendanceActions.markStudentAttandance),
            switchMap((data) =>
                this.attendanceService.markAttandance(data.data).pipe(
                    switchMap(() =>
                        of(
                            attendanceActions.markStudentAttandanceDateStatus({
                                date: data.data.attendance_date,
                            }),
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Attendance Marked Successfully",
                            }),
                        ),
                    ),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    getClassroomAttandanceStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attendanceActions.getClassroomAttandanceStatus),
            switchMap((data) => {
                return this.attendanceService.getAttendanceStatus(data.params).pipe(
                    switchMap((resp) => {
                        return of(attendanceActions.setClassroomAttandanceStatusData({ data: resp }));
                    }),
                );
            }),
        ),
    );

    getStudentMonthlyAttandance$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attendanceActions.getStudentMonthlyAttandance),
            withLatestFrom(this.store.select(selectStudentMonthlyAttendanceCache)),
            switchMap(([data, studentMonthlyAttendance]) => {
                return studentMonthlyAttendance[JSON.stringify(data.params)]
                    ? of(appEventActions.emptyAction())
                    : this.attendanceService.getStudentAttendanceStatus(data.params).pipe(
                        switchMap((resp) => {
                            return of(
                                attendanceActions.setStudentMonthlyAttandance({
                                    data: {
                                        params: JSON.stringify(data.params),
                                        data: resp,
                                    },
                                }),
                            );
                        }),
                    );
            }),
        ),
    );

    checkInTeacher$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attendanceActions.checkInTeacher),
            switchMap((data) =>
                this.attendanceService.checkInTeacher(data.data).pipe(
                    switchMap((resp) => {
                        setTimeout(() => window.location.reload(), 2000);
                        return of(
                            attendanceActions.setTeacherAttenanceForDate({
                                data: {
                                    data: resp.data,
                                    params: resp.data.attendance_date,
                                },
                            }),
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Logged in Successfully",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    checkOutTeacher$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attendanceActions.checkOutTeacher),
            switchMap((data) =>
                this.attendanceService.checkOutTeacher(data.data).pipe(
                    switchMap((resp) =>
                        of(
                            attendanceActions.setTeacherAttenanceForDate({
                                data: {
                                    data: resp.data,
                                    params: resp.data.attendance_date,
                                },
                            }),
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Logged out Successfully",
                            }),
                        ),
                    ),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    updateTeacherAttendance$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attendanceActions.updateTeacherAttendance),
            switchMap((data) =>
                this.attendanceService.updateTeacherAttendance(data.data).pipe(
                    switchMap(() => {
                        if (data.onClose) {
                            data.onClose(true)
                        }
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Teacher Attendance Updated Successfully",
                            }),
                        )
                    }
                    ),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    getTeacherAttenanceForDate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attendanceActions.getTeacherAttenanceForDate),
            switchMap((data) =>
                this.attendanceService.getTeacherAttenanceForDate(data.date).pipe(
                    switchMap((resp) => {
                        return of(
                            attendanceActions.setTeacherAttenanceForDate({
                                data: { data: resp, params: data.date },
                            }),
                        );
                    }),
                ),
            ),
        ),
    );

    getTeachersAttendanceStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attendanceActions.getTeachersAttendanceStatus),
            switchMap((data) => {
                return this.attendanceService.getTeachersAttendanceStatus(data.params).pipe(
                    switchMap((resp) => {
                        return of(
                            attendanceActions.setTeachersAttendanceStatus({ data: resp.attendance_status }),
                            attendanceActions.setTeachersAttendanceCrs({
                                data: resp.crs,
                            }),
                        );
                    }),
                );
            }),
        ),
    );

    createAttendanceChangeRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attendanceActions.createAttendanceChangeRequest),
            switchMap((data) =>
                this.attendanceService.createAttendanceChangeRequest(data.data).pipe(
                    switchMap((resp) => {
                        if (data.onClose) {
                            data.onClose(true);
                        }
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Change Request Created Successfully",
                            }),
                            attendanceActions.setTeachersAttendanceCrs({
                                data: {
                                    [data.data.attendance_date]: resp.data,
                                },
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    approveRejectAttendanceChangeRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(attendanceActions.approveRejectAttendanceChangeRequest),
            switchMap((data) =>
                this.attendanceService.approveRejectAttendanceChangeRequest(data.data).pipe(
                    switchMap((resp) => {
                        if (data.onClose) {
                            data.onClose(true);
                        }
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: `Change Request ${data.data.action} Successfully`,
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );
}
