import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Router,
  ActivatedRoute,
  NavigationEnd,
  RouterLink,
} from '@angular/router';
import { Subscription, distinctUntilChanged } from 'rxjs';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuItem } from 'primeng/api';

export interface IBreadCrumb {
  label: string;
  url: string;
}

@Component({
  selector: 'sms-breadcrumb',
  standalone: true,
  imports: [CommonModule, RouterLink, BreadcrumbModule],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  breadcrumbs: Array<MenuItem>;
  deliminator: string = '>';

  subscription: Subscription = new Subscription();

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
  }

  ngOnInit() {
    this.subscription.add(
      this.router.events.pipe(distinctUntilChanged()).subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.breadcrumbs = this.buildBreadCrumb(this.activatedRoute.root);
        }
      })
    );
  }

  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: MenuItem[] = []
  ): MenuItem[] {
    //If no routeConfig is avalailable we are on the root path

    let label =
      route.routeConfig && route.routeConfig.data
        ? (route.routeConfig.data as any).breadCrumb
        : '';
    let path =
      route.routeConfig && route.routeConfig.data
        ? `${route.routeConfig.path}`
        : '';

    //In the routeConfig the complete path is not available,
    //so we rebuild it each time
    const nextUrl = path ? `${url}/${path}` : url;

    const breadcrumb: MenuItem = {
      label: label,
      routerLink: breadcrumbs.length > 1 ? nextUrl : undefined,
    };
    // Only adding route with non-empty label
    const newBreadcrumbs = breadcrumb.label
      ? [...breadcrumbs, breadcrumb]
      : [...breadcrumbs];
    if (route.firstChild) {
      //If we are not on our current path yet,
      //there will be more children to look after, to build our breadcumb
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
