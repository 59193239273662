import { createActionGroup, props } from '@ngrx/store';
import {
  CreateFeesInterface,
  FeesInterface,
  onClose,
} from 'src/app/shared/interfaces';

export const feesActions = createActionGroup({
  source: 'fees',
  events: {
    'get admission fees': props<{ admissionId: string }>(),
    'set admission fees': props<{ data: FeesInterface }>(),
    create: props<{
      data: CreateFeesInterface;
      onClose: onClose;
    }>(),
    update: props<{
      data: CreateFeesInterface;
      onClose: onClose;
    }>(),
  },
});
