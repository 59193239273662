import { Directive, HostBinding, Input } from '@angular/core';

const statusClassObject: Record<string, string> = {
  Pending: 'text-error bg-error-light',
  Paid: 'text-success bg-success-light',
};

const baseClasses = 'w-min px-[0.41rem] py-[0.1rem] rounded text-center';

function getClasses(status: string) {
  return `${baseClasses} ${
    statusClassObject[status]
      ? statusClassObject[status]
      : statusClassObject['other']
  }`;
}

@Directive({
  selector: '[smsPaymentStatus]',
  standalone: true,
})
export class PaymentStatusDirective {
  _status: string = 'Other';

  @HostBinding('class')
  classes = getClasses('Other');

  @Input()
  set status(value: string) {
    this._status = value;
    this.classes = getClasses(value);
  }
  constructor() {}
}
