import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  ChangeAdmissionStatus,
  PostScheduleInterviewInterface,
} from '../interfaces';
import { EnquiryInterface, onClose } from 'src/app/shared/interfaces';
import { AdmissionStatusEnum } from 'src/app/shared/enum';

export const adminAdmissionActions = createActionGroup({
  source: 'admin-admission',
  events: {
    'schedule interview': props<{
      data: PostScheduleInterviewInterface;
      onClose: onClose;
    }>(),
    'schedule bulk interviews': props<{
      data: PostScheduleInterviewInterface;
      onClose: onClose;
    }>(),
    'change admission status': props<{
      data: ChangeAdmissionStatus;
      onClose: onClose;
    }>(),
    'update enquiry': props<{
      data: EnquiryInterface;
      id: string;
      onClose: onClose;
    }>(),
    'export admission excel': props<{ status?: AdmissionStatusEnum }>(),
    'export enquiries excel': emptyProps(),
    'export idcard excel': emptyProps(),
  },
});
