export function copyToClipboard(text: string) {
  if (!navigator.clipboard) {
    console.error('Clipboard API not supported');
    return;
  }

  navigator.clipboard.writeText(text).then(
    () => {
      console.log('Text copied to clipboard');
    },
    () => {
      console.error('Failed to copy text to clipboard');
    }
  );
}
