import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseInterface } from '../../../interfaces';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
  deps: [HttpClient],
})
export class DatagridService {
  constructor(private httpClient: HttpClient) {}

  getAllDatagridData(
    url: string,
    params?: any
  ): Observable<ResponseInterface<any[]>> {
    return this.httpClient.get<ResponseInterface<any[]>>(
      environment.API_URL + url,
      {
        params: params,
      }
    );
  }
}
