<p-tabMenu
    class="sms-tab"
    [model]="items"
    [activeItem]="activeItem"
    [scrollable]="true"
    [autofocus]="true"
    (activeItemChange)="onActiveItemChange($event)"
>
</p-tabMenu>
<ng-container [ngTemplateOutlet]="tabsBody" [ngTemplateOutletContext]="{ $implicit: activeItem }"></ng-container>
<div class="flex justify-center items-center h-[500px]" *ngIf="!activeItem">
    <sms-no-data></sms-no-data>
</div>
