import { createActionGroup, props } from "@ngrx/store";
import { onClose } from "src/app/shared/interfaces";

export const teacherActions = createActionGroup({
    source: "teacher",
    events: {
        create: props<{
            data: FormData;
            onClose: onClose;
        }>(),
        update: props<{
            data: FormData;
            id: string;
            onClose?: onClose;
            isProfileUpdate?: boolean;
        }>(),
        "get teacher data": props<{ id: string }>(),
        "re assign teacher": props<{ data: { old_teacher: string; new_teacher: string }; onClose?: onClose }>(),
    },
});
