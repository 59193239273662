<span class="absolute top-0 left-0 h-full rounded-l-sm bg-primary w-1" *ngIf="variant === 'primary'"></span>
<p
    class="font-satoshi lg:text-lg text-sm font-bold"
    [ngClass]="{
        'pl-4 py-1 text-heading uppercase': variant === 'primary',
        'py-2 text-primary': variant === 'secondary',
    }"
>
    <ng-content> </ng-content>
</p>
