import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { CreateSubjectInterface, SubjectInterface } from "../../../interfaces";

@Injectable({
    providedIn: "root",
    deps: [HttpClient],
})
export class SubjectService {
    constructor(private httpClient: HttpClient) {}

    getAllSubjects(): Observable<SubjectInterface[]> {
        return this.httpClient.get<SubjectInterface[]>(environment.API_URL + "/subjects/");
    }

    create(data: CreateSubjectInterface): Observable<CreateSubjectInterface> {
        console.log("In subjevct create");
        return this.httpClient.post<CreateSubjectInterface>(environment.API_URL + "/admin/subjects/create/", data);
    }

    update(data: CreateSubjectInterface, id: string): Observable<CreateSubjectInterface> {
        return this.httpClient.put<CreateSubjectInterface>(environment.API_URL + `/admin/subjects/${id}/`, data);
    }
}
