import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { AccordionModule } from "primeng/accordion";
import { CheckboxModule } from "primeng/checkbox";
import { Observable, Subscription } from "rxjs";
import { ButtonDirective } from "src/app/core/directives";

import { AccordionTabComponent, AlertComponent } from "src/app/shared/components/ui";

import { AgreeDeclarationInterface, BatchInterface, PostAdmissionStepResponse } from "src/app/shared/interfaces";

import {
    admissionActions,
    batchActions,
    selectAdmissionBatch,
    selectAdmissionIdsData,
    selectIsMutationLoading,
} from "src/app/shared/store";

import { QuillModule } from "ngx-quill";
import { DateTimePipe } from "src/app/core/pipes";
import { ContentManagementComponentEnum } from "src/app/shared/enum/settings";
import { contentManagementActions, selectContentData } from "src/app/shared/store/settings/content-management";

@Component({
    selector: "sms-declaration-form",
    standalone: true,
    imports: [
        CommonModule,
        AccordionModule,
        AccordionTabComponent,
        AlertComponent,
        ButtonDirective,
        CheckboxModule,
        ReactiveFormsModule,
        DateTimePipe,
        QuillModule,
    ],
    templateUrl: "./declaration-form.component.html",
    styleUrls: ["./declaration-form.component.scss"],
})
export class DeclarationFormComponent implements OnInit, OnDestroy {
    declaration: FormControl = new FormControl(null, Validators.required);
    error: string | null = null;
    admissionIdsData!: PostAdmissionStepResponse | null;

    admissionBatch$: Observable<null | BatchInterface> = this.store.select(selectAdmissionBatch);
    isLoading$ = this.store.select(selectIsMutationLoading);

    subscription: Subscription = new Subscription();
    contentData: string | undefined = undefined;

    constructor(private store: Store) {
        this.store.dispatch(
            contentManagementActions.getContentManagementData({
                component: ContentManagementComponentEnum.DECLARATION_RULES,
            }),
        );
    }

    ngOnInit(): void {
        scrollTo(0, 0);
        this.subscription.add(
            this.store.select(selectAdmissionIdsData).subscribe((value) => {
                this.admissionIdsData = value;
                if (value) {
                    this.store.dispatch(batchActions.getAdmissionBatch({ admissionId: value?.admission }));
                    if (value.declaration_agreed) {
                        this.declaration.setValue(true);
                    }
                }
            }),
        );

        this.store
            .select(selectContentData({ key: ContentManagementComponentEnum.DECLARATION_RULES }))
            .subscribe((data) => {
                this.contentData = data?.content;
            });
    }

    onSave() {
        if (!this.declaration.getRawValue()) {
            this.error = "Please accept declaration form";
        } else {
            this.error = null;
            const data: AgreeDeclarationInterface = {
                step: 5,
                admission: this.admissionIdsData?.admission as string,
                student: this.admissionIdsData?.student as string,
            };
            this.store.dispatch(
                admissionActions.agreeDeclarationForm({
                    data,
                }),
            );
        }
    }

    onBack() {
        this.store.dispatch(admissionActions.previousStep());
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
