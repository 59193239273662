import { createFeature, createReducer, on } from '@ngrx/store';
import { datagridActions } from './datagrid.action';
import { DatagridStoreInterface } from 'src/app/shared/interfaces';

const initailState: DatagridStoreInterface = {};

const datagridFeature = createFeature({
  name: 'datagrid',
  reducer: createReducer(
    initailState,
    on(datagridActions.setNewTableData, (state, action) => ({
      ...state,
      [JSON.stringify(action.key)]: {
        DatagridData: action.data.rows,
        DatagridDataCount: action.data.count,
      },
    }))
  ),
});

export const {
  name: datagridFeatureKey,
  reducer: datagridReducer,
  selectDatagridData,
  selectDatagridDataCount,
  selectDatagridState,
} = datagridFeature;
