<button (click)="visible = true" smsButton size="small" type="button" variant="text">
    <i class="ph-duotone ph-bank text-lg"></i>
    View Payment Details
</button>
<p-dialog
    [(visible)]="visible"
    [modal]="true"
    [style]="{ width: '800px' }"
    [breakpoints]="{ '820px': '90vw' }"
    [dismissableMask]="true"
>
    <ng-template pTemplate="header">
        <h4 class="text-center font-semibold font-satoshi text-lg text-heading w-full">Payment Details</h4>
    </ng-template>
    <div>
        <ng-container *ngIf="bank_details; else elseTemplate">
            <div
                class="w-full md:h-[450px] md:px-0 px-5 flex justify-center items-center self-stretch my-5 gap-5 md:flex-row flex-col"
            >
                <div class="md:w-1/2 flex flex-col gap-5 justify-between items-center h-full">
                    <div class="font-satoshi font-semibold text-lg text-gray-700">FOR ONLINE</div>
                    <div class="p-1 rounded-lg shadow border border-gray-100">
                        <img src="{{ bank_details.payment_qr_code }}" width="250px" height="250px" />
                    </div>
                    <p class="my-0 text-gray-300 font-satoshi text-center normal-case">
                        Kindly utilize this QR code for processing fee payments.
                    </p>
                </div>
                <p-divider class="h-full my-0 md:block hidden" layout="vertical" align="center"> Or </p-divider>
                <p-divider class="w-full my-0 block md:hidden" align="center"> Or </p-divider>
                <div class="md:w-1/2 md:gap-0 gap-5 flex flex-col justify-between items-center h-full">
                    <div class="font-satoshi font-semibold text-lg text-gray-700">FOR NEFT / RTGS / IMPS</div>
                    <div class="flex flex-col justify-center gap-2 border border-gray-200 rounded-lg w-[280px]">
                        <div class="w-full border-gray-200 border-b p-2">
                            <div class="text-gray-400 text-sm font-satoshi">Bank Name:</div>
                            <div class="text-gray-700 text-sm font-satoshi font-medium">
                                {{ bank_details.bank_name }}
                            </div>
                        </div>
                        <div class="w-full border-gray-200 border-b p-2">
                            <div class="text-gray-400 text-sm font-satoshi">Account Name:</div>
                            <div class="text-gray-700 text-sm font-satoshi font-medium">
                                {{ bank_details.account_name }}
                            </div>
                        </div>
                        <div class="w-full border-gray-200 border-b p-2">
                            <div class="text-gray-400 text-sm font-satoshi">Account Number:</div>
                            <div class="text-gray-700 text-sm font-satoshi font-medium">
                                {{ bank_details.account_number }}
                            </div>
                        </div>
                        <div class="w-full border-gray-200 border-b p-2">
                            <div class="text-gray-400 text-sm font-satoshi">IFSC code:</div>
                            <div class="text-gray-700 text-sm font-satoshi font-medium">
                                {{ bank_details.ifsc_code }}
                            </div>
                        </div>
                        <div class="w-full p-2">
                            <div class="text-gray-400 text-sm font-satoshi">Branch Name:</div>
                            <div class="text-gray-700 text-sm font-satoshi font-medium">
                                {{ bank_details.branch_name }}
                            </div>
                        </div>
                    </div>
                    <p class="my-0 text-gray-300 font-satoshi text-center normal-case">
                        Kindly utilize this Bank Account Details for processing fee payments.
                    </p>
                </div>
            </div>
        </ng-container>
        <ng-template #elseTemplate>
            <div class="my-5 text-gray-300 font-satoshi text-center normal-case">
                No payment Details available. Please contact your school administration for more information.
            </div>
        </ng-template>
    </div>
</p-dialog>
