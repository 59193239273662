import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { feesActions } from './fees.action';
import { catchError, concat, concatMap, of, switchMap } from 'rxjs';
import { FeesService } from 'src/app/shared/services';
import { appEventActions } from '../../app-event';

@Injectable()
export class FeesEffects {
  constructor(private actions$: Actions, private feesService: FeesService) {}

  getAdmissionFees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(feesActions.getAdmissionFees),

      concatMap((data) =>
        concat(
          // of(appEventActions.startFullPageLoading()),
          this.feesService.getAdmissionFees(data.admissionId).pipe(
            switchMap((data) =>
              of(
                feesActions.setAdmissionFees({ data })
                // appEventActions.endFullPageLoading()
              )
            )
          )
        )
      )
    )
  );

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(feesActions.create),
      switchMap((data) =>
        this.feesService.create(data.data).pipe(
          switchMap(() => {
            data.onClose(true);

            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Fee created successfully.',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(feesActions.update),
      switchMap((data) =>
        this.feesService.create(data.data).pipe(
          switchMap(() => {
            data.onClose(true);
            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Fee updated successfully.',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    );
  });
}
