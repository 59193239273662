import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RouterLink } from "@angular/router";
@Component({
    selector: "sms-action-card",
    standalone: true,
    imports: [CommonModule, RouterLink],
    templateUrl: "./action-card.component.html",
    styleUrls: ["./action-card.component.scss"],
})
export class ActionCardComponent {
    @Input({ required: true }) icon: string = "";
    @Input({ required: false }) direction?: "row" | "col" = "row";
    @Input({ required: true }) heading: string = "";
    @Input({ required: false }) count?: number | null;
    @Input({ required: true }) description: string = "";
    @Input({ required: false }) routerLink?: string | string[];
    @Input({ required: false }) queryParams?: Record<string, any> = {};

    @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter();

    onContainerClick(event: MouseEvent) {
        this.onClick.emit(event);
    }
}
