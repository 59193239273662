import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, of, switchMap, withLatestFrom } from "rxjs";
import { selectTeacherData, selectUserData } from "src/app/core/stores";
import { ClassroomInterface } from "src/app/shared/interfaces";
import { appEventActions } from "src/app/shared/store";
import { ClassroomService } from "../../services/classroom/classroom.service";
import { classroomActions } from "./classroom.action";
import { selectClassroomIdsData } from "./classroom.reducer";

@Injectable()
export class ClassroomEffects {
    constructor(
        private actions$: Actions,
        private classroomService: ClassroomService,
        private store: Store,
    ) {}

    saveClassroomInfoData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(classroomActions.saveClassroomInfo),
            switchMap((data) =>
                this.classroomService.saveClassroomData(data.data).pipe(
                    switchMap((res) => {
                        return of(
                            classroomActions.saveClassroomIdsData({
                                data: {
                                    ...res,
                                    classroom: res?.division?.id || "",
                                    currentClassroomStep: 2,
                                },
                            }),
                            classroomActions.nextClassroomStep(),
                            appEventActions.addToast({
                                detail: "Classroom info saved successfully",
                                severity: "success",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    saveClassroomTeacherData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(classroomActions.saveClassroomTeachersInfo),
            switchMap((data) =>
                this.classroomService.saveClassroomData(data.data).pipe(
                    switchMap((res) => {
                        return of(
                            classroomActions.saveClassroomIdsData({
                                data: {
                                    ...res,
                                    classroom: res?.division?.id || "",
                                    currentClassroomStep: 3,
                                },
                            }),
                            classroomActions.nextClassroomStep(),

                            appEventActions.addToast({
                                detail: "Subjects and Teachers tagged successfully",
                                severity: "success",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    saveClassroomStudentData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(classroomActions.saveClassroomStudentsInfo),
            switchMap((data) =>
                this.classroomService.saveClassroomData(data.data).pipe(
                    switchMap((res) => {
                        return of(
                            classroomActions.clearClassroomState(),
                            appEventActions.addToast({
                                detail: "Students Assigned successfully",
                                severity: "success",
                            }),
                            appEventActions.redirect({
                                navigate: ["/admin/classroom/all"],
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        ),
    );

    getStepData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(classroomActions.getClassroomData),
            withLatestFrom(this.store.select(selectClassroomIdsData)),
            switchMap(([data, classroomData]) => {
                return data?.id || classroomData?.classroom
                    ? this.classroomService
                          .getStepDetails<ClassroomInterface>(data.id ? data.id : (classroomData?.classroom as string))
                          .pipe(
                              exhaustMap((response) => {
                                  return of(
                                      !response
                                          ? appEventActions.emptyAction()
                                          : classroomActions.setClassroomData({
                                                data: response,
                                            }),
                                  );
                              }),
                              catchError((err) => {
                                  return of(appEventActions.handleError(err));
                              }),
                          )
                    : of(appEventActions.emptyAction());
            }),
        ),
    );

    getTeachersClassrooms$ = createEffect(() =>
        this.actions$.pipe(
            ofType(classroomActions.getTeachersClassrooms),
            withLatestFrom(this.store.select(selectTeacherData)),
            switchMap(([data, teacherData]) =>
                teacherData
                    ? this.classroomService
                          .getTeachersClassrooms(teacherData.id, data?.classTeacherOnly)
                          .pipe(switchMap((resp) => of(classroomActions.setClassrooms({ data: resp }))))
                    : of(appEventActions.emptyAction()),
            ),
        ),
    );

    getStudentsClassrooms$ = createEffect(() =>
        this.actions$.pipe(
            ofType(classroomActions.getStudentsClassrooms),
            withLatestFrom(this.store.select(selectUserData)),
            switchMap(([_, userData]) =>
                userData
                    ? this.classroomService
                          .getStudentsClassrooms(userData.student_id)
                          .pipe(switchMap((resp) => of(classroomActions.setClassrooms({ data: resp }))))
                    : of(appEventActions.emptyAction()),
            ),
        ),
    );
}
