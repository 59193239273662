import { Injectable } from '@angular/core';
import { SaveAdmsisionService } from './save-admission.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AdmissionService extends SaveAdmsisionService {
  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
  }
}
