<div
  class="flex align-items-center gap-8 checkbox-container flex-wrap cursor-pointer h-full"
  [class.flex-col]="rows"
  [class.invalid]="isError()"
  [class.h-[calc(100%_-_18px)]]="isError()"
>
  <div *ngFor="let option of options; let index = index">
    <p-checkbox
      [name]="controlName"
      [binary]="binary"
      [value]="option.value"
      [inputId]="option.label + index"
      [formControl]="control"
      [label]="option.label"
    ></p-checkbox>
  </div>
  <sms-label [required]="isRequired">{{ label }}</sms-label>
</div>
<sms-display-validation-error
  [control]="control"
  [label]="label"
  *ngIf="isError()"
></sms-display-validation-error>
