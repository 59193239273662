import { CommonModule } from "@angular/common";
import { Component, Input, forwardRef } from "@angular/core";
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from "@angular/forms";
import { NgIconComponent } from "@ng-icons/core";
import { InputTextModule } from "primeng/inputtext";
import { FormInputContorlDirective } from "src/app/core/directives";
import { DisplayValidationErrorComponent } from "../../display-validation-error/display-validation-error.component";
import { HelpTextComponent } from "../../help-text/help-text.component";
import { LabelComponent } from "../../label/label.component";

@Component({
    selector: "sms-password-input",
    standalone: true,
    imports: [
        CommonModule,
        InputTextModule,
        NgIconComponent,
        ReactiveFormsModule,
        DisplayValidationErrorComponent,
        LabelComponent,
        HelpTextComponent,
        FormsModule,
    ],
    templateUrl: "./password-input.component.html",
    styleUrls: ["./password-input.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => PasswordInputComponent),
            multi: true,
        },
    ],
})
export class PasswordInputComponent<T> extends FormInputContorlDirective<T> {
    @Input() label?: string = "";
    @Input() value?: string;
    @Input() min?: string;
    @Input() max?: string;
    @Input() placeholder?: string = "";
    @Input() disabled: boolean = false;
    @Input() strongValidation: boolean = false;
    @Input() helpText!: string;
    passwordVisible = false;

    switchVisibility() {
        this.passwordVisible = !this.passwordVisible;
    }
}
