import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GetAdminAdmissionService } from './get-admin-admission.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AdminAdmissionService extends GetAdminAdmissionService {
  constructor(protected override httpClient: HttpClient) {
    super(httpClient);
  }

  exportAdmissionsExcel(status?: string) {
    return this.httpClient.get<{ file: string }>(
      `${environment.API_URL}${
        status
          ? `/admin/admission/excel/${status}/list/`
          : '/admin/approved-admission/excel/list/'
      }`
    );
  }

  exportEnquiriesExcel() {
    return this.httpClient.get<{ file: string }>(
      `${environment.API_URL}${'/enquiries/excel/list/'}`
    );
  }
 
  exportIdCardExcel() {
    return this.httpClient.get<{ file: string }>(
      `${environment.API_URL}${'/student-id-card/excel/'}`
    );
  }
}
