import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { subjectActions } from './subject.action';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { appEventActions } from '../../app-event';
import { SubjectService } from 'src/app/shared/services/settings/subject';

@Injectable()
export class SubjectEffects {
  constructor(
    private actions$: Actions,
    private subjectService: SubjectService
  ) {}

  getSubjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(subjectActions.loadSubjects),
      mergeMap(() =>
        this.subjectService
          .getAllSubjects()
          .pipe(map((data) => subjectActions.setSubjects({ data })))
      )
    )
  );

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(subjectActions.create),
      switchMap((data) =>
        this.subjectService.create(data.data).pipe(
          switchMap(() => {
            data.onClose(true);

            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Subject created successfully.',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(subjectActions.update),
      switchMap((data) =>
        this.subjectService.update(data.data, data.id).pipe(
          switchMap(() => {
            data.onClose(true);
            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Subject updated successfully.',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    );
  });
}
