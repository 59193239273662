import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, concat, concatMap, of, switchMap } from 'rxjs';
import { academicYearActions } from './academic-year.action';
import { AcademicYearService } from 'src/app/shared/services/settings/academic-year';
import { appEventActions } from '../../app-event';

@Injectable()
export class AcademicYearEffects {
  constructor(
    private actions$: Actions,
    private academicYearService: AcademicYearService
  ) {}

  getAcademicYears$ = createEffect(() =>
    this.actions$.pipe(
      ofType(academicYearActions.getAcademicYears),
      concatMap(() =>
        concat(
          this.academicYearService
            .getAllAcademicYears()
            .pipe(
              switchMap((data) =>
                of(academicYearActions.setAcademicYear({ data }))
              )
            )
        )
      )
    )
  );

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(academicYearActions.create),
      switchMap((data) =>
        this.academicYearService.create(data.data).pipe(
          switchMap(() => {
            data.onClose(true);
            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Academic Year created successfully.',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(academicYearActions.update),
      switchMap((data) =>
        this.academicYearService.update(data.data, data.id).pipe(
          switchMap(() => {
            data.onClose(true);
            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Academic Year updated successfully.',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    );
  });
}
