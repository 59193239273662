import { CommonModule, formatDate } from "@angular/common";
import { Component, Inject, Input, LOCALE_ID, OnChanges, SimpleChanges, type OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { ButtonDirective } from "src/app/core/directives";
import { BACKEND_DATE_TIME_FORMAT } from "src/app/shared/constants";
import { HomeworkTypeEnum } from "src/app/shared/enum";
import { toFormData } from "src/app/shared/helpers";
import { ClassSubjectInterface, HomeworkInterface } from "src/app/shared/interfaces";
import { homeworkActions, lovsActions, selectData, selectIsMutationLoading } from "src/app/shared/store";
import {
    AutocompleteComponent,
    DateTimeComponent,
    FileInputComponent,
    RichTextEditorComponent,
    TextareaComponent,
} from "../../ui/inputs";

@Component({
    selector: "sms-homework-form",
    standalone: true,
    imports: [
        CommonModule,
        AutocompleteComponent,
        DateTimeComponent,
        TextareaComponent,
        FileInputComponent,
        ButtonDirective,
        ReactiveFormsModule,
        RouterLink,
        RichTextEditorComponent,
    ],
    templateUrl: "./homework-form.component.html",
    styleUrls: ["./homework-form.component.scss"],
})
export class HomeworkFormComponent implements OnInit, OnChanges {
    @Input() initialData?: HomeworkInterface;
    formGroup: FormGroup;
    today = new Date();
    classroomSubjectsDataKey = "/app/class-subjects/";
    classroomSubjectsData: ClassSubjectInterface[] = [];
    homeworkTypes = Object.values(HomeworkTypeEnum);
    isLoading$ = this.store.select(selectIsMutationLoading);

    subscription = new Subscription();

    constructor(
        private formBuilder: FormBuilder,
        public route: ActivatedRoute,
        private store: Store,
        @Inject(LOCALE_ID) private localeId: string,
    ) {
        this.formGroup = this.formBuilder.group({
            class_subject: new FormControl("", Validators.required),
            type: new FormControl(HomeworkTypeEnum.RESOURCE, Validators.required),
            title: new FormControl("", Validators.required),
            description: new FormControl("", Validators.required),
            submission_date: new FormControl(""),
            attachments: new FormControl(""),
        });
        this.formGroup.get("type")?.valueChanges.subscribe((value) => {
            if (value === HomeworkTypeEnum.ASSIGNMENT) {
                this.formGroup.get("submission_date")?.addValidators(Validators.required);
            } else {
                this.formGroup.get("submission_date")?.removeValidators(Validators.required);
            }
        });
    }

    ngOnInit(): void {
        if (this.route.snapshot.queryParams["classroomRef"]) {
            this.getLovsData(this.route.snapshot.queryParams["classroomRef"]);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["initialData"].currentValue) {
            if (this.formGroup)
                this.formGroup.patchValue({
                    ...changes["initialData"].currentValue,
                    submission_date: changes["initialData"].currentValue.submission_date
                        ? new Date(changes["initialData"].currentValue.submission_date)
                        : null,
                });
            this.getLovsData(changes["initialData"].currentValue.class_subject.division);
        }
    }

    getLovsData(id: string) {
        this.classroomSubjectsDataKey = `/app/class-subjects/${id}`;
        this.subscription.add(
            this.store.select(selectData).subscribe((data) => {
                let values = data[this.classroomSubjectsDataKey];
                if (!values) {
                    this.store.dispatch(lovsActions.getLovData({ data: { url: this.classroomSubjectsDataKey } }));
                }

                this.classroomSubjectsData = values || [];
            }),
        );
    }

    onSubmit() {
        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            let data = {
                ...this.formGroup.getRawValue(),
                class_subject: this.formGroup.getRawValue().class_subject.id,
                submission_date: this.formGroup.getRawValue().submission_date
                    ? formatDate(this.formGroup.getRawValue().submission_date, BACKEND_DATE_TIME_FORMAT, this.localeId)
                    : null,
            };
            if (this.initialData) {
                this.store.dispatch(
                    homeworkActions.update({
                        data: toFormData(data, ["attachments"]),
                        id: this.initialData.id,
                    }),
                );
            } else {
                this.store.dispatch(
                    homeworkActions.create({
                        data: toFormData(data, ["attachments"]),
                    }),
                );
            }
        }
    }
}
