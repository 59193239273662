import { CommonModule, Location } from "@angular/common";
import { Component, Input } from "@angular/core";
import { RouterLink } from "@angular/router";
import { Store } from "@ngrx/store";
import { QuillModule } from "ngx-quill";
import { ButtonDirective } from "src/app/core/directives";
import { AnnouncementReciverInterface } from "src/app/shared/interfaces";
import { announcementActions } from "src/app/shared/store";
import { DocumentViewerComponent, MobileHeaderComponent } from "../../ui";
import { AnnouncementHeadingComponent } from "../announcement-heading/announcement-heading.component";

@Component({
    selector: "sms-announcement-detail",
    standalone: true,
    imports: [
        CommonModule,
        MobileHeaderComponent,
        AnnouncementHeadingComponent,
        ButtonDirective,
        RouterLink,
        QuillModule,
        DocumentViewerComponent,
    ],
    templateUrl: "./announcement-detail.component.html",
    styleUrls: ["./announcement-detail.component.scss"],
})
export class AnnouncementDetailComponent {
    @Input() announcement: AnnouncementReciverInterface | null = null;
    @Input() isMobile: boolean = false;
    @Input() isTeacher: boolean = false;

    constructor(
        private store: Store,
        private location: Location,
    ) {}

    markAsRead(id: string) {
        this.store.dispatch(announcementActions.markAsRead({ id, isMobile: this.isMobile }));
    }

    clearAnnouncement() {
        if (this.isTeacher) {
            this.location.back();
        } else {
            this.store.dispatch(announcementActions.setOneAnnouncement({ data: null }));
        }
    }
}
