import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { selectIsAdminLoggedIn } from '../stores';

export const AdminLoginGuard: CanActivateFn = (route, state) => {
  const store = inject(Store);
  const router = inject(Router);
  return store.select(selectIsAdminLoggedIn).pipe(
    map((value) => {
      if (value) {
        router.navigate(['/admin']);
        return false;
      } else {
        return true;
      }
    })
  );
};
