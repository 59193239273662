import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";

@Component({
    selector: "sms-search-input",
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule],
    templateUrl: "./search-input.component.html",
    styleUrls: ["./search-input.component.scss"],
})
export class SearchInputComponent {
    @Input() control: FormControl;
}
