import { createActionGroup, emptyProps, props } from "@ngrx/store";

import { IDCardInterface } from "src/app/features/private/portals/student/interfaces";
import {
    AdmissionFeesInterface,
    AdmissionInterface,
    AgreeDeclarationInterface,
    DownloadInstalmentPDF,
    FindAdmissionInterface,
    PayInstalmentInterface,
    PayOtherChargesInterface,
    PostAdmissionStepResponse,
    PostChildrenInfoInterface,
    PostParentsInfoInterface,
    PostSupplementryInfoInterface,
    onClose,
} from "src/app/shared/interfaces";

export const admissionActions = createActionGroup({
    source: "admission",
    events: {
        "set current step": props<{ stepNumber: number }>(),
        "next step": emptyProps(),
        "previous step": emptyProps(),
        "clear state": emptyProps(),
        "save admission ids data": props<{
            data: PostAdmissionStepResponse | null;
        }>(),
        "save children info": props<{
            data: PostChildrenInfoInterface;
        }>(),
        "save parents info": props<{ data: PostParentsInfoInterface }>(),
        "save supplementry info": props<{ data: PostSupplementryInfoInterface }>(),
        "save documents attached": props<{ data: FormData; documentsOnly?: boolean }>(),
        "agree declaration form": props<{ data: AgreeDeclarationInterface }>(),
        "payment completed": props<{ data: AgreeDeclarationInterface }>(),
        "get admission data": props<{ stepNumber: number }>(),
        "set admission data": props<{
            stepNumber: number;
            data: AdmissionInterface;
        }>(),
        "find partial admissions": props<{ data: FindAdmissionInterface }>(),
        "set partial admissions": props<{ data: AdmissionInterface[] }>(),
        "set partial admission form": props<{ data: AdmissionInterface }>(),
        "find interview scheduled admissions": props<{
            data: FindAdmissionInterface;
        }>(),
        "set interview scheduled admissions": props<{
            data: AdmissionInterface[];
        }>(),
        "download admission form": props<{ form_number: string }>(),
        "find partial admission by admission id": props<{ admissionId: string }>(),
        "set partial admission data": props<{ data: AdmissionInterface }>(),
        "get admission fees data": props<{ data?: { admissionId: string } }>(),
        "set admission fees data": props<{ data: AdmissionFeesInterface }>(),
        "pay instalment": props<{
            onClose?: onClose;
            data: PayInstalmentInterface;
        }>(),
        "pay other charges": props<{
            data: PayOtherChargesInterface;
            onClose?: onClose;
        }>(),
        "download instalment recipt": props<{
            data: DownloadInstalmentPDF;
        }>(),
        "get id card data": props<{
            student_id: string;
        }>(),
        "set id card data": props<{
            data: IDCardInterface;
        }>(),
    },
});
