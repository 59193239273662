import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { datagridActions } from './datagrid.action';
import { mergeMap, switchMap, of } from 'rxjs';
import { DatagridService } from '../../services';

@Injectable()
export class DatagridEffects {
  constructor(
    private actions$: Actions,
    private datagridService: DatagridService
  ) {}

  getDatagridData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(datagridActions.getTableData),
      mergeMap(({ url, params }) =>
        this.datagridService.getAllDatagridData(url, params).pipe(
          switchMap((data) =>
            of(
              datagridActions.setNewTableData({
                data,
                key: { url, params },
              })
            )
          )
        )
      )
    )
  );
}
