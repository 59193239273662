import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class PushNotificationAPIService {
    constructor(private httpClient: HttpClient) {}

    updateToken(data: { token: string }): Observable<{ data: any }> {
        return this.httpClient.post<{ data: any }>(environment.API_URL + `/app/notifications/update-token/`, data);
    }
}
