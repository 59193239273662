import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { map, withLatestFrom } from "rxjs";
import { selectIsLoggedIn, selectUserData, selectUserType } from "../stores";

export const LoginGuard: CanActivateFn = (route, state) => {
    const store = inject(Store);
    const router = inject(Router);

    return store.select(selectIsLoggedIn).pipe(
        withLatestFrom(store.select(selectUserType), store.select(selectUserData)),
        map(([value, userType, studentData]) => {
            if (value) {
                if (userType === "Teacher") {
                    router.navigate(["/teacher"]);
                } else {
                    if (studentData && !route.queryParams["add_more"]) {
                        router.navigate(["/student/home"]);
                        return false;
                    }
                    return true;
                }
                return false;
            } else {
                return true;
            }
        }),
    );
};
