import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { DialogModule } from "primeng/dialog";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ButtonDirective } from "src/app/core/directives";
import { HeadingComponent } from "../../heading/heading.component";

@Component({
    selector: "sms-confirm-dialog",
    standalone: true,
    imports: [CommonModule, HeadingComponent, DialogModule, ButtonDirective],
    templateUrl: "./confirm-dialog.component.html",
    styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent {
    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
    ) {}

    onConfirm() {
        this.ref.close(true);
    }

    onClose() {
        this.ref.close(false);
    }
}
