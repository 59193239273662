import { createFeature, createReducer, on } from '@ngrx/store';
import { EnquiryStoreInterface } from '../interface';
import { enquiryActions } from './enquiry.action';

const initialState: EnquiryStoreInterface = {};

const enquiryFeature = createFeature({
  name: 'enquiry',
  reducer: createReducer(
    initialState,
    on(enquiryActions.create, (state) => ({ ...state }))
  ),
});

export const { name: enquiryFeatureKey, reducer: enquiryReducer } =
  enquiryFeature;
