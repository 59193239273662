import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, SimpleChanges, TemplateRef, type OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { appEventActions, lovsActions, selectData } from "../../../store";
import { NoDataComponent } from "../../ui";

@Component({
    selector: "sms-list-with-pagination",
    standalone: true,
    imports: [CommonModule, NoDataComponent],
    templateUrl: "./list-with-pagination.component.html",
    styleUrls: ["./list-with-pagination.component.scss"],
})
export class ListWithPaginationComponent implements OnInit, OnDestroy {
    @Input() bodyRef?: TemplateRef<any>;
    @Input() headerRef?: TemplateRef<any>;
    @Input() key: string;

    pg = 0;
    limit = 20;

    data: { rows: any[]; count: 0 };

    subscription = new Subscription();

    constructor(private store: Store) {}

    ngOnInit(): void {
        this.subscription.add(
            this.store.select(selectData).subscribe((values) => {
                if (values[this.key]) {
                    this.data = values[this.key];
                }
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["key"].currentValue) {
            this.resetQueryParams();
            this.store.dispatch(appEventActions.startFullPageLoading());
            this.getData({ url: changes["key"].currentValue, queryParams: this.queryParams });
        }
    }

    getData({ url, queryParams }: { url: string; queryParams?: any }) {
        this.store.dispatch(appEventActions.startFullPageLoading());
        this.store.dispatch(lovsActions.getLovData({ data: { url: url, queryParams: queryParams } }));
    }

    resetQueryParams() {
        this.pg = 0;
        this.limit = 20;
    }

    get queryParams() {
        return {
            pg: this.pg,
            limit: this.limit,
        };
    }

    get hasMore() {
        return Math.ceil(this.data.count / this.limit) - 1 === this.pg;
    }

    onNext() {
        this.pg = this.pg + 1;
        this.getData({ url: this.key, queryParams: this.queryParams });
    }

    onPrev() {
        this.pg = this.pg - 1;
        this.getData({ url: this.key, queryParams: this.queryParams });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
