import { createActionGroup, props } from '@ngrx/store';
import { CreateUserInterface, onClose } from 'src/app/shared/interfaces';

export const userActions = createActionGroup({
  source: 'user',
  events: {
    create: props<{
      data: CreateUserInterface;
      onClose: onClose;
    }>(),
    update: props<{
      data: CreateUserInterface;
      id: string;
      onClose: onClose;
    }>(),
  },
});
