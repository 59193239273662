<form [formGroup]="formGroup" class="w-full" (ngSubmit)="onSave()">
  <div class="gap-5 grid sm:grid-cols-1 w-full">
    <sms-text-input
      formControlName="previous_school"
      label="Previous School"
      smsUppercase
    ></sms-text-input>
    <sms-text-input
      formControlName="previous_class"
      label="Previous Class Attended"
      smsUppercase
    ></sms-text-input>
    <sms-textarea
      formControlName="reason_of_leaving"
      label="Reason of Leaving"
      smsUppercase
    ></sms-textarea>
    <sms-textarea
      formControlName="history_of_disease"
      label="History of Disease"
      smsUppercase
    ></sms-textarea>
    <sms-textarea
      formControlName="allergy_details"
      label="Any Allergy"
      smsUppercase
    ></sms-textarea>
  </div>
  <div class="flex flex-col gap-4 items-start self-stretch my-4">
    <button
      smsButton
      color="primary"
      type="submit"
      [loading]="isLoading$ | async"
    >
      {{ !preview ? "Save & Next" : "Next" }}
    </button>
    <button
      smsButton
      color="primary"
      variant="outlined"
      type="button"
      (click)="onBack()"
    >
      Back
    </button>
  </div>
</form>
