import { Directive, HostBinding } from "@angular/core";

@Directive({
    selector: "[smsIconButton]",
    standalone: true,
})
export class IconButtonDirective {
    @HostBinding("class")
    buttonClassess: string =
        "text-xl text-primary cursor-pointer hover:bg-gray-200 rounded-full p-1 w-10 h-10 flex justify-center items-center";

    constructor() {}
}
