<form class="w-full" [formGroup]="formGroup" (ngSubmit)="onSave()">
    <p-accordion expandIcon="ph ph-caret-double-left" collapseIcon="ph ph-caret-double-up">
        <sms-accordion-tab [selected]="true" header="KINDLY NOTE">
            <quill-view-html *ngIf="contentData" [content]="contentData" theme="snow"></quill-view-html>

            <!-- <ul class="px-5 my-0">
        <li class="text-sm font-satoshi text-gray-800 mb-2">
          Each file or image must be less than or equal to 150 KB.
        </li>
        <li class="text-sm font-satoshi text-gray-800 mb-2">
          Below is the list of apps which can help in compressing images.

          <ul class="mt-2">
            <li class="text-sm font-satoshi text-gray-800 mb-2">
              <a
                href="https://play.google.com/store/apps/details?id=com.mobso.photoreducer.lite"
                target="_blank"
                class="underline"
                >Compress image size in kb & mb.</a
              >
            </li>
            <li class="text-sm font-satoshi text-gray-800 mb-2">
              <a
                href="https://play.google.com/store/apps/details?id=aculix.bulk.image.compressor"
                target="_blank"
                class="underline"
                >Image Compressor - MB to kB.</a
              >
            </li>
          </ul>
        </li>
      </ul> -->
        </sms-accordion-tab>
    </p-accordion>

    <div class="mt-5 gap-5 grid sm:grid-cols-1 w-full">
        <sms-file-input formControlName="medical_certificate" label="Medical Certificate"></sms-file-input>
        <sms-file-input
            formControlName="leaving_certificate"
            label="Leaving/Transfer Certificate Of Previous School"
        ></sms-file-input>
        <sms-file-input
            formControlName="student_photo"
            label="Student Photos"
            accept="image/*"
            helpText="Passport Size Photographs With Red
      Background(Student)"
        ></sms-file-input>
        <sms-file-input accept="image/*" formControlName="mother_photo" label="Mother's Photo"></sms-file-input>
        <sms-file-input accept="image/*" formControlName="father_photo" label="Father's Photo"></sms-file-input>
        <!-- <sms-file-input
      accept="image/*"
      formControlName="family_photo"
      label="Family Photo"
    ></sms-file-input> -->
        <sms-file-input formControlName="caste_certificate" label="Caste Certificate, If Any"></sms-file-input>

        <sms-file-input
            formControlName="birth_certificate"
            label="Birth Certificate
	  (Original Or True Copies)"
        ></sms-file-input>
        <sms-file-input formControlName="previous_report_card" label="Report Card Of Previous School"></sms-file-input>
        <sms-file-input
            formControlName="udise_of_preschool"
            label="Udise ID Of Previous School/Student"
        ></sms-file-input>
        <sms-file-input formControlName="aadhaar_image" label="Zerox Copy Of Aadhar Card"></sms-file-input>
    </div>
    <div class="flex flex-col gap-4 items-start self-stretch my-4">
        <button *ngIf="!preview" [loading]="isLoading$ | async" smsButton color="primary" type="submit">
            Save & Next
        </button>
        <button (click)="onBack()" smsButton color="primary" variant="outlined" type="button">Back</button>
    </div>
</form>
