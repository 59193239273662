import { CommonModule } from "@angular/common";
import { Component, type OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { ButtonDirective } from "src/app/core/directives";
import { toFormData } from "src/app/shared/helpers";
import { homeworkActions, selectIsMutationLoading } from "src/app/shared/store";
import { DividerComponent, FileInputComponent, TextareaComponent } from "../../ui";

@Component({
    selector: "sms-submit-assignment",
    standalone: true,
    imports: [
        CommonModule,
        FileInputComponent,
        ButtonDirective,
        DividerComponent,
        TextareaComponent,
        ReactiveFormsModule,
    ],
    templateUrl: "./submit-assignment.component.html",
    styleUrls: ["./submit-assignment.component.scss"],
})
export class SubmitAssignmentComponent implements OnInit {
    formGroup: FormGroup;
    isLoading$ = this.store.select(selectIsMutationLoading);

    constructor(
        private formBuilder: FormBuilder,
        private ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.formGroup = this.formBuilder.group({
            assignments: new FormControl([], Validators.required),
            comments: new FormControl(""),
        });

        if (this.config.data.myAssignment) {
            this.formGroup.patchValue({ ...this.config.data.myAssignment });
            this.formGroup.disable();
        }
    }

    onSubmit() {
        this.formGroup.markAllAsTouched();
        if (this.formGroup.valid) {
            let data = {
                ...this.formGroup.getRawValue(),
                homework: this.config.data.homework,
            };
            this.store.dispatch(
                homeworkActions.submitAssignment({
                    data: toFormData(data, ["assignments"]),
                    onClose: this.onClose,
                }),
            );
        }
    }

    onClose = () => {
        this.ref.close(false);
    };
}
