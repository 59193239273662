import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';
import { schoolConfig } from 'src/app/core/config';

@Directive({
  selector: '[smsUppercase]',
  standalone: true,
})
export class UppercaseDirective {
  constructor(private control: NgControl) {}
  @HostListener('input', ['$event']) onInput(event: KeyboardEvent) {
    if (schoolConfig.config.FORM_IN_BLOCK_LETTERS) {
      const input = event.target as HTMLInputElement;
      input.value = input.value.toUpperCase();
      this.control.control?.patchValue(input.value.toUpperCase());
    }
  }
}
