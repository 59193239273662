export enum AdmissionStatusEnum {
  IN_PROGRESS = 'In Progress',
  NEW = 'New',
  APPROVED_FOR_INTERVIEW = 'Approved for Interview',
  IN_REVIEW = 'In Review',
  SELECTED = 'Selected',
  REJECTED = 'Rejected',
}

export enum PaymentStatusEnum {
  PENDING = 'Pending',
  PAID = 'Paid',
}
