<ng-container *ngIf="control.disabled; else elseTemplate">
  <div class="flex flex-col gap-1">
    <sms-label>{{ label }}</sms-label>

    <p class="text-base font-satoshi text-gray-900 px-1">
      {{
        (control.value === true
          ? "Yes"
          : control.value === false
          ? "No"
          : control.value) || "-"
      }}
    </p>
  </div>
</ng-container>
<ng-template #elseTemplate>
  <div
    [class]="
      'flex align-items-center gap-8 checkbox-container flex-col sm:flex-row' +
      containerClass
    "
    [class.invalid]="isError()"
    [class.!flex-col]="!!rows"
  >
    <div *ngFor="let option of options">
      <p-radioButton
        name="{{ controlName }}"
        [value]="option.value"
        [formControl]="control"
        inputId="{{ option.label }}"
        label="{{ option.label }}"
      ></p-radioButton>
    </div>
    <sms-label *ngIf="label" [required]="isRequired">{{ label }}</sms-label>
  </div>
  <sms-help-text *ngIf="!isError() && helpText">{{ helpText }}</sms-help-text>
  <sms-display-validation-error
    [control]="control"
    [label]="label"
    *ngIf="isError()"
  ></sms-display-validation-error>
</ng-template>
