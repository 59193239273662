import { createFeature, createReducer, on } from '@ngrx/store';
import { feesActions } from './fees.action';
import { FeesStoreInterface } from 'src/app/shared/interfaces';

const initailState: FeesStoreInterface = {
  admissionFees: null,
};

const feesFeature = createFeature({
  name: 'fees',
  reducer: createReducer(
    initailState,
    on(feesActions.setAdmissionFees, (state, action) => ({
      ...state,
      admissionFees: action.data,
    })),
    on(feesActions.create, (state) => ({ ...state })),
    on(feesActions.update, (state) => ({ ...state }))
  ),
});

export const {
  name: feesFeatureKey,
  reducer: feesReducer,
  selectAdmissionFees,
} = feesFeature;
