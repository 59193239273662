<p-toast [life]="4000" [breakpoints]="{ '500px': { width: '90%' } }" position="top-center">
    <ng-template let-message pTemplate="message">
        <div class="flex justify-start items-start gap-4 rounded-lg w-[300px] shadow-xs" (click)="navigate(message)">
            <div
                class="flex justify-center items-center gap-2/5 p-2 bg-gray-100 rounded-m"
                [class.!bg-success-light]="message.severity === 'success'"
                [class.!bg-error-light]="message.severity === 'error'"
                [class.!bg-warning-light]="message.severity === 'warn'"
                [class.!bg-info-light]="message.severity === 'info'"
            >
                <i
                    class="ph-duotone {{ iconsObject[message.severity] }} text-gray-900 text-2xl"
                    [class.!text-success]="message.severity === 'success'"
                    [class.!text-error]="message.severity === 'error'"
                    [class.!text-warning]="message.severity === 'warn'"
                    [class.!text-info]="message.severity === 'info'"
                ></i>
            </div>
            <div class="flex flex-col">
                <h3
                    class="font-satoshi text-gray-900 text-base sm:text-lg font-bold"
                    [class.!text-success]="message.severity === 'success'"
                    [class.!text-error]="message.severity === 'error'"
                    [class.!text-warning]="message.severity === 'warn'"
                    [class.!text-info]="message.severity === 'info'"
                >
                    {{ message.summary ? message.summary : summaryObject[message.severity] }}
                </h3>
                <p class="font-satoshi text-sm sm:text-base text-gray-900 break-words">
                    {{ message.detail }}
                </p>
            </div>
        </div>
    </ng-template>
</p-toast>
<ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async } as authVal">
    <button
        class="fixed shadow-2xl hover:bg-gray-300 right-5 cursor-pointer bg-gray-200 z-[1000000]"
        *ngIf="showInstallButton; else elseTemplate"
        [ngClass]="{
            'bottom-28 min-[960px]:bottom-5': authVal.isLoggedIn?.isUser,
            'bottom-5': !authVal.isLoggedIn || authVal.isLoggedIn.isAdmin || !authVal.isLoggedIn.isAdmin,
        }"
        (click)="installPWA()"
        style="box-shadow: 5px 10px 30px #00000050"
        smsIconButton
    >
        <i class="ph-bold ph-download-simple text-gray-700"></i>
    </button>
</ng-container>

<ng-template #elseTemplate>
    <sms-pwa-instructions></sms-pwa-instructions>
</ng-template>
