import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { ButtonDirective } from 'src/app/core/directives';

@Component({
  selector: 'sms-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss'],
  standalone: true,
  imports: [CommonModule, ButtonDirective, RouterLink],
})
export class PaymentSuccessComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
