import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, Output, TemplateRef, type OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { MenuItem } from "primeng/api";
import { SkeletonModule } from "primeng/skeleton";
import { TabMenuModule } from "primeng/tabmenu";
import { Subscription } from "rxjs";
import { classroomActions } from "src/app/shared/store/classroom/classroom.action";
import { selectClassrooms } from "src/app/shared/store/classroom/classroom.reducer";
import { NoDataComponent } from "../../ui";

@Component({
    selector: "sms-classroom-tabs",
    standalone: true,
    imports: [CommonModule, TabMenuModule, SkeletonModule, NoDataComponent],
    templateUrl: "./classroom-tabs.component.html",
    styleUrls: ["./classroom-tabs.component.scss"],
})
export class ClassroomTabsComponent implements OnInit, OnDestroy {
    isLoading: boolean = false;
    @Input() isTeacher: boolean = true;
    @Input() classTeacherOnly: boolean = false;
    @Input() tabsBody: TemplateRef<any>;
    @Output() onTabChange: EventEmitter<MenuItem> = new EventEmitter();

    activeItem: MenuItem | undefined;
    items: MenuItem[] = [];
    subscription = new Subscription();

    constructor(
        private store: Store,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.isLoading = true;
        if (this.isTeacher) {
            this.store.dispatch(
                classroomActions.getTeachersClassrooms({
                    classTeacherOnly: this.classTeacherOnly,
                }),
            );
        } else {
            this.store.dispatch(classroomActions.getStudentsClassrooms());
        }

        this.route.queryParams.subscribe((values) => {
            if (values && values["index"]) {
                this.activeItem = this.items[+values["index"]];
            }
        });
        this.subscription.add(
            this.store.select(selectClassrooms).subscribe((values) => {
                this.activeItem = undefined;
                if (values) {
                    this.items = values.map((item, index) => {
                        let menuItem: MenuItem = {
                            label: item.name,
                            id: item.id,
                            tabindex: index.toString(),
                            // icon: "ph-duotone ph-chalkboard-teacher",
                            state: {
                                ...item,
                            },
                        };
                        if (this.route.snapshot.queryParams["classroomRef"] === item.id) {
                            this.activeItem = menuItem;
                        }
                        return menuItem;
                    });
                    if (!this.activeItem) {
                        this.activeItem = this.items[0];
                        this.onTabChange.emit(this.activeItem);
                    }
                    this.isLoading = false;
                }
            }),
        );
    }

    onActiveItemChange(event: MenuItem) {
        if (this.onTabChange) {
            this.onTabChange.emit(event);
        }
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                classroomRef: event.id,
                classroom: event.label,
                index: event.tabindex,
            },
            queryParamsHandling: "merge",
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
