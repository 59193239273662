import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "src/environments/environment";

@Pipe({
    name: "env",
    standalone: true,
})
export class EnvPipe implements PipeTransform {
    transform(value: keyof typeof environment): unknown {
        return environment[value];
    }
}
