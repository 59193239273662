<svg
  width="50"
  height="50"
  viewBox="0 0 50 50"
  class="circular-progress"
  style="--progress: {{ percentage }}"
>
  <circle class="bg"></circle>
  <circle class="fg"></circle>
  <text
    font-size="10px"
    x="50%"
    y="50%"
    text-anchor="middle"
    stroke="var(--app-gray-400)"
    stroke-width="1px"
    dy=".3em"
  >
    {{ text }}
  </text>
</svg>
