import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import {
    AdminDataInterface,
    AdminLoginInterface,
    ForgotPasswordOTPResponseInterface,
    RequestForgotPasswordOTPInterface,
    StudentDataInterface,
    StudentLoginInterface,
    TeacherLoginInterface,
    UpdatePasswordInterface,
    VerifyOTPInterface,
} from "../../interfaces";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    constructor(private httpClient: HttpClient) {}

    studentLogin(data: StudentLoginInterface): Observable<StudentDataInterface> {
        return this.httpClient.post<StudentDataInterface>(`${environment.API_URL}/accounts/student-login/`, data);
    }

    teacherLogin(data: TeacherLoginInterface): Observable<any> {
        return this.httpClient.post<any>(`${environment.API_URL}/accounts/teacher-login/`, data);
    }

    adminLogin(data: AdminLoginInterface): Observable<AdminDataInterface> {
        return this.httpClient.post<AdminDataInterface>(`${environment.API_URL}/accounts/admin-login/`, data);
    }

    requestForgotPasswordOTP(data: RequestForgotPasswordOTPInterface): Observable<ForgotPasswordOTPResponseInterface> {
        return this.httpClient.post<ForgotPasswordOTPResponseInterface>(
            `${environment.API_URL}/accounts/forgot-password/request-otp/`,
            data,
        );
    }

    verifyOTP(data: VerifyOTPInterface): Observable<{ verified: boolean }> {
        return this.httpClient.post<{ verified: boolean }>(
            `${environment.API_URL}/accounts/forgot-password/verify-otp/`,
            data,
        );
    }

    updatePassword(data: UpdatePasswordInterface): Observable<unknown> {
        return this.httpClient.post<unknown>(`${environment.API_URL}/accounts/forgot-password/update-password/`, data);
    }
}
