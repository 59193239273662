import { CommonModule } from "@angular/common";
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    TemplateRef,
} from "@angular/core";
import { StepperStepInterface } from "src/app/shared/interfaces";
import { CircularProgressComponent } from "../circular-progress/circular-progress.component";

@Component({
    selector: "sms-stepper",
    standalone: true,
    imports: [CommonModule, CircularProgressComponent],
    templateUrl: "./stepper.component.html",
    styleUrls: ["./stepper.component.scss"],
})
export class StepperComponent implements OnChanges {
    @Input({ required: true }) stepperName!: string;
    @Input({ required: true }) steps!: StepperStepInterface[];
    @Input() currentStepNumber: number | null;
    @Input() stepperBody: TemplateRef<any>;
    @Input() disableStepClick: boolean = false;

    @Output() changeStepper: EventEmitter<number> = new EventEmitter();

    currentStep: StepperStepInterface;
    currentStepIndex = 1;

    constructor(private changeRef: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes["steps"] && changes["steps"].currentValue) {
            this.currentStep = changes["steps"].currentValue[0];
            this.changeRef.detectChanges();
        }
        if (changes["currentStepNumber"] && changes["currentStepNumber"].currentValue !== null) {
            this.currentStepIndex = changes["currentStepNumber"].currentValue - 1;
            if (this.steps[this.currentStepIndex]) {
                this.currentStep = this.steps[this.currentStepIndex];
            }
            // this.currentStepIndex = changes["currentStepNumber"].currentValue;
            // if (this.steps[this.currentStepIndex - 1]) {
            //     this.currentStep = this.steps[this.currentStepIndex - 1];
            // }
            this.changeRef.detectChanges();
        }

        // if (changes["changeStepper"] && changes["changeStepper"].currentValue) {
        //     this.changeStepper = new EventEmitter();
        // }
    }

    emmitStepperValue() {
        if (this.changeStepper) {
            if (this.currentStep) this.changeStepper.emit(this.currentStep.stepNumber);
        }
    }

    getStepNumber(number: number) {
        return number.toString().padStart(2, "0");
    }

    onCurrentStepChange(item: StepperStepInterface) {
        this.currentStep = item;
        this.currentStepIndex = item?.stepNumber - 1;
        this.changeRef.detectChanges();
        this.emmitStepperValue();
    }

    onNext = () => {
        // if (this.steps[this.currentStepIndex]) {
        //     this.currentStep = this.steps[this.currentStepIndex];
        if (this.steps[this.currentStepIndex + 1]) {
            this.currentStep = this.steps[this.currentStepIndex + 1];
            this.currentStepIndex = this.currentStepIndex + 1;
            this.changeRef.detectChanges();
            this.emmitStepperValue();
        }
    };

    onPrevious = () => {
        if (this.steps[this.currentStepIndex - 1]) {
            this.currentStep = this.steps[this.currentStepIndex - 1];
            this.currentStepIndex = this.currentStepIndex - 1;
            this.changeRef.detectChanges();
            this.emmitStepperValue();
        }
    };
}
