import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import { ContentManagementStoreInterface } from "src/app/shared/interfaces/settings/content-management";
import { contentManagementActions } from "./content-management.action";

const initailState: ContentManagementStoreInterface = {
    contentManagementData: null,
};

const contentManagementFeature = createFeature({
    name: "content-management",
    reducer: createReducer(
        initailState,
        on(contentManagementActions.setContentManagementData, (state, action) => {
            return {
                ...state,
                contentManagementData: {
                    ...state.contentManagementData,
                    [action.data.key]: action.data.data,
                },
            };
        }),
        on(contentManagementActions.update, (state) => ({ ...state })),
    ),
});

export const {
    name: contentManagementFeatureKey,
    reducer: contentManagementReducer,
    selectContentManagementData,
} = contentManagementFeature;

export const selectContentData = (props: { key: string }) =>
    createSelector(selectContentManagementData, (data) => {
        return data && data[props.key];
    });
