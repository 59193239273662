<ng-container *ngIf="isHandset$ | async; else desktopView">
    <div class="max-w-full relative h-screen mx-auto">
        <div class="bg-white p-5 sm:p-5 overflow-y-auto h-[calc(100vh_-_86px)]">
            <router-outlet></router-outlet>
        </div>
        <div
            class="absolute top-0 right-0 h-screen w-full bg-backdrop z-[1000] overflow-hidden full-page-loading"
            *ngIf="isFullPageLoading"
        >
            <p-progressSpinner
                class="absolute left-1/2 top-[50vh] translate-center"
                styleClass="w-20 h-20"
                strokeWidth="4"
                fill="transparent"
                animationDuration=".5s"
            ></p-progressSpinner>
        </div>
        <ng-container *ngIf="menuItems$ | async as menuItems">
            <div
                class="z-[1001] fixed bg-background rounded-t-2xl bottom-0 left-1/2 w-full pt-2 px-2 flex justify-between items-center gap-2 pb-5"
                style="box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.09); transform: translateX(-50%)"
            >
                <ng-container *ngFor="let item of menuItems">
                    <div
                        class="flex flex-col justify-center items-center text-gray-500 flex-1 py-1 px-1 cursor-pointer"
                        *ngIf="!item.state?.['hideOnMobile']"
                        [routerLink]="[item.routerLink]"
                        [routerLinkActiveOptions]="item.routerLinkActiveOptions ? item.routerLinkActiveOptions : {}"
                        routerLinkActive="nav-active"
                    >
                        <i [class]="'text-2xl text-inherit ' + item.icon"></i>
                        <span class="text-xs text-inherit font-bold font-satoshi text-center label">{{
                            item.label
                        }}</span>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-template #desktopView>
    <sms-admin-container>
        <router-outlet></router-outlet>
    </sms-admin-container>
</ng-template>
