import { createActionGroup, emptyProps, props } from "@ngrx/store";
import {
    AnnouncementInterface,
    AnnouncementReciverInterface,
    MyAnnouncementsInterace,
    onClose,
} from "src/app/shared/interfaces";

export const announcementActions = createActionGroup({
    source: "announcement",
    events: {
        create: props<{
            data: FormData;
            onClose: onClose;
        }>(),
        update: props<{
            data: FormData;
            id: string;
            onClose: onClose;
        }>(),
        "mark as read": props<{
            id: string;
            queryParams?: Record<string, any>;
            isMobile: boolean;
        }>(),
        "get my announcements": props<{ isMobile?: boolean }>(),
        "set my announcements": props<{
            data: MyAnnouncementsInterace;
            isMobile?: boolean;
        }>(),
        "mark current announcement": emptyProps(),
        "get my announcements count": emptyProps(),
        "set my announcements count": props<{
            data: number;
        }>(),
        "decrease announcements count": emptyProps(),
        "get one announcement": props<{ id: string }>(),
        "set one announcement": props<{
            data: AnnouncementReciverInterface | AnnouncementInterface | null;
        }>(),
    },
});
