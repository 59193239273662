<div class="w-full flex justify-center items-center my-4">
    <div class="flex justify-start items-center bg-primary-light rounded-lg p-1 w-max">
        <button
            class="bg-transparent cursor-pointer rounded-lg font-medium text-gray-300 font-satoshi text-sm text-center py-2 px-8"
            *ngFor="let item of buttons"
            [ngClass]="{
                'text-primary !bg-background !font-bold': activeButtonId === item.id,
            }"
            [ngStyle]="{
                'box-shadow': activeButtonId === item.id ? '0px 2px 7px rgba(var(--app-primary-rgb), 0.15)' : 'none',
            }"
            (click)="changeTab(item.id)"
        >
            {{ item.label }}
        </button>
    </div>
</div>
<ng-container [ngTemplateOutlet]="tabsBody" [ngTemplateOutletContext]="{ $implicit: activeButtonId }"></ng-container>
