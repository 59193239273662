import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormInputContorlDirective } from 'src/app/core/directives';
import { CheckboxModule } from 'primeng/checkbox';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { LabelComponent } from '../../label/label.component';
import { DisplayValidationErrorComponent } from '../../display-validation-error/display-validation-error.component';

@Component({
  selector: 'sms-checkbox',
  standalone: true,
  imports: [
    CommonModule,
    CheckboxModule,
    LabelComponent,
    ReactiveFormsModule,
    DisplayValidationErrorComponent,
  ],
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent<T>
  extends FormInputContorlDirective<T>
  implements OnChanges
{
  @Input() label?: string = '';
  @Input() binary: boolean = false;
  @Input() rows: boolean = false;
  @Input({ required: false }) options: { label: string; value: any }[] = [];

  override writeValue(value: T): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['binary'] && changes['binary'].currentValue) {
      this.controlName = undefined;
    }
  }
}
