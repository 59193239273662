<ng-container *ngIf="data && data.count; else elseTemplate">
    <div>
        <ng-container *ngIf="headerRef" [ngTemplateOutlet]="headerRef" [ngTemplateOutletContext]="{ $implicit: data }">
        </ng-container>
        <ng-container
            *ngFor="let item of data.rows"
            [ngTemplateOutlet]="bodyRef ? bodyRef : defaultBody"
            [ngTemplateOutletContext]="{ $implicit: item }"
        >
        </ng-container>

        <ng-template #defaultBody></ng-template>

        <div class="flex justify-center items-center mt-5">
            <button
                class="rounded-l-md font-medium font-satoshi bg-background disabled:opacity-70 disabled:bg-gray-100 disabled:cursor-default cursor-pointer text-heading px-4 py-2 border border-r-0 border-placeholder border-collapse"
                [ngClass]="pg !== 0 ? 'hover:bg-gray-100' : ''"
                [disabled]="pg === 0"
                (click)="onPrev()"
                pRipple
            >
                Prev
            </button>
            <button
                class="rounded-r-md font-medium font-satoshi bg-background disabled:opacity-70 disabled:bg-gray-100 disabled:cursor-default cursor-pointer text-heading px-4 py-2 border border-placeholder border-collapse"
                [ngClass]="!hasMore ? 'hover:bg-gray-100' : ''"
                [disabled]="hasMore"
                (click)="onNext()"
                pRipple
            >
                Next
            </button>
        </div>
    </div>
</ng-container>

<ng-template #elseTemplate>
    <div class="flex justify-center items-center h-[500px]">
        <sms-no-data></sms-no-data>
    </div>
</ng-template>
