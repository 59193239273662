import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class StudentAdmissionService {
    constructor(private httpClient: HttpClient) {}

    fillIdCardForm(data: FormData) {
        return this.httpClient.post(`${environment.API_URL}/student-id-card/create/`, data);
    }

    updateIdCardFrom(data: FormData, id: string) {
        return this.httpClient.put(`${environment.API_URL}/student-id-card/${id}/`, data);
    }
}
