import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Params } from "@angular/router";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { GetSchoolConfigItemParams, PostSchoolConfigItem } from "../../interfaces";

@Injectable({
    providedIn: "root",
})
export class SchoolConfigService {
    constructor(private httpClient: HttpClient) {}

    getSchoolConfigItem(params: GetSchoolConfigItemParams): Observable<any> {
        return this.httpClient.get<any>(environment.API_URL + "/schools/admin/school-config/", {
            params: params as Params,
        });
    }

    saveSchoolConfigItem(data: PostSchoolConfigItem): Observable<any> {
        return this.httpClient.post<any>(environment.API_URL + "/schools/admin/school-config/", data);
    }

    saveAttendanceSettings(data: PostSchoolConfigItem, id: string | null): Observable<any> {
        if (id) {
            return this.httpClient.put<any>(
                `${environment.API_URL}/schools/admin/attendance-settings-config/${id}/`,
                data,
            );
        }

        return this.httpClient.post<any>(
            `${environment.API_URL}/schools/admin/attendance-settings-config/create/`,
            data,
        );
    }

    genrateQRCode(id: string): Observable<{ qr: string }> {
        return this.httpClient.get<{ qr: string }>(environment.API_URL + "/schools/admin/generate-attendance-qr/" + id);
    }
}
