import { createActionGroup, emptyProps, props } from "@ngrx/store";

import {
    ClassroomInterface,
    PostClassroomInfoInterface,
    PostClassroomStepResponse,
    PostClassroomStudentInterface,
    PostClassroomTeacherInterface,
} from "src/app/shared/interfaces";

export const classroomActions = createActionGroup({
    source: "classroom",
    events: {
        "set current classroom step": props<{ stepNumber: number }>(),
        "next classroom step": emptyProps(),
        "previous classroom step": emptyProps(),
        "clear classroom state": emptyProps(),
        "save classroom ids data": props<{
            data: PostClassroomStepResponse | null;
        }>(),
        "save classroom info": props<{
            data: PostClassroomInfoInterface;
        }>(),
        "save classroom teachers info": props<{
            data: PostClassroomTeacherInterface;
        }>(),
        "save classroom students info": props<{
            data: PostClassroomStudentInterface;
        }>(),
        "get classroom data": props<{ id?: string }>(),
        "set classroom data": props<{
            data: ClassroomInterface;
        }>(),
        "get teachers classrooms": props<{ classTeacherOnly?: boolean }>(),
        "set classrooms": props<{
            data: ClassroomInterface[];
        }>(),
        "get students classrooms": emptyProps(),
    },
});
