import { Component } from '@angular/core';

@Component({
  selector: 'sms-powerd-by',
  templateUrl: './powerd-by.component.html',
  standalone: true,
  styleUrls: ['./powerd-by.component.scss'],
})
export class PowerdByComponent {
  constructor() {}
}
