import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  constructor(private httpClient: HttpClient) {}

  createOrder(data: any): Observable<any> {
    return this.httpClient.post<any>(
      environment.API_URL + '/create-order/',
      data
    );
  }
}
