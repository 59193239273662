import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, of, switchMap } from "rxjs";
import { AnnouncementService } from "../../services";
import { appEventActions } from "../app-event";
import { lovsActions } from "../lovs";
import { announcementActions } from "./announcement.action";

@Injectable()
export class AnnouncementEffects {
    constructor(
        private actions$: Actions,
        private announcementService: AnnouncementService,
    ) {}

    create$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(announcementActions.create),
            switchMap((data) =>
                this.announcementService.create(data.data).pipe(
                    switchMap(() => {
                        data.onClose(true);
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Announcement sent successfully.",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });

    update$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(announcementActions.update),
            switchMap((data) =>
                this.announcementService.update(data.data, data.id).pipe(
                    switchMap(() => {
                        data.onClose(true);
                        return of(
                            appEventActions.addToast({
                                severity: "success",
                                detail: "Announcement updated successfully.",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });

    getMyAnnouncements$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(announcementActions.getMyAnnouncements),
            switchMap((data) =>
                this.announcementService.getMyAnnouncements().pipe(
                    switchMap((resp) => {
                        return of(
                            announcementActions.setMyAnnouncements({
                                data: resp,
                                isMobile: data.isMobile,
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });

    getOneAnnouncement$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(announcementActions.getOneAnnouncement),
            switchMap((data) =>
                this.announcementService.getOneAnnouncements(data.id).pipe(
                    switchMap((resp) => {
                        return of(announcementActions.setOneAnnouncement({ data: resp }));
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });

    markAsRead$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(announcementActions.markAsRead),
            switchMap((data) =>
                this.announcementService.markAsRead(data.id).pipe(
                    switchMap(() => {
                        if (data.id !== "all-read") {
                            return of(
                                // announcementActions.getMyAnnouncements({
                                //   isMobile: data.isMobile,
                                // }),
                                lovsActions.getLovData({
                                    data: { url: "/app/announcements/my/list/" },
                                }),
                                announcementActions.markCurrentAnnouncement(),
                                appEventActions.addToast({
                                    severity: "success",
                                    detail: "Announcement marked as read.",
                                }),
                            );
                        }

                        return of(
                            // announcementActions.getMyAnnouncements({
                            //     isMobile: data.isMobile,
                            // }),
                            lovsActions.getLovData({
                                data: { url: "/app/announcements/my/list/", queryParams: data.queryParams },
                            }),
                            appEventActions.addToast({
                                severity: "success",
                                detail: "All announcements marked as read.",
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });

    getMyAnnouncementsCount$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(announcementActions.getMyAnnouncementsCount),
            switchMap((data) =>
                this.announcementService.getMyAnnouncementsCount().pipe(
                    switchMap((resp) => {
                        return of(
                            announcementActions.setMyAnnouncementsCount({
                                data: resp.unread_count,
                            }),
                        );
                    }),
                    catchError((err) => {
                        return of(appEventActions.handleError(err));
                    }),
                ),
            ),
        );
    });
}
