import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EnquiryService } from '../service/enquiry.service';
import { enquiryActions } from './enquiry.action';
import { catchError, of, switchMap } from 'rxjs';
import { appEventActions } from 'src/app/shared/store/app-event';

@Injectable()
export class EnquiryEffects {
  constructor(
    private actions$: Actions,
    private enquiryService: EnquiryService
  ) {}

  createEnquiryEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(enquiryActions.create),
      switchMap((data) =>
        this.enquiryService.createEnquiry(data.data).pipe(
          switchMap(() =>
            of(
              appEventActions.redirect({
                navigate: ['../'],
              }),
              appEventActions.addToast({
                severity: 'success',
                detail:
                  'Thank you for filling enquiry, our Admin will contact you soon.',
              })
            )
          ),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    );
  });
}
