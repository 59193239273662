import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { AcademicYearInterface, CreateAcademicYearInterface } from "src/app/shared/interfaces/settings/academic-year";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
    deps: [HttpClient],
})
export class AcademicYearService {
    constructor(private httpClient: HttpClient) {}

    getAllAcademicYears(): Observable<AcademicYearInterface[]> {
        return this.httpClient.get<AcademicYearInterface[]>(environment.API_URL + "/schools/admin/academic-year/");
    }

    create(data: CreateAcademicYearInterface): Observable<CreateAcademicYearInterface> {
        return this.httpClient.post<CreateAcademicYearInterface>(
            environment.API_URL + "/schools/admin/academic-year/create/",
            data,
        );
    }

    update(data: CreateAcademicYearInterface, id: string): Observable<CreateAcademicYearInterface> {
        return this.httpClient.put<CreateAcademicYearInterface>(
            environment.API_URL + `/schools/admin/academic-year/${id}/`,
            data,
        );
    }
}
