import { CommonModule } from "@angular/common";
import { Component, Input, type OnInit } from "@angular/core";
import { AvatarModule } from "primeng/avatar";
import { EnvPipe } from "src/app/core/pipes";
import { environment } from "src/environments/environment";

@Component({
    selector: "sms-avatar",
    standalone: true,
    imports: [CommonModule, AvatarModule, EnvPipe],
    templateUrl: "./avatar.component.html",
    styleUrls: ["./avatar.component.scss"],
})
export class AvatarComponent implements OnInit {
    @Input() class: string;
    @Input() photo: string;
    @Input() fallbackLabel: string;
    @Input() size: "normal" | "large" | "xlarge" | undefined = "large";
    @Input() shape: "square" | "circle" | undefined = "circle";

    ngOnInit(): void {}

    onError(event: any, item: string) {
        event.target.src = `${environment.AVATAR_GENRATION_API}/?name=${item}`;
    }
}
