<div
    class="flex justify-start items-center gap-2 overflow-x-clip border-b border-[rgba(0,_0,_0,_0.12)]"
    *ngIf="isLoading"
>
    <p-skeleton width="150px" height="50px" />
    <p-skeleton width="150px" height="50px" />
    <p-skeleton width="150px" height="50px" />
    <p-skeleton width="150px" height="50px" />
    <p-skeleton width="150px" height="50px" />
    <p-skeleton width="150px" height="50px" />
</div>

<p-tabMenu
    [(activeItem)]="activeItem"
    [model]="items"
    [scrollable]="true"
    (activeItemChange)="onActiveItemChange($event)"
></p-tabMenu>
<ng-container *ngIf="activeItem; else elseTemplate">
    <ng-container [ngTemplateOutlet]="tabsBody" [ngTemplateOutletContext]="{ $implicit: activeItem }"></ng-container>
</ng-container>
<ng-template #elseTemplate>
    <div class="flex justify-center items-center h-[500px]">
        <sms-no-data></sms-no-data>
    </div>
</ng-template>
