import { Injectable, isDevMode, Renderer2, RendererFactory2 } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { first } from "rxjs";
import { environment } from "src/environments/environment";
import { pwaTags, webManifest } from "../../constants";

@Injectable({
    providedIn: "root",
})
export class PwaService {
    private renderer: Renderer2;

    constructor(
        rendererFactory: RendererFactory2,
        private swUpdate: SwUpdate,
    ) {
        this.renderer = rendererFactory.createRenderer(null, null);
    }

    setUpPWA() {
        this.addTags(pwaTags);
    }

    addTags(tags: Array<any>): void {
        tags.forEach((tag) => {
            const link = this.renderer.createElement("link");
            this.renderer.setAttribute(link, "href", tag.href.replace("{{school}}", environment.SCHOOL_CONFIG_NAME));
            this.renderer.setAttribute(link, "rel", tag.rel);

            if (tag.media) {
                this.renderer.setAttribute(link, "media", tag.media);
            }

            if (tag.type) {
                this.renderer.setAttribute(link, "type", tag.type);
            }
            this.renderer.appendChild(document.head, link);
        });
    }

    isIos() {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;
    }

    isInStandaloneMode() {
        return "standalone" in window.navigator && window.navigator["standalone"];
    }

    checkUpdate() {
        if (!isDevMode()) {
            if (this.swUpdate.isEnabled) {
                // check for updates every 6
                this.swUpdate.checkForUpdate();

                // if a new version is available, ask the user to load it
                this.swUpdate.versionUpdates.pipe(first()).subscribe((versionEvent) => {
                    if (versionEvent.type === "VERSION_READY") {
                        if (confirm("New version available. Load New Version?")) {
                            window.location.reload();
                        }
                    }
                });
            }
        }
    }

    handleDynamicManifest() {
        let myDynamicManifest = {
            ...webManifest,
            icons: webManifest.icons.map((icon) => ({
                ...icon,
                src: icon.src.replace("{{school}}", environment.SCHOOL_CONFIG_NAME),
            })),
        };
        let content = encodeURIComponent(JSON.stringify(myDynamicManifest));
        let url = "data:application/manifest+json," + content;

        const link = this.renderer.createElement("link");
        this.renderer.setAttribute(link, "href", url);
        this.renderer.setAttribute(link, "rel", "manifest");
        this.renderer.appendChild(document.head, link);
    }
}
