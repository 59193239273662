<div class="flex justify-center items-center my-10">
    <div
        class="border border-gray-100 shadow-s p-10 rounded-lg w-[600px] flex justify-center items-center gap-6 flex-col"
    >
        <h3 class="text-lg text-gray-500 font-semibold font-satoshi">Upload Excel File</h3>
        <div class="flex justify-start items-center gap-5">
            <button
                [loading]="clickedButton === 'download' && !!(isLoading$ | async)"
                (click)="downloadSmpleFile()"
                size="small"
                variant="outlined"
                smsButton
            >
                <i class="text-lg ph-duotone ph-download"></i>
                Download Sample Excel
            </button>
            <sms-file-upload-button
                [size]="'small'"
                [formControl]="fileField"
                [loading]="clickedButton === 'upload' && !!(isLoading$ | async)"
            >
                <i class="text-lg ph-duotone ph-upload"></i>
                Upload Sample File
            </sms-file-upload-button>
        </div>
        <p class="text-gray-400 text-base text-center font-satoshi">
            Important: Kindly ensure you utilize the sample Excel provided by the system, and that the data within the
            Excel file is valid.
        </p>
    </div>
</div>
<sms-divider [horizontal]="true"></sms-divider>
<div class="flex justify-end items-center gap-5 px-2">
    <button (click)="onClose()" smsButton size="small" variant="outlined">Close</button>
</div>
