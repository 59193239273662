import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { adminAdmissionActions } from './admin-admission.action';
import { catchError, of, switchMap } from 'rxjs';
import { AdminAdmissionService } from '../services';
import { appEventActions } from 'src/app/shared/store';
import { downloadFile } from 'src/app/core/helpers';

@Injectable({
  providedIn: 'root',
})
export class AdminAdmissionEffects {
  constructor(
    private actions$: Actions,
    private adminAdmissionService: AdminAdmissionService
  ) {}

  scheduleInterview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminAdmissionActions.scheduleInterview),
      switchMap((data) =>
        this.adminAdmissionService.scheduleInterview(data.data).pipe(
          switchMap(() => {
            data.onClose(true);
            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Interview Scheduled Sucessfully',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    )
  );

  scheduleBulkInterview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminAdmissionActions.scheduleBulkInterviews),
      switchMap((data) =>
        this.adminAdmissionService.scheduleBulkInterview(data.data).pipe(
          switchMap(() => {
            data.onClose(true);
            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Interviews Scheduled Sucessfully',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    )
  );

  changeAdmissionStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminAdmissionActions.changeAdmissionStatus),
      switchMap((data) =>
        this.adminAdmissionService.changeAdmissionStatus(data.data).pipe(
          switchMap(() => {
            data.onClose(true);
            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Admission Status Changed Sucessfully',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    )
  );

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(adminAdmissionActions.updateEnquiry),
      switchMap((data) =>
        this.adminAdmissionService.updateEnquiry(data.data, data.id).pipe(
          catchError((err) => {
            return of(appEventActions.handleError(err));
          }),
          switchMap(() => {
            data.onClose(true);
            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Enquiry updated successfully.',
              })
            );
          })
        )
      )
    );
  });

  exportAdmissionExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminAdmissionActions.exportAdmissionExcel),
      switchMap((data) =>
        this.adminAdmissionService.exportAdmissionsExcel(data?.status).pipe(
          switchMap((response) => {
            downloadFile(response.file);
            return of(
              appEventActions.addToast({
                detail: 'Excel downloaded successfully.',
                severity: 'success',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    )
  );

  exportEnquiriesExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminAdmissionActions.exportEnquiriesExcel),
      switchMap((data) =>
        this.adminAdmissionService.exportEnquiriesExcel().pipe(
          switchMap((response) => {
            downloadFile(response.file);
            return of(
              appEventActions.addToast({
                detail: 'Excel downloaded successfully.',
                severity: 'success',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    )
  );


  exportIdCardExcel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminAdmissionActions.exportIdcardExcel),
      switchMap((data) =>
        this.adminAdmissionService.exportIdCardExcel().pipe(
          switchMap((response) => {
            console.log('---------',response)
            downloadFile(response.file);
            return of(
              appEventActions.addToast({
                detail: 'Excel downloaded successfully.',
                severity: 'success',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    )
  );
}
