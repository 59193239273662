import { createFeature, createReducer } from '@ngrx/store';

const studentAdmissionFeature = createFeature({
  name: 'student-admission',
  reducer: createReducer({}),
});

export const {
  name: studentAdmissionFeatureKey,
  reducer: studentAdmissionReducer,
} = studentAdmissionFeature;
