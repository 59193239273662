import { createFeature, createReducer, on } from '@ngrx/store';
import { contentManagementActions } from './content-management.action';
import { ContentManagementStoreInterface } from 'src/app/shared/interfaces/settings/content-management';

const initailState: ContentManagementStoreInterface = {
  contentManagementData: null,
  contentManagementList: null,
};

const contentManagementFeature = createFeature({
  name: 'content-management',
  reducer: createReducer(
    initailState,
    on(contentManagementActions.setContentManagementData, (state, action) => {
      return {
        ...state,
        contentManagementData: action.data,
      };
    }),
    on(contentManagementActions.update, (state) => ({ ...state }))
  ),
});

export const {
  name: contentManagementFeatureKey,
  reducer: contentManagementReducer,
  selectContentManagementData,
  selectContentManagementList,
} = contentManagementFeature;
