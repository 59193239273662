import { createFeature, createReducer, on } from '@ngrx/store';
import { subjectActions } from './subject.action';
import { SubjectStoreInterface } from 'src/app/shared/interfaces';

const initailState: SubjectStoreInterface = {
  subjects: [],
};

const subjectFeature = createFeature({
  name: 'subject',
  reducer: createReducer(
    initailState,
    on(subjectActions.setSubjects, (state, action) => ({
      ...state,
      subjects: action.data,
    })),
    on(subjectActions.create, (state) => ({ ...state })),
    on(subjectActions.update, (state) => ({ ...state }))
  ),
});

export const {
  name: subjectFeatureKey,
  reducer: subjectReducer,
  selectSubjects,
} = subjectFeature;
