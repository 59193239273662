<div class="flex justify-between items-stretch gap-5 overflow-x-auto w-full md:h-[120px]">
    <div
        class="md:flex hidden flex-col gap-3 max-w-80 min-w-52 !h-full self-stretch justify-between"
        *ngFor="let item of steps; let index = index"
        [class.cursor-pointer]="!disableStepClick"
        [ngStyle]="{ minWidth: '200px', width: '100%', maxWidth: '100%' }"
        (click)="!disableStepClick && onCurrentStepChange(item)"
    >
        <div class="flex w-full justify-start items-center gap-4" *ngIf="currentStep">
            <div
                class="text-gray-800 font-normal text-center font-satoshi text-4xl"
                [ngClass]="{
                    'text-primary !font-bold': item.stepNumber === currentStep.stepNumber,
                }"
            >
                {{ getStepNumber(item.stepNumber) }}
            </div>
            <div class="flex flex-col items-start gap-1 justify-center">
                <h5 class="text-base font-bold font-satoshi text-heading">
                    {{ stepperName }}
                </h5>
                <p class="my-0 font-satoshi text-xs font-medium text-gray-400">
                    <span class="font-bold text-gray-900">Section {{ item.stepNumber }}: </span>
                    {{ item.label }}
                </p>
            </div>
        </div>

        <div class="w-full flex justify-start items-center gap-4" *ngIf="currentStep">
            <div
                class="my-4 ml-4 w-2 h-2 rounded-full outline outline-1 outline-offset-[5px] outline-gray-300"
                [ngClass]="{
                    'bg-[#0DC7B1] !outline-[#0DC7B1] !outline-2': item.stepNumber <= currentStep.stepNumber,
                }"
            ></div>
            <div
                class="w-full h-[1px] rounded-lg bg-gray-100"
                [ngClass]="{
                    '!h-[2px] !bg-[#0DC7B1]': item.stepNumber <= currentStep.stepNumber,
                }"
            ></div>
        </div>
    </div>
    <div class="flex md:hidden justify-between items-center w-full">
        <div
            class="cursor-pointer w-[44px] h-[44px] flex justify-center items-center bg-primary-light rounded-full text-primary-dark font-satoshi text-center"
            [class.!bg-gray-100.!text-gray-500]="currentStep.stepNumber === 1"
            [class.!text-gray-500]="currentStep.stepNumber === 1"
            [class.!cursor-default]="currentStep.stepNumber === 1"
            (click)="onPrevious()"
        >
            <i class="ph-bold ph-caret-left text-lg"></i>
        </div>
        <div class="flex justify-start items-center gap-3">
            <sms-circular-progress
                [percentage]="(currentStep.stepNumber / steps.length) * 100"
                text="{{ currentStep.stepNumber }} of {{ steps.length }}"
            ></sms-circular-progress>
            <div class="flex flex-col items-start gap-1 justify-center">
                <h5 class="text-base font-bold font-satoshi text-heading">
                    {{ stepperName }}
                </h5>
                <p class="my-0 font-satoshi text-xs font-medium text-gray-400">
                    <span class="font-bold text-gray-900">Section {{ currentStep.stepNumber }}: </span>
                    {{ currentStep.label }}
                </p>
            </div>
        </div>
        <div
            class="cursor-pointer z-[1000] w-[44px] h-[44px] flex justify-center items-center bg-primary-light rounded-full text-primary-dark font-satoshi text-center"
            [class.!bg-gray-100.!text-gray-500]="currentStep.stepNumber === steps.length"
            [class.!text-gray-500]="currentStep.stepNumber === steps.length"
            [class.!cursor-default]="currentStep.stepNumber === steps.length"
            (click)="onNext()"
        >
            <i class="ph-bold ph-caret-right text-lg"></i>
        </div>
    </div>
</div>

<ng-container
    [ngTemplateOutlet]="stepperBody"
    [ngTemplateOutletContext]="{
        $implicit: currentStep,
        onNext: onNext,
        onPrevious: onPrevious,
    }"
></ng-container>
