import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'sms-help-text',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './help-text.component.html',
  styleUrls: ['./help-text.component.scss'],
})
export class HelpTextComponent {}
