<label class="inline-flex items-center cursor-pointer">
    <input class="hidden" [checked]="checked" (change)="onValueChange($event)" type="checkbox" />
    <div
        class="relative w-9 h-5 rounded-full"
        [ngClass]="{
            'bg-[#0DC7B1]': checked,
            'bg-error': !checked,
        }"
    >
        <div
            class="absolute top-1/2 bg-white !h-4 !w-4 rounded-full transition"
            [style]="{
                transform: checked ? 'translate(18px,-50%)' : 'translate(2px,-50%)',
            }"
        ></div>
    </div>
    <sms-label *ngIf="label">{{ label }}</sms-label>
</label>
