import { createFeature, createReducer } from '@ngrx/store';

const initialState: any = {};

const adminAdmissionFeature = createFeature({
  name: 'admin-admission',
  reducer: createReducer(initialState),
});

export const {
  name: adminAdmissionFeatureKey,
  reducer: adminAdmissionReducer,
} = adminAdmissionFeature;
