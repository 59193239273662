import { environment } from 'src/environments/environment';

export const downloadFile = (path: string, fileName: string = '') => {
  let download_path =
    environment.MEDIA_FOLDER_URL + (path[0] === '/' ? path : `/${path}`);

  if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
    window.location.href = download_path;
  } else {
    let anchor = document.createElement('a');
    anchor.setAttribute('href', download_path);
    anchor.setAttribute('target', '_blank');
    anchor.setAttribute('download', fileName);
    anchor.click();
  }
};
