import { createFeature, createReducer, on } from '@ngrx/store';
import { AnnouncementStoreInterface } from '../../interfaces';
import { announcementActions } from './announcement.action';

const initailState: AnnouncementStoreInterface = {
  myAnnouncements: null,
  announcement: null,
  myAnnouncementsCount: null,
};

const announcementFeature = createFeature({
  name: 'announcement',
  reducer: createReducer(
    initailState,
    on(announcementActions.setMyAnnouncements, (state, action) => ({
      ...state,
      myAnnouncements: action.data,
      announcement: action.isMobile
        ? null
        : state.announcement
        ? state.announcement
        : action.data.rows[0],
    })),
    on(announcementActions.setOneAnnouncement, (state, action) => ({
      ...state,
      announcement: action.data,
    })),
    on(announcementActions.markCurrentAnnouncement, (state) => ({
      ...state,
      announcement: state.announcement
        ? { ...state.announcement, is_read: true }
        : null,
    })),
    on(announcementActions.setMyAnnouncementsCount, (state, action) => ({
      ...state,
      myAnnouncementsCount: action.data,
    })),
    on(announcementActions.decreaseAnnouncementsCount, (state) => ({
      ...state,
      myAnnouncementsCount:
        state.myAnnouncementsCount && state!.myAnnouncementsCount - 1,
    }))
  ),
});

export const {
  name: announcementFeatureKey,
  reducer: announcementReducer,
  selectMyAnnouncements,
  selectAnnouncement,
  selectMyAnnouncementsCount,
} = announcementFeature;
