import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  CreateSubjectInterface,
  SubjectInterface,
  onClose,
} from 'src/app/shared/interfaces';

export const subjectActions = createActionGroup({
  source: 'subject',
  events: {
    'load subjects': emptyProps(),
    'set subjects': props<{ data: SubjectInterface[] }>(),
    create: props<{
      data: CreateSubjectInterface;
      onClose: onClose;
    }>(),
    update: props<{
      data: CreateSubjectInterface;
      id: string;
      onClose: onClose;
    }>(),
  },
});
