import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { map } from "rxjs";
import { selectUserType } from "../stores";

export const RoleGuard = (role: string): CanActivateFn => {
    return (route: ActivatedRouteSnapshot, state) => {
        const store = inject(Store);
        const router = inject(Router);
        return store.select(selectUserType).pipe(
            map((userType) => {
                if (userType !== role) {
                    router.navigate(["/"]);
                    return false;
                }
                return true;
            }),
        );
    };
};
