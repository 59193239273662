import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { TooltipModule } from "primeng/tooltip";
import { ButtonDirective } from "src/app/core/directives";
import { DividerComponent } from "src/app/shared/components";
import { copyToClipboard } from "src/app/shared/helpers";
import { appEventActions } from "src/app/shared/store";

@Component({
    selector: "sms-show-form-number",
    standalone: true,
    imports: [CommonModule, ButtonDirective, DividerComponent, TooltipModule],
    templateUrl: "./show-form-number.component.html",
    styleUrls: ["./show-form-number.component.scss"],
})
export class ShowFormNumberComponent implements OnInit {
    admissionNumber: string;

    constructor(
        private config: DynamicDialogConfig,
        private dialogRef: DynamicDialogRef,
        private store: Store,
    ) {}

    ngOnInit(): void {
        this.admissionNumber = this.config.data;
    }

    onClose() {
        this.dialogRef.close(false);
    }

    onCopy() {
        copyToClipboard(this.admissionNumber);
        this.store.dispatch(
            appEventActions.addToast({
                severity: "success",
                detail: "Admission Number Copied Successfully",
            }),
        );
    }
}
