<div class="flex flex-col">
    <sms-list-with-pagination [bodyRef]="receiver" key="/app/announcements/receivers/{{ announcementId }}/">
    </sms-list-with-pagination>
    <ng-template #receiver let-item>
        <div class="flex justify-start items-center px-1 h-[74px]">
            <div class="px-2 h-full flex justify-center items-center w-[74px]">
                <ng-container *ngIf="item.student; else elseTemplate">
                    <sms-avatar
                        [photo]="item.student.photo"
                        [fallbackLabel]="item.student.first_name"
                        size="large"
                        shape="circle"
                    ></sms-avatar>
                </ng-container>
                <ng-template #elseTemplate>
                    <sms-avatar
                        [photo]="item.teacher.photo"
                        [fallbackLabel]="item.teacher.first_name"
                        size="large"
                        shape="circle"
                    ></sms-avatar>
                </ng-template>
            </div>
            <div class="border-t-1 border-gray-200 w-full h-full flex justify-between items-center gap-5">
                <div class="flex flex-col">
                    <p class="m-0 text-active font-satoshi text-sm">
                        {{ item.student ? item.student.full_name : item.teacher.full_name }}
                    </p>
                    <span class="text-placeholder text-sm font-satoshi">
                        {{
                            item.read_at
                                ? (item.read_at | timeAgo) + " at " + (item.read_at | dateTime: "shortTime")
                                : "-"
                        }}
                    </span>
                </div>
                <div [variant]="item.is_read ? 'success' : 'warn'" smsChip>
                    {{ item.is_read ? "Read" : "Unread" }}
                </div>
            </div>
        </div>
    </ng-template>
</div>
