import { createFeature, createReducer, createSelector, on } from "@ngrx/store";
import {
    ADMIN_USER_DATA,
    AdminMenus,
    CURRENT_USER_DATA,
    USERS_DATA,
    USER_TYPE,
    getStudentMenus,
    menus,
} from "src/app/shared/constants";
import { TeacherMenus } from "src/app/shared/constants/menus/teacher.constants";
import { AuthStoreInterface } from "../../interfaces";
import { authActions } from "./auth.action";

let userData = localStorage.getItem(CURRENT_USER_DATA) ? JSON.parse(localStorage.getItem(CURRENT_USER_DATA)!) : null;
let usersData = localStorage.getItem(USERS_DATA) ? JSON.parse(localStorage.getItem(USERS_DATA)!) : null;
let adminUserData = localStorage.getItem(ADMIN_USER_DATA) ? JSON.parse(localStorage.getItem(ADMIN_USER_DATA)!) : null;
let userType = localStorage.getItem(USER_TYPE) ? JSON.parse(localStorage.getItem(USER_TYPE)!) : null;

const initialState: AuthStoreInterface = {
    isLoggedIn: !!userData,
    userData: userType === "Student" ? userData : null,
    teacherData: userType === "Teacher" ? userData : null,
    userType,
    usersData,
    isAdminLoggedIn: !!adminUserData,
    adminUserData: adminUserData,
    menuItems: (typeof menus[userType] === "function" ? menus[userType](userData) : menus[userType]) || [],
    forgotPasswordStep: 1,
    forgotPasswordData: null,
    isOTPValid: null,
};

const authFeature = createFeature({
    name: "auth",
    reducer: createReducer(
        initialState,
        on(authActions.studentLoginSuccess, (state, action) => ({
            ...state,
            isLoggedIn: true,
            isAdminLoggedIn: false,
            userData: action.data,
            menuItems: getStudentMenus(action.data),
        })),
        on(authActions.teacherLoginSuccess, (state, action) => ({
            ...state,
            isLoggedIn: true,
            isAdminLoggedIn: false,
            teacherData: action.data,
            menuItems: TeacherMenus,
        })),
        on(authActions.setIdCardFilled, (state) => ({
            ...state,
            userData: state.userData
                ? {
                      ...state.userData,
                      is_id_card_filled: true,
                  }
                : null,
        })),
        on(authActions.setDocumentAdded, (state) => ({
            ...state,
            userData: state.userData
                ? {
                      ...state.userData,
                      is_document_added: true,
                  }
                : null,
        })),
        on(authActions.studentLogout, (state) => ({
            ...state,
            isLoggedIn: false,
            userData: null,
            usersData: null,
            menuItems: [],
        })),
        on(authActions.setUsersData, (state, action) => ({
            ...state,
            usersData: action.data,
        })),
        on(authActions.setUserType, (state, action) => ({
            ...state,
            userType: action.data,
        })),
        on(authActions.adminLoginSuccess, (state, action) => ({
            ...state,
            isAdminLoggedIn: true,
            adminUserData: action.data,
            menuItems: AdminMenus,
        })),
        on(authActions.adminLogout, (state) => ({
            ...state,
            isAdminLoggedIn: false,
            isLoggedIn: false,
            adminUserData: null,
            menuItems: [],
        })),
        on(authActions.teacherLogout, (state) => ({
            ...state,
            isAdminLoggedIn: false,
            userData: null,
            userType: null,
            teacherData: null,
            isLoggedIn: false,
            adminUserData: null,
            menuItems: [],
        })),
        on(authActions.setForgotPasswordStep, (state, payload) => ({
            ...state,
            forgotPasswordStep: payload.setpNumber,
        })),
        on(authActions.setForgotPasswordData, (state, payload) => ({
            ...state,
            forgotPasswordData: payload.data,
        })),
        on(authActions.setIsOTPValid, (state, payload) => ({
            ...state,
            isOTPValid: payload.data,
        })),
    ),
});

export const {
    name: authFeatureKey,
    reducer: authReducer,
    selectIsLoggedIn,
    selectUserData,
    selectUsersData,
    selectIsAdminLoggedIn,
    selectAdminUserData,
    selectMenuItems,
    selectUserType,
    selectTeacherData,
    selectAuthState,
    selectForgotPasswordData,
    selectForgotPasswordStep,
    selectIsOTPValid,
} = authFeature;

export const selectLoggedIn = createSelector(selectAuthState, (state) => ({
    isAdmin: state.isAdminLoggedIn,
    isUser: state.isLoggedIn,
}));
