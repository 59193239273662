import { CommonModule } from "@angular/common";
import { Component, Input, type OnInit } from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { SliderModule } from "primeng/slider";
import { LabelComponent } from "../../label/label.component";

@Component({
    selector: "sms-slider",
    standalone: true,
    imports: [CommonModule, SliderModule, ReactiveFormsModule, LabelComponent],
    templateUrl: "./slider.component.html",
    styleUrls: ["./slider.component.scss"],
})
export class SliderComponent implements OnInit {
    @Input() control: FormControl = new FormControl(5);
    @Input() label: string;
    @Input() isRequired: boolean;
    ngOnInit(): void {}
}
