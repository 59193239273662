<form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <div class="grid grid-cols-1 tb:grid-cols-2 gap-5 tb:mt-0 mt-8">
        <sms-autocomplete
            [options]="classroomSubjectsData"
            field="name"
            formControlName="class_subject"
            label="Select Subject"
        ></sms-autocomplete>
        <sms-autocomplete
            [options]="homeworkTypes"
            formControlName="type"
            label="Select Homework Type"
        ></sms-autocomplete>
        <sms-date-time
            *ngIf="formGroup.get('type')?.value === 'Assignment'"
            [showTime]="true"
            [minDate]="today"
            formControlName="submission_date"
            label="Submission Date"
        ></sms-date-time>
        <sms-textarea class="tb:col-span-2 col-span-1" formControlName="title" label="Title of Homework"></sms-textarea>
        <sms-rich-text-editor
            class="tb:col-span-2 col-span-1"
            [required]="true"
            [control]="$any(formGroup.controls['description'])"
            label="Description"
        ></sms-rich-text-editor>

        <sms-file-input
            class="tb:col-span-2 col-span-1"
            [multiple]="true"
            formControlName="attachments"
            label="Attachments"
        ></sms-file-input>

        <button [loading]="isLoading$ | async" smsButton type="submit">
            <ng-container *ngIf="!initialData; else elseTemplate">
                <i class="ph-bold ph-plus"></i>
                Add Homework
            </ng-container>
            <ng-template #elseTemplate> Update Homework </ng-template>
        </button>
        <button [variant]="'outlined'" [routerLink]="['../']" smsButton>Close</button>
    </div>
</form>
