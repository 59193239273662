import { createFeature, createReducer, on } from '@ngrx/store';
import { AcademicYearStoreInterface } from 'src/app/shared/interfaces/settings/academic-year';
import { academicYearActions } from './academic-year.action';

const initailState: AcademicYearStoreInterface = {
  academicYears: [],
};

const academicYearFeature = createFeature({
  name: 'academic-year',
  reducer: createReducer(
    initailState,
    on(academicYearActions.setAcademicYear, (state, action) => {
      return {
        ...state,
        academicYears: action.data,
      };
    }),
    on(academicYearActions.create, (state) => ({ ...state })),
    on(academicYearActions.update, (state) => ({ ...state }))
  ),
});

export const {
  name: academicYearsFeatureKey,
  reducer: academicYearsReducer,
  selectAcademicYears,
} = academicYearFeature;
