import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { AccordionModule } from "primeng/accordion";
import { HeadingComponent } from "../heading/heading.component";

@Component({
    selector: "sms-accordion-tab",
    standalone: true,
    imports: [CommonModule, HeadingComponent, AccordionModule],
    templateUrl: "./accordion-tab.component.html",
    styleUrls: ["./accordion-tab.component.scss"],
})
export class AccordionTabComponent {
    @Input({ required: true }) header!: string;
    @Input({ required: false }) selected: boolean = false;
}
