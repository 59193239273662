import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output, TemplateRef, type OnInit } from "@angular/core";

@Component({
    selector: "sms-button-group",
    standalone: true,
    imports: [CommonModule],
    templateUrl: "./button-group.component.html",
    styleUrls: ["./button-group.component.scss"],
})
export class ButtonGroupComponent implements OnInit {
    activeButtonId = "1";
    @Input() tabsBody: TemplateRef<any>;
    @Input() buttons: { label: string; id: string }[] = [];

    @Output() onButtonSelect: EventEmitter<string> = new EventEmitter();

    ngOnInit(): void {}

    changeTab(item: string) {
        this.activeButtonId = item;
        this.onButtonSelect.emit(item);
    }
}
