import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { Store } from "@ngrx/store";
import { AnnouncementReciverInterface } from "src/app/shared/interfaces";
import { announcementActions } from "src/app/shared/store";
import { AnnouncementHeadingComponent } from "../announcement-heading/announcement-heading.component";

@Component({
    selector: "sms-announcement-card",
    templateUrl: "./announcement-card.component.html",
    styleUrls: ["./announcement-card.component.scss"],
    standalone: true,
    imports: [CommonModule, AnnouncementHeadingComponent, RouterLink],
})
export class AnnouncementCardComponent implements OnInit {
    @Input() sidebar: boolean = false;
    @Input() url: string;
    @Input() selectAnnouncementId: string | null = null;
    @Input() item: AnnouncementReciverInterface;
    constructor(
        private store: Store,
        private router: Router,
    ) {}

    ngOnInit() {}

    onSelectAnnouncement(item: AnnouncementReciverInterface) {
        if (this.url) {
            this.router.navigate([this.url]);
        }
        if (!this.sidebar) {
            this.router.navigate([this.router.routerState.snapshot.url, item.id]);
        }
        this.store.dispatch(announcementActions.setOneAnnouncement({ data: item }));
    }
}
