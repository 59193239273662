import {
  Component,
  HostListener,
  Input,
  ElementRef,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import {
  DragDropFileDirective,
  FormInputContorlDirective,
} from 'src/app/core/directives';
import { HelpTextComponent } from '../../help-text/help-text.component';
import { DisplayValidationErrorComponent } from '../../display-validation-error/display-validation-error.component';
import { EnvPipe } from 'src/app/core/pipes';
import { DocumentViewerComponent } from '../../document-viewer/document-viewer.component';

@Component({
  selector: 'sms-file-input',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HelpTextComponent,
    DisplayValidationErrorComponent,
    DragDropFileDirective,
    EnvPipe,
    DocumentViewerComponent,
  ],
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true,
    },
  ],
})
export class FileInputComponent<T> extends FormInputContorlDirective<T> {
  @Input({ required: true }) label: string = 'Upload file';
  @Input({ required: false }) accept: string = '*';
  @Input({ required: false }) helpText?: string =
    'Choose a file or drag & drop it here';
  @Input() multiple: boolean = false;
  @ViewChild('input') input!: ElementRef<HTMLInputElement>;

  onFileDropped($event: any) {
    let files: any[] = [];
    Object.values($event).forEach((file: any) => files.push(file));

    this.control.setValue(this.multiple ? files : files[0]);
    this.control.markAllAsTouched();
    this.input.nativeElement.value = '';
  }

  @HostListener('change', ['$event.target.files']) emitFiles(event: FileList) {
    const file = !this.multiple ? event.item(0) : Object.values(event);
    this.control.setValue(file);
    this.control.markAllAsTouched();
    this.input.nativeElement.value = '';
  }

  override writeValue(value: T): void {}

  getNames() {
    if (this.multiple) {
      if (this.control.value) {
        return this.control.value.length
          ? this.control.value.length + ' Files Selected'
          : this.label;
      }
    } else {
      if (this.control.value instanceof File) {
        return this.control.value.name || this.label;
      } else {
        if (this.control.value) {
          return (
            this.control.value.split('/')[
              this.control.value.split('/').length - 1
            ] || this.label
          );
        }
        return this.label;
      }
    }
  }

  removeFile(index: number) {
    if (this.multiple) {
      let values = this.control.value;
      if (this.control.value instanceof FileList) {
        values = Object.values(this.control.value);
      }
      values.splice(index, 1);
      console.log(values);

      this.control.patchValue(values);
    } else {
      this.control.setValue(null);
    }
  }
}
