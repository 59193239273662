export const environment = {
    serviceWorker: true,
    production: true,
    DEFAULT_CONFIG_NAME: "default",
    SCHOOL_CONFIG_NAME: "default",
    RAZORPAY_KEY_ID: "rzp_test_mQwJMUtpUIuXi7",
    FRONTEND_URL: "https://sms-demo.quartoloom.com",
    API_URL: "https://sms-demo.quartoloom.com:8000/api",
    MEDIA_FOLDER_URL: "https://sms-demo.quartoloom.com:8000",
    AVATAR_GENRATION_API: "https://ui-avatars.com/api",
    FIREBASE_CONFIG: {
        apiKey: "AIzaSyAKEchTxbjuw7jiN0puaBTQkhOvlnQDg2Q",
        authDomain: "school-management-system-5312e.firebaseapp.com",
        databaseURL: "https://school-management-system-5312e-default-rtdb.asia-southeast1.firebasedatabase.app",
        projectId: "school-management-system-5312e",
        storageBucket: "school-management-system-5312e.appspot.com",
        messagingSenderId: "371820619512",
        appId: "1:371820619512:web:9f94fe43c61fde704dfd0d",
    },
    VAPID_KEY: "BKlvUd3_p5X5NAmVemER_aYePTebAQHGC7U6sd_lQwzyXoWkttfGEmK1pFDi90xoD8ikRu_7RrJwxgC-mc8X0Yk",
};
