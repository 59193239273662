<div class="grid sm:grid-cols-1 w-full">
    <sms-alert class="!mb-5">
        This Is To Inform You Kindly Go Through This Declaration, Sign By Parents & Guardians.</sms-alert
    >
    <p-accordion expandIcon="ph ph-caret-double-left" collapseIcon="ph ph-caret-double-up">
        <sms-accordion-tab [selected]="true" header="KINDLY NOTE">
            <div>
                <h2 class="text-primary font-bold text-sm font-satoshi">
                    All The Parents Have To Follow The Rules Below Given
                </h2>
                <ng-container *ngIf="admissionBatch$ | async as admissionBatch">
                    <h4 class="text-gray-500 text-xs my-4 font-satoshi text-center">
                        Timings: {{ admissionBatch.in_time | dateTime }} To
                        {{ admissionBatch.out_time | dateTime }}
                    </h4>
                    <div class="flex flex-col justify-start gap-2 p-3 bg-primary-light rounded">
                        <div class="flex justify-between items-center">
                            <div class="text-xs text-gray-800 font-satoshi">Class</div>
                            <div class="text-xs text-gray-800 font-satoshi">
                                {{ admissionBatch.label }}
                            </div>
                        </div>
                        <div class="flex justify-between items-center">
                            <div class="text-xs text-gray-800 font-satoshi">In Time</div>
                            <div class="text-xs text-gray-800 font-satoshi">
                                {{ admissionBatch.in_time | dateTime }}
                            </div>
                        </div>
                        <div
                            class="flex justify-between items-center"
                            *ngFor="let batchRule of admissionBatch.batch_rules"
                        >
                            <div class="text-xs text-gray-800 font-satoshi">
                                {{ batchRule["label"] }}
                            </div>
                            <div class="text-xs text-gray-800 font-satoshi">
                                {{ batchRule["value"] || "-" }}
                            </div>
                        </div>

                        <div class="flex justify-between items-center">
                            <div class="text-xs text-gray-800 font-satoshi">Out Time</div>
                            <div class="text-xs text-gray-800 font-satoshi">
                                {{ admissionBatch.out_time | dateTime }}
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </sms-accordion-tab>
        <quill-view-html *ngIf="contentData" [content]="contentData" theme="snow"></quill-view-html>

        <!-- <sms-accordion-tab [selected]="true" header="REGARDING FEES">
      <ul class="px-5 my-0">
        <li class="text-sm font-satoshi text-gray-800 mb-2">
          Once Post Dated Cheque (Pdc) Given Will Be Deposit Accordingly.
        </li>
        <li class="text-sm font-satoshi text-gray-800 mb-2">
          There Will Be No Reminder Announcement For Fees Due Date Or Cheque Deposit.
        </li>
        <li class="text-sm font-satoshi text-gray-800 mb-2">
          Cheque Bounce Fine Would Be Charge Of Rs.350/-.
        </li>
        <li class="text-sm font-satoshi text-gray-800 mb-2">
          If Fees Is Not Cleared Within Given Time, Students Wont Be Allowed To
          Sit For Exams.
        </li>
      </ul>
    </sms-accordion-tab>
    <sms-accordion-tab [selected]="true" header="PARENTS BEHAVIOUR">
      <ul class="px-5 my-0">
        <li class="text-sm font-satoshi text-gray-800 mb-2">
          Parents Are Not Allowed To Argue With Teaching, Non-Teaching & Admin
          Staff.
        </li>
        <li class="text-sm font-satoshi text-gray-800 mb-2">
          Parents Are Not Allowed To Misbehave Or Use Any Type Of Abusive/Slang
          Language. If Found, Strict Action Will Be Taken Against The Parents.
        </li>
      </ul>
    </sms-accordion-tab>
    <sms-accordion-tab [selected]="true" header="REGARDING STUDENT">
      <ul class="px-5 my-0">
        <li class="text-sm font-satoshi text-gray-800 mb-2">
          In Any Case No Re-Exams Will Be Taken.
        </li>
        <li class="text-sm font-satoshi text-gray-800 mb-2">
          Student Remaining Absent During Exam Will Be Marked As Absent In
          Report Card.
        </li>
        <li class="text-sm font-satoshi text-gray-800 mb-2">
          Zero Hair Cut For Boys & Two Pony's For Girls Compulsory.
        </li>
        <li class="text-sm font-satoshi text-gray-800 mb-2">
          Only Black Colour Hairband, Ribbons Are Allowed.
        </li>
        <li class="text-sm font-satoshi text-gray-800 mb-2">
          If A Student Misbehave, Speaks Slang Language Or Beats, Will Be
          Suspended For 2 Days.
        </li>
      </ul>
    </sms-accordion-tab> -->
    </p-accordion>
    <div class="flex justify-start items-start gap-3 mt-5">
        <p-checkbox [binary]="true" [formControl]="declaration" inputId="declaration"> </p-checkbox>

        <label class="text-sm font-semibold font-satoshi text-active leading-5" for="declaration"
            >I hereby declare that I accept the above conditions</label
        >
    </div>
    <div class="text-error text-xs font-satoshi" *ngIf="error">{{ error }}</div>
    <div class="flex flex-col gap-4 items-start self-stretch my-4">
        <button [loading]="isLoading$ | async" (click)="onSave()" smsButton color="primary" type="button">
            Agree & continue
        </button>
        <button (click)="onBack()" smsButton color="primary" variant="outlined" type="button">Back</button>
    </div>
</div>
