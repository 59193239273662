<div
    class="cursor-pointer relative h-full p-4 rounded-lg border border-solid border-gray-100 shadow-xs flex justify-between tb:items-center items-start gap-4 w-full hover:bg-primary-light"
    [ngClass]="{
        'flex-col': direction === 'col',
    }"
    [routerLink]="routerLink"
    [queryParams]="queryParams"
    (click)="onContainerClick($event)"
>
    <div class="flex justify-center items-center gap-2/5 p-2 bg-primary-light rounded-m">
        <!-- <ng-icon name="{{ icon }}" class="text-primary text-2xl"></ng-icon> -->
        <i class="{{ icon }} text-primary text-2xl"></i>
    </div>
    <div
        class="absolute right-4 top-4 text-error self-baseline bg-[#FDEFEC] !text-xs px-2 py-1 rounded-lg flex justify-start items-center gap-2"
        *ngIf="!!count"
    >
        <div class="w-[7px] h-[7px] bg-error rounded-full"></div>
        <div>{{ count }} New</div>
    </div>
    <div class="flex justify-center items-start gap-4 flex-col flex-[1_0_0]">
        <h2 class="font-satoshi font-extrabold text-lg text-active break-all">
            {{ heading }}
        </h2>
        <p class="font-satoshi font-medium text-sm text-placeholder">
            {{ description }}
        </p>
    </div>
</div>
