<div
    class="fixed top-[65px] left-0 bottom-0 bg-white border-0 border-r border-solid border-gray-200 w-[290px] h-[calc(100%_-_65px)] z-10 lg:flex flex-col gap-y-5 py-5 px-2 overflow-y-auto transition-all ease-in-out delay-150 justify-between overflow-auto"
    id="sideNavBar"
    *ngIf="!(isHandset$ | async)"
    [class.!-left-[290px]]="!isSidebarOpen"
>
    <div>
        <sms-text-input
            class="block mb-5"
            [formControl]="searchField"
            type="text"
            placeholder="Quick Find"
            leftIcon="ph-duotone ph-magnifying-glass"
        ></sms-text-input>

        <p-panelMenu [model]="items" [style]="{ width: '300px' }"> </p-panelMenu>
    </div>
    <sms-powerd-by class="left-0"></sms-powerd-by>
</div>
<p-sidebar id="sideNavBar" #sidebarRef *ngIf="isHandset$ | async" [(visible)]="isSidebarOpen">
    <ng-template pTemplate="header">
        <p class="text-[#340E0E] font-black">
            {{ schoolName }}
        </p>
    </ng-template>
    <ng-template pTemplate="footer">
        <sms-powerd-by></sms-powerd-by>
    </ng-template>

    <ng-template pTemplate="headless">
        <div class="flex flex-col h-full gap-5 mt-5">
            <sms-text-input
                [formControl]="searchField"
                type="text"
                placeholder="Quick Find"
                leftIcon="ph-duotone ph-magnifying-glass"
            ></sms-text-input>

            <p-panelMenu [model]="items" [style]="{ width: '300px' }"></p-panelMenu>
        </div>
    </ng-template>
</p-sidebar>
<div
    class="fixed top-0 left-0 right-0 flex justify-between items-center w-full h-[65px] pr-4 py-1 bg-white z-10 border-0 border-b border-solid border-gray-200"
    id="appBar"
>
    <div class="flex justify-center gap-5 px-5 items-center">
        <img class="h-[50px]" src="{{ schoolLogo }}" alt="system logo" />
        <div class="lg:flex flex-col justify-center">
            <div class="text-[#340E0E] text-xl font-satoshi sm:block hidden font-black">
                {{ schoolName }}
            </div>
            <div class="lg:flex hidden text-sm text-heading font-medium">School Management System</div>
        </div>
    </div>

    <div class="flex justify-end items-center gap-x-5 w-[75%] lg:w-[50%]">
        <ng-icon class="text-gray-600 text-xl !hidden lg:!block" name="bootstrapSliders"></ng-icon>
        <ng-icon class="text-gray-600 text-xl !hidden lg:!block" name="bootstrapQuestionCircleFill"></ng-icon>
        <div class="flex flex-col items-end justify-between">
            <p class="!m-0 font-bold text-sm lg:text-base font-satoshi">
                {{ userData?.name }}
            </p>
            <p class="!m-0 text-gray-300 font-normal text-[0.750rem] lg:text-[0.75rem] font-satoshi">
                {{ userData?.subname }}
            </p>
        </div>
        <div class="flex">
            <div (click)="menu.toggle($event)">
                <sms-avatar
                    [photo]="userData.photo"
                    [fallbackLabel]="userData?.name"
                    size="large"
                    shape="circle"
                ></sms-avatar>
            </div>

            <p-menu #menu [model]="accountMenu" [popup]="true" appendTo="body"></p-menu>
        </div>
    </div>
</div>

<div
    class="fixed top-[65px] right-0 left-0 w-full h-[80px] flex justify-between items-center px-4 py-2 bg-white z-10 border-0 border-b border-solid border-gray-200 transition-all ease-in-out delay-150"
    id="navBar"
    [ngClass]="{
        '!left-0 !w-full': (isHandset$ | async),
        '!left-[290px] !w-[calc(100vw_-_290px)]': isSidebarOpen,
    }"
>
    <div class="w-1/3 flex items-center gap-x-3">
        <button class="cursor-pointer rounded-md pt-1" (click)="location.back()">
            <ng-icon
                class="!h-[1.5rem] !w-[1.5rem] text-[1.5rem] lg:!h-[2rem] lg:!w-[2rem] lg:text-[2rem]"
                [name]="'bootstrapCaretLeftFill'"
            ></ng-icon>
        </button>
        <sms-breadcrumb></sms-breadcrumb>
    </div>
    <div class="w-2/3 flex justify-end items-center gap-x-3">
        <div
            class="cursor-pointer rounded-md border border-solid border-neutral-200 p-3 flex justify-center items-center hover:bg-neutral-100 hover:shadow"
            (click)="isSidebarOpen = !isSidebarOpen"
        >
            <i class="pi pi-bars"></i>
        </div>
    </div>
</div>
<div
    class="ml-0 mt-[145px] sm:p-10 p-5 transition-all ease-in-out delay-150 relative h-full"
    [ngClass]="{
        'ml-[290px]': isSidebarOpen && !(isHandset$ | async),
    }"
>
    <div
        class="absolute top-0 right-0 h-[calc(100vh_-_145px)] w-full bg-backdrop z-[1000] overflow-hidden full-page-loading"
        *ngIf="isFullPageLoading$ | async"
    >
        <p-progressSpinner
            class="absolute left-1/2 top-[50vh] translate-center"
            styleClass="w-20 h-20"
            strokeWidth="4"
            fill="transparent"
            animationDuration=".5s"
        ></p-progressSpinner>
    </div>
    <router-outlet></router-outlet>
</div>
