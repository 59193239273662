import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { CreateStandardInterface, StandardInterface, onClose } from "src/app/shared/interfaces";

export const standardActions = createActionGroup({
    source: "standard",
    events: {
        "load standards": emptyProps(),
        "set standards": props<{ data: StandardInterface[] }>(),
        "load standards for admission": emptyProps(),
        "load active standards": emptyProps(),
        "set active standards": props<{ data: StandardInterface[] }>(),
        "load inactive standards": emptyProps(),
        "set inactive standards": props<{ data: StandardInterface[] }>(),
        "load standards for fees": emptyProps(),
        "set standards for fees": props<{ data: StandardInterface[] }>(),
        create: props<{
            data: CreateStandardInterface;
            onClose: onClose;
        }>(),
        update: props<{
            data: CreateStandardInterface;
            id: string;
            onClose: onClose;
        }>(),
    },
});
