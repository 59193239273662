import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { DateTimePipe } from "src/app/core/pipes";
import { ChipDirective } from "src/app/shared/directive";
import { AnnouncementReciverInterface } from "src/app/shared/interfaces";

@Component({
    selector: "sms-announcement-heading",
    standalone: true,
    imports: [CommonModule, ChipDirective, DateTimePipe],
    templateUrl: "./announcement-heading.component.html",
    styleUrls: ["./announcement-heading.component.scss"],
})
export class AnnouncementHeadingComponent implements OnInit {
    @Input() item: AnnouncementReciverInterface;
    constructor() {}

    ngOnInit() {}

    get showRead() {
        return "is_read" in this.item;
    }
}
