import { CommonModule, formatDate } from "@angular/common";
import {
    AfterViewChecked,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    LOCALE_ID,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    TemplateRef,
    ViewChild,
    type OnInit,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { MenuItem } from "primeng/api";
import { MenuModule } from "primeng/menu";
import { Subscription } from "rxjs";
import { ButtonDirective } from "src/app/core/directives";
import { DateTimePipe } from "src/app/core/pipes";
import { BACKEND_DATE_FORMAT } from "src/app/shared/constants";
import { attendanceActions, lovsActions, selectAttendanceStatus, selectData } from "src/app/shared/store";
import { ExportExcelComponent } from "../../others";

@Component({
    selector: "sms-attendance-calender",
    standalone: true,
    imports: [CommonModule, DateTimePipe, MenuModule, ExportExcelComponent, ButtonDirective],
    templateUrl: "./attendance-calender.component.html",
    styleUrls: ["./attendance-calender.component.scss"],
})
export class AttendanceCalenderComponent implements OnInit, OnChanges, OnDestroy, AfterViewChecked {
    @Input() body: TemplateRef<any>;
    @Input() classroom: string;
    @Input() teacher: string | null;
    @Input() excelUrl: string | null;
    @Input() hideStaus: boolean = false;

    @Output() getData: EventEmitter<Date> = new EventEmitter();

    months: any;
    activeDate: Date;
    today = new Date();
    datesData: any = [];
    items: MenuItem[] = [];
    scrollSuccessfull: boolean = false;

    subscription: Subscription = new Subscription();
    @ViewChild("scrollContainer") scrollContainer: ElementRef;

    attendanceStatus$ = this.store.select(selectAttendanceStatus);

    constructor(
        private store: Store,
        @Inject(LOCALE_ID) private localeId: string,
    ) {}

    ngOnInit(): void {
        this.store.dispatch(
            lovsActions.getLovData({
                data: {
                    url: "/schools/admin/academic-months/",
                },
            }),
        );

        this.subscription.add(
            this.store.select(selectData).subscribe((values) => {
                if (values["/schools/admin/academic-months/"]) {
                    this.items = (values["/schools/admin/academic-months/"] || [])
                        .filter((item: any) => {
                            return item.year === this.today.getFullYear()
                                ? item.month <= this.today.getMonth() + 1 && item.year <= this.today.getFullYear()
                                : item.year <= this.today.getFullYear();
                        })
                        .map((item: any) => {
                            if (item.this_month) {
                                this.changeDate(
                                    new Date(
                                        `${item.year}-${item.month.toString().padStart(2, "0")}-${this.today.getDate().toString().padStart(2, "0")}`,
                                    ),
                                    false,
                                );
                            }
                            return {
                                label: item.month_name,
                                state: item,
                                command: () => {
                                    this.changeDate(
                                        new Date(
                                            `${item.year}-${item.month.toString().padStart(2, "0")}-${item.this_month ? this.today.getDate() : new Date(item.year, item.month, 0).getDate()}`,
                                        ),
                                        false,
                                    );
                                },
                            };
                        });

                    if (!this.activeDate && this.items.length) {
                        let lastMonth: any = this.items[this.items.length - 1].state;
                        this.changeDate(
                            new Date(
                                `${lastMonth.year}-${lastMonth.month.toString().padStart(2, "0")}-${new Date(lastMonth.year, lastMonth.month, 0).getDate()}`,
                            ),
                            false,
                        );
                    }
                }
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            (changes["classroom"] && changes["classroom"].currentValue) ||
            (changes["teacher"] && changes["teacher"].currentValue)
        ) {
            this.getAttendanceData();
        }
    }

    ngAfterViewChecked(): void {
        if (!this.scrollSuccessfull) {
            this.scrollToItem();
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    getDaysOfMonth(date: Date, dateClicked?: boolean) {
        if (date) {
            const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
            const result = [];
            const year = date.getFullYear();
            const month = date.getMonth();
            const currentDate =
                this.today.getMonth() === date.getMonth()
                    ? this.today.getDate()
                    : new Date(year, month + 1, 0).getDate();

            for (let date = 1; date <= currentDate; date++) {
                const currentDay = new Date(year, month, date);
                const day = daysOfWeek[currentDay.getDay()];

                result.push({
                    date: date,
                    dateStr: formatDate(currentDay, BACKEND_DATE_FORMAT, this.localeId),
                    fullDate: currentDay,
                    day: day,
                });
            }

            this.datesData = result;

            if (!dateClicked) {
                this.scrollSuccessfull = false;
            }
        }
    }

    getUrl() {
        if (this.excelUrl && this.activeDate) {
            const url = new URLSearchParams();
            const year = this.activeDate.getFullYear();
            const month = this.activeDate.getMonth();

            const firstDate = new Date(year, month, 1);

            const lastDate = new Date(year, month + 1, 0);

            const params: Record<string, string> = {
                classroom: this.classroom,
                start_date: formatDate(firstDate, BACKEND_DATE_FORMAT, this.localeId),
                end_date: formatDate(lastDate, BACKEND_DATE_FORMAT, this.localeId),
            };

            Object.keys(params).forEach((key) => url.append(key, params[key]));
            return this.excelUrl + url.toString();
        }
        return "";
    }

    changeDate(date: Date, dateClicked: boolean) {
        // let isMonthSame = date.getMonth() === this.activeDate.getMonth();
        this.activeDate = date;
        if (this.teacher) {
            this.getData.emit(this.activeDate);
        }

        if (!dateClicked) {
            // if (!isMonthSame) {
            this.getAttendanceData();
            // }

            this.getDaysOfMonth(date, dateClicked);
        }
    }

    scrollToItem(): void {
        if (this.scrollContainer) {
            this.scrollContainer.nativeElement.scrollLeft = this.scrollContainer.nativeElement.scrollWidth;
            this.scrollSuccessfull = true;
        }
    }

    goToCurrentMonth() {
        this.changeDate(this.today, false);
        this.getDaysOfMonth(this.activeDate);
    }

    getAttendanceData() {
        if (this.activeDate) {
            if (this.classroom) {
                this.store.dispatch(
                    attendanceActions.getClassroomAttandanceStatus({
                        params: {
                            classroom: this.classroom,
                            date: formatDate(this.activeDate, BACKEND_DATE_FORMAT, this.localeId),
                        },
                    }),
                );
            } else if (this.teacher) {
                this.store.dispatch(
                    attendanceActions.getTeachersAttendanceStatus({
                        params: {
                            teacher: this.teacher,
                            date: formatDate(this.activeDate, BACKEND_DATE_FORMAT, this.localeId),
                        },
                    }),
                );
            }
        }
    }
}
