import { environment } from "src/environments/environment";

export const pwaTags = [
    { id: "appFavicon", rel: "icon", type: "image/x-icon", href: "/assets/apps/{{school}}/icon.png" },
    { id: "appTouchFavicon", rel: "apple-touch-icon", href: "/assets/apps/{{school}}/icon.png" },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/iPhone_15_Pro__iPhone_15__iPhone_14_Pro_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/iPhone_11_Pro_Max__iPhone_XS_Max_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/iPhone_11__iPhone_XR_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/4__iPhone_SE__iPod_touch_5th_generation_and_later_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 1032px) and (device-height: 1376px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/13__iPad_Pro_M4_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/12.9__iPad_Pro_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 834px) and (device-height: 1210px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/11__iPad_Pro_M4_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/11__iPad_Pro__10.5__iPad_Pro_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/10.9__iPad_Air_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/10.5__iPad_Air_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/10.2__iPad_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)",
        href: "/assets/apps/{{school}}/8.3__iPad_Mini_landscape.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/iPhone_15_Pro_Max__iPhone_15_Plus__iPhone_14_Pro_Max_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/iPhone_15_Pro__iPhone_15__iPhone_14_Pro_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/iPhone_14_Plus__iPhone_13_Pro_Max__iPhone_12_Pro_Max_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/iPhone_14__iPhone_13_Pro__iPhone_13__iPhone_12_Pro__iPhone_12_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/iPhone_13_mini__iPhone_12_mini__iPhone_11_Pro__iPhone_XS__iPhone_X_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/iPhone_11_Pro_Max__iPhone_XS_Max_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/iPhone_11__iPhone_XR_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/iPhone_8_Plus__iPhone_7_Plus__iPhone_6s_Plus__iPhone_6_Plus_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/iPhone_8__iPhone_7__iPhone_6s__iPhone_6__4.7__iPhone_SE_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/4__iPhone_SE__iPod_touch_5th_generation_and_later_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 1032px) and (device-height: 1376px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/13__iPad_Pro_M4_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/12.9__iPad_Pro_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 834px) and (device-height: 1210px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/11__iPad_Pro_M4_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/11__iPad_Pro__10.5__iPad_Pro_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 820px) and (device-height: 1180px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/10.9__iPad_Air_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/10.5__iPad_Air_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 810px) and (device-height: 1080px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/10.2__iPad_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/9.7__iPad_Pro__7.9__iPad_mini__9.7__iPad_Air__9.7__iPad_portrait.png",
    },
    {
        rel: "apple-touch-startup-image",
        media: "screen and (device-width: 744px) and (device-height: 1133px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)",
        href: "/assets/apps/{{school}}/8.3__iPad_Mini_portrait.png",
    },
];

export const webManifest = {
    name: "School Management System",
    short_name: "SMS",
    theme_color: "#004ab0",
    background_color: "#ffffff",
    display: "standalone",
    scope: environment.FRONTEND_URL,
    start_url: environment.FRONTEND_URL,
    prefer_related_applications: false,
    icons: [
        {
            src: `${environment.FRONTEND_URL}/assets/apps/{{school}}/icon-72x72.png`,
            sizes: "72x72",
            type: "image/png",
            purpose: "any",
        },
        {
            src: `${environment.FRONTEND_URL}/assets/apps/{{school}}/icon-96x96.png`,
            sizes: "96x96",
            type: "image/png",
            purpose: "any",
        },
        {
            src: `${environment.FRONTEND_URL}/assets/apps/{{school}}/icon-128x128.png`,
            sizes: "128x128",
            type: "image/png",
            purpose: "any",
        },
        {
            src: `${environment.FRONTEND_URL}/assets/apps/{{school}}/icon-144x144.png`,
            sizes: "144x144",
            type: "image/png",
            purpose: "any",
        },
        {
            src: `${environment.FRONTEND_URL}/assets/apps/{{school}}/icon-152x152.png`,
            sizes: "152x152",
            type: "image/png",
            purpose: "any",
        },
        {
            src: `${environment.FRONTEND_URL}/assets/apps/{{school}}/icon-192x192.png`,
            sizes: "192x192",
            type: "image/png",
            purpose: "any",
        },
        {
            src: `${environment.FRONTEND_URL}/assets/apps/{{school}}/icon-384x384.png`,
            sizes: "384x384",
            type: "image/png",
            purpose: "any",
        },
        {
            src: `${environment.FRONTEND_URL}/assets/apps/{{school}}/icon-512x512.png`,
            sizes: "512x512",
            type: "image/png",
            purpose: "any",
        },
    ],
};
