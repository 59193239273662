import { CommonModule } from "@angular/common";
import { Component, Input } from "@angular/core";
import { DateTimePipe, TimeAgoPipe } from "src/app/core/pipes";
import { ChipDirective } from "src/app/shared/directive";
import { ListWithPaginationComponent } from "../../others/list-with-pagination/list-with-pagination.component";
import { AvatarComponent } from "../../ui";

@Component({
    selector: "sms-announcement-receiver-list",
    standalone: true,
    imports: [CommonModule, AvatarComponent, ChipDirective, TimeAgoPipe, ListWithPaginationComponent, DateTimePipe],
    templateUrl: "./announcement-receiver-list.component.html",
    styleUrls: ["./announcement-receiver-list.component.scss"],
})
export class AnnouncementReceiverListComponent {
    @Input() announcementId: string;
}
