import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CreateOrderInterface } from '../../interfaces';
import { AdmissionInstalmentInterface } from '../../interfaces/admission/admission-fees.interface';

export const paymentActions = createActionGroup({
  source: 'payment',
  events: {
    'create order': props<{
      data: CreateOrderInterface;
    }>(),
    'set order data': props<{
      data: any;
    }>(),

    'open payment box': props<{ data: AdmissionInstalmentInterface }>(),
    'close payment box': emptyProps(),
  },
});
