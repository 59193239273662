<form method="POST" action="https://api.razorpay.com/v1/checkout/embedded">
  <input type="hidden" name="key_id" [value]="'RAZORPAY_KEY_ID' | env" />
  <input type="hidden" name="amount" value="{{ orderData?.total_amount }}" />
  <input type="hidden" name="currency" value="INR" />
  <input type="hidden" name="order_id" value="{{ orderData?.order_id }}" />
  <input type="hidden" name="name" value="{{ schoolConfig.school_name }}" />
  <input
    type="hidden"
    name="image"
    [value]="('FRONTEND_URL' | env) + schoolConfig.school_logo_path"
  />

  <input
    type="hidden"
    name="theme[color]"
    value="{{ schoolConfig.theme['primary-dark'] }}"
  />

  <input
    type="hidden"
    name="callback_url"
    [value]="
      ('API_URL' | env) +
      '/schools/admin/admission-fees-success/?redirect=' +
      redirect.replace('&', '*')
    "
  />
  <input
    type="hidden"
    name="cancel_url"
    [value]="('FRONTEND_URL' | env) + redirect"
  />
  <ng-content></ng-content>
</form>
