import { Directive, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  Validators,
  NgControl,
  FormControlName,
  FormGroupDirective,
  FormControlDirective,
} from '@angular/forms';
import {
  Subject,
  takeUntil,
  startWith,
  distinctUntilChanged,
  tap,
  Subscription,
} from 'rxjs';

@Directive({
  selector: '[smsFormInputContorl]',
  standalone: true,
})
export class FormInputContorlDirective<T>
  implements ControlValueAccessor, OnInit, OnDestroy
{
  control: FormControl = new FormControl();
  isRequired = false;

  _isDisabled = false;
  private _destroy$ = new Subject<void>();
  _onTouched!: () => T;
  controlName!: any;

  subscription: Subscription = new Subscription();
  constructor(@Inject(Injector) private injector: Injector) {}

  ngOnInit() {
    this.setFormControl();
    this.isRequired = this.control?.hasValidator(Validators.required) ?? false;
  }

  setFormControl() {
    try {
      const formControl = this.injector.get(NgControl);
      this.controlName = formControl.name;
      switch (formControl.constructor) {
        case FormControlName:
          this.control = this.injector
            .get(FormGroupDirective)
            .getControl(formControl as FormControlName);
          break;
        default:
          this.control = (formControl as FormControlDirective)
            .form as FormControl;
          break;
      }
    } catch (err) {
      this.control = new FormControl();
    }
  }

  writeValue(value: T): void {
    if (this.control && this.control.value != value)
      this.control.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: (val: T | null) => T): void {
    this.subscription.add(
      this.control?.valueChanges
        .pipe(
          takeUntil(this._destroy$),
          startWith(this.control.value),
          distinctUntilChanged(),
          tap((val) => fn(val))
        )
        .subscribe(() => this.control?.markAsUntouched())
    );
  }

  registerOnTouched(fn: () => T): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._isDisabled = isDisabled;
  }

  isError() {
    return (
      this.control &&
      this.control.touched &&
      this.control.errors &&
      Object.keys(this.control.errors).length
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
