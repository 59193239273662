<sms-label
    class="mb-2 block"
    *ngIf="label"
    [required]="required"
    style="position: absolute; top: 0px; left: 16px; transform: translateY(-50%)"
>
    {{ label }}
</sms-label>
<quill-editor
    [styles]="{ height: '200px' }"
    [class]="isError() ? 'error-field' : ''"
    [formControl]="control"
    format="html"
></quill-editor>

<sms-display-validation-error *ngIf="isError()" [control]="control" [label]="label"></sms-display-validation-error>
