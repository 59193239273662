import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { onClose } from 'src/app/shared/interfaces';
import {
  AcademicYearInterface,
  CreateAcademicYearInterface,
} from 'src/app/shared/interfaces/settings/academic-year';

export const academicYearActions = createActionGroup({
  source: 'academic-year',
  events: {
    'get academic years': emptyProps(),
    'set academic year': props<{ data: AcademicYearInterface[] }>(),
    create: props<{
      data: CreateAcademicYearInterface;
      onClose: onClose;
    }>(),
    update: props<{
      data: CreateAcademicYearInterface;
      id: string;
      onClose: onClose;
    }>(),
  },
});
