import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { NgIconComponent } from '@ng-icons/core';
import { FormInputContorlDirective } from 'src/app/core/directives';
import { DisplayValidationErrorComponent } from '../../display-validation-error/display-validation-error.component';
import { LabelComponent } from '../../label/label.component';
import { InputTextareaModule } from 'primeng/inputtextarea';

@Component({
  selector: 'sms-textarea',
  standalone: true,
  imports: [
    CommonModule,
    InputTextareaModule,
    NgIconComponent,
    ReactiveFormsModule,
    DisplayValidationErrorComponent,
    LabelComponent,
  ],
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true,
    },
  ],
})
export class TextareaComponent<T> extends FormInputContorlDirective<T> {
  @Input() label?: string = '';
  @Input() value?: string;
  @Input() cols?: number = 100;
  @Input() rows?: number = 3;
  @Input() placeholder?: string = '';
  @Input() disabled: boolean = false;
}
