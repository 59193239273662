import { Component, Input, OnInit } from "@angular/core";

@Component({
    selector: "sms-circular-progress",
    templateUrl: "./circular-progress.component.html",
    styleUrls: ["./circular-progress.component.scss"],
    standalone: true,
})
export class CircularProgressComponent implements OnInit {
    @Input() text: string;
    @Input() percentage: number;
    constructor() {}

    ngOnInit() {}
}
