import { Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { FormInputContorlDirective } from 'src/app/core/directives';
import { DisplayValidationErrorComponent } from '../../display-validation-error/display-validation-error.component';
import { schoolConfig } from 'src/app/core/config';
import { DateTimePipe } from 'src/app/core/pipes';
import { LabelComponent } from '../../label/label.component';

@Component({
  selector: 'sms-date-time',
  standalone: true,
  imports: [
    CommonModule,
    CalendarModule,
    ReactiveFormsModule,
    DisplayValidationErrorComponent,
    DateTimePipe,
    LabelComponent,
  ],
  templateUrl: './date-time.component.html',
  styleUrls: ['./date-time.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimeComponent),
      multi: true,
    },
  ],
})
export class DateTimeComponent<T> extends FormInputContorlDirective<T> {
  @Input() label?: string = '';
  @Input() value?: string;
  @Input({ required: false }) minDate!: Date;
  @Input({ required: false }) maxDate!: Date;
  @Input() dateFormat: string = schoolConfig.formats['date-input-format'];
  @Input() timeFormat: string = schoolConfig.formats['time-input-format'];
  @Input() selectionMode: 'multiple' | 'range' | 'single' = 'single';
  @Input() view: 'date' | 'month' | 'year' = 'date';
  @Input() disabled: boolean = false;
  @Input() showTime: boolean = false;
  @Input() showButtonBar: boolean = true;
  @Input() hideHeader: boolean = false;
  @Input() showOtherMonths: boolean = true;
  @Input() showSeconds: boolean = false;
  @Input() timeOnly: boolean = false;
}
