import { createFeature, createReducer, on } from "@ngrx/store";
import { StandardStoreInterface } from "src/app/shared/interfaces";
import { standardActions } from "./standard.action";

const initailState: StandardStoreInterface = {
    standards: [],
    standardsForFees: [],
};

const standardFeature = createFeature({
    name: "standard",
    reducer: createReducer(
        initailState,
        on(standardActions.setStandards, (state, action) => ({
            ...state,
            standards: action.data,
        })),
        on(standardActions.setStandardsForFees, (state, action) => ({
            ...state,
            standardsForFees: action.data,
        })),
        on(standardActions.create, (state) => ({ ...state })),
        on(standardActions.update, (state) => ({ ...state })),
    ),
});

export const {
    name: standardFeatureKey,
    reducer: standardReducer,
    selectStandards,
    selectStandardsForFees,
} = standardFeature;
