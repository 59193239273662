import { createFeature, createReducer, on } from "@ngrx/store";
import { AppEventStoreInerface } from "../../interfaces/other/app-event";
import { appEventActions } from "./app-event.action";

const initialState: AppEventStoreInerface = {
    isMutationLoading: false,
    isFullPageLoading: false,
    isSidebarOpen: false,
};

const appEventFeature = createFeature({
    name: "app-event",
    reducer: createReducer(
        initialState,
        on(appEventActions.startLoading, (state) => ({
            ...state,
            isMutationLoading: true,
        })),
        on(appEventActions.endLoading, (state) => ({
            ...state,
            isMutationLoading: false,
        })),
        on(appEventActions.startFullPageLoading, (state) => ({
            ...state,
            isFullPageLoading: true,
        })),
        on(appEventActions.endFullPageLoading, (state) => ({
            ...state,
            isFullPageLoading: false,
        })),
        on(appEventActions.addToast, (state) => ({
            ...state,
            isMutationLoading: false,
        })),
        on(appEventActions.toggleSidebar, (state) => ({
            ...state,
            isSidebarOpen: !state.isSidebarOpen,
        })),
        on(appEventActions.handleError, (state) => ({
            ...state,
            isMutationLoading: false,
        })),
    ),
});

export const {
    name: appEventFeatureKey,
    reducer: appEventReducer,
    selectIsMutationLoading,
    selectIsFullPageLoading,
    selectIsSidebarOpen,
} = appEventFeature;
