<ng-container *ngIf="showInstallPrompt">
    <ng-container *ngIf="{ isLoggedIn: isLoggedIn$ | async } as authVal">
        <button
            class="fixed shadow-2xl hover:bg-gray-300 right-5 cursor-pointer bg-gray-200 z-[1000000]"
            *ngIf="!visible"
            [ngClass]="{
                'bottom-28 min-[960px]:bottom-5': authVal.isLoggedIn?.isUser,
                'bottom-5': !authVal.isLoggedIn || authVal.isLoggedIn.isAdmin || !authVal.isLoggedIn.isAdmin,
            }"
            (click)="showPoup()"
            style="box-shadow: 5px 10px 30px #00000050"
            smsIconButton
        >
            <i class="ph-bold ph-download-simple text-gray-700"></i>
        </button>
    </ng-container>

    <p-sidebar
        [visible]="visible"
        [dismissible]="true"
        [showCloseIcon]="false"
        [position]="'bottom'"
        (visibleChange)="hide($event)"
    >
        <ng-container>
            <sms-heading>Install Application</sms-heading>
            <sms-alert class="mt-3 block"
                >Installing the app on iOS is quick and easy with just a few simple steps.</sms-alert
            >
            <div class="flex flex-col gap-2 py-3">
                <ul>
                    <li class="text-active mb-1 text-base">
                        Tap on <i class="ph-bold ph-export text-lg text-blue-600"></i>

                        icon.
                    </li>
                    <li class="text-active mb-1 text-base">
                        Scroll down and tap on
                        <span class="px-1 font-semibold">
                            Add to Home Screen
                            <i class="ph-bold ph-plus p-[2px] text-xs border rounded"></i>
                        </span>
                        button.
                    </li>
                    <li class="text-active text-base">
                        Tap on <span class="text-blue-600 font-semibold">Add</span> on top right corner.
                    </li>
                </ul>
            </div>
            <div class="mb-4 border border-dashed border-gray-200 w-full"></div>
            <div class="flex flex-col gap-4">
                <button (click)="hide(false)" smsButton color="primary" variant="outlined">Cancel</button>
            </div>
        </ng-container>
    </p-sidebar>
</ng-container>
