import { createFeature, createReducer, on } from "@ngrx/store";
import { AdmissionStoreInterface } from "../../interfaces";
import { admissionActions } from "./admission.action";

const initialState: AdmissionStoreInterface = {
    currentStep: 1,
    admissionIdsData: null,
    admissionData: null,
    partialAdmissions: [],
    interviewScheduledAdmissions: [],
    admissionFeesData: null,
    idCardData: null,
};

const admissionFeature = createFeature({
    name: "admission",
    reducer: createReducer(
        initialState,
        on(admissionActions.clearState, () => initialState),
        on(admissionActions.setCurrentStep, (state, action) => ({
            ...state,
            currentStep: action.stepNumber,
        })),
        on(admissionActions.nextStep, (state) => ({
            ...state,
            currentStep: state.currentStep + 1,
        })),
        on(admissionActions.previousStep, (state) => ({
            ...state,
            currentStep: state.currentStep - 1,
        })),
        on(admissionActions.saveAdmissionIdsData, (state, action) => {
            return {
                ...state,
                admissionIdsData: {
                    student: `${action.data?.student || state.admissionIdsData?.student}`,
                    admission: `${action.data?.admission || state.admissionIdsData?.admission}`,
                    form_number: `${action.data?.form_number || state.admissionIdsData?.form_number}`,
                    declaration_agreed: !!(
                        action.data?.declaration_agreed || state.admissionIdsData?.declaration_agreed
                    ),
                    currentStep: +(action.data?.currentStep || state.admissionIdsData?.currentStep || 1),
                },
            };
        }),
        on(admissionActions.setAdmissionData, (state, action) => ({
            ...state,
            admissionData: {
                ...state.admissionData,
                ...action.data,
            },
            admissionIdsData:
                action.stepNumber === 1
                    ? {
                          student: `${action.data?.student.id}`,
                          admission: `${action.data?.id}`,
                          form_number: `${action.data?.form_number}`,
                          declaration_agreed: !!action.data?.declaration_agreed,
                          currentStep: +action.stepNumber,
                      }
                    : state.admissionIdsData,
        })),
        on(admissionActions.setPartialAdmissions, (state, action) => ({
            ...state,
            partialAdmissions: action.data,
        })),
        on(admissionActions.setInterviewScheduledAdmissions, (state, action) => ({
            ...state,
            interviewScheduledAdmissions: action.data,
        })),
        on(admissionActions.setAdmissionFeesData, (state, action) => ({
            ...state,
            admissionFeesData: action.data,
        })),
        on(admissionActions.getAdmissionData, (state, action) => ({
            ...state,
            // currentStep: action.stepNumber,
        })),
        on(admissionActions.setIdCardData, (state, action) => ({
            ...state,
            idCardData: action.data,
        })),
    ),
});

export const {
    name: admissionFeatureKey,
    reducer: admissionReducer,
    selectCurrentStep,
    selectAdmissionIdsData,
    selectAdmissionData,
    selectPartialAdmissions,
    selectInterviewScheduledAdmissions,
    selectAdmissionFeesData,
    selectIdCardData,
} = admissionFeature;
