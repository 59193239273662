import { CommonModule } from "@angular/common";
import {
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Store } from "@ngrx/store";
import { DropdownModule } from "primeng/dropdown";
import { MultiSelectModule } from "primeng/multiselect";
import { ProgressBarModule } from "primeng/progressbar";
import { SliderModule } from "primeng/slider";
import { TableLazyLoadEvent, TableModule } from "primeng/table";
import { TagModule } from "primeng/tag";
import { Subscription } from "rxjs";
import { DateTimePipe } from "src/app/core/pipes";
import { FRONTEND_DATE_FORMAT } from "src/app/shared/constants";
import { datagridActions, selectDatagridState } from "src/app/shared/store";
import { SearchInputComponent } from "../inputs/search-input/search-input.component";
import { TextInputComponent } from "../inputs/text-input/text-input.component";
import { NoDataComponent } from "../no-data/no-data.component";

@Component({
    selector: "sms-data-grid",
    standalone: true,
    imports: [
        CommonModule,
        TableModule,
        MultiSelectModule,
        FormsModule,
        DropdownModule,
        TagModule,
        SliderModule,
        ProgressBarModule,
        SearchInputComponent,
        TextInputComponent,
        ReactiveFormsModule,
        DateTimePipe,
        NoDataComponent,
    ],
    templateUrl: "./data-grid.component.html",
    styleUrls: ["./data-grid.component.scss"],
})
export class DataGridComponent<T> implements OnInit, OnChanges, OnDestroy {
    selectedData: any[] = [];
    DATE_FROMAT = FRONTEND_DATE_FORMAT;
    loading: boolean = false;
    totalRecords: number = 0;
    first: number = 0;
    rows: number = 10;
    initial: boolean = true;
    log = console.log;

    @Input() value: T[] = [];
    @Input() dataKey = "_id";
    @Input() columns: any[] = [];
    @Input() bodyRef?: TemplateRef<any>;
    @Input() serverSide: boolean = false;
    @Input() url: string;
    @Input() scrollable: boolean = true;
    @Input() data: T[] = [];
    @Input() checkboxSelection = false;
    @Input() selectCondition: string;

    @ViewChild("pTable") pTable: any;

    subscription: Subscription = new Subscription();

    constructor(
        private store: Store,
        private cdRef: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        if (this.url && this.serverSide) {
            this.subscription.add(
                this.store.select(selectDatagridState).subscribe((value) => {
                    let tableData = value[this.getQuery()];

                    this.data = tableData ? tableData.DatagridData : [];
                    this.totalRecords = tableData ? tableData.DatagridDataCount : 0;
                    this.loading = false;
                    this.cdRef.detectChanges();
                }),
            );
        }
    }

    getQuery() {
        return JSON.stringify({
            url: this.url,
            params: {
                pg: this.first / this.rows || 0,
                limit: this.rows,
                q: this.pTable?.filters?.global?.value ? this.pTable?.filters?.global?.value : "",
            },
        });
    }

    onSearchInputChange(event: any) {
        const inputValue = (event.target as HTMLInputElement).value;
        this.pTable.filterGlobal(inputValue, "contains");
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes["url"]?.currentValue) {
            this.data = changes["data"]?.currentValue || [];
            this.totalRecords = changes["data"]?.currentValue?.length ?? 0;
        }

        if (changes["url"]?.currentValue) {
            if (changes["url"].currentValue !== changes["url"].previousValue && !this.initial) {
                this.pTable?.clearState();
                this.loadData();
            }
        }
    }

    loadData = (event?: TableLazyLoadEvent) => {
        if (this.initial) {
            this.initial = false;
        }
        if (!event) {
            event = {
                first: this.pTable?._first || 0,
                rows: this.pTable?._rows || 10,
                globalFilter: this.pTable?.filters?.global?.value,
            };
        }

        if (this.serverSide && this.url) {
            this.loading = true;

            this.first = event.first || 0;
            this.rows = event.rows || 10;
            this.store.dispatch(
                datagridActions.getTableData({
                    url: this.url,
                    params: {
                        pg: this.first / this.rows || 0,
                        limit: this.rows,
                        q: event.globalFilter ? event.globalFilter : "",
                    },
                }),
            );
        } else {
            this.data = this.value;
            this.totalRecords = this.data?.length ?? 0;
        }
    };

    isRowSelectable = (event: any) => {
        return this._isRowSelectable(event.data);
    };

    _isRowSelectable(row: any) {
        return this.selectCondition ? eval(`${this.selectCondition}`) : true;
    }

    resetSelection() {
        this.selectedData = [];
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }
}
