import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { studentAdmissionActions } from './student-admisison.action';
import { catchError, of, switchMap } from 'rxjs';
import { StudentAdmissionService } from '../../services';
import { appEventActions } from 'src/app/shared/store';
import { LocalStorageService } from 'src/app/shared/services';
import { CURRENT_USER_DATA } from 'src/app/shared/constants';
import { StudentDataInterface } from 'src/app/core/interfaces';
import { authActions } from 'src/app/core/stores';

@Injectable()
export class StudentAdmissionEffect {
  constructor(
    private actions$: Actions,
    private studentAdmissionService: StudentAdmissionService,
    private localStorageService: LocalStorageService
  ) {}

  fillIdCardForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(studentAdmissionActions.fillIdCardForm),
      switchMap((payload) => {
        return this.studentAdmissionService.fillIdCardForm(payload.data).pipe(
          switchMap(() => {
            let data =
              this.localStorageService.getItem<StudentDataInterface>(
                CURRENT_USER_DATA
              );
            if (data) {
              data.is_id_card_filled = true;
              this.localStorageService.setItem(CURRENT_USER_DATA, data);
            }
            return of(
              authActions.setIdCardFilled(),
              appEventActions.addToast({
                severity: 'success',
                detail: 'ID card form submitted successfully',
              }),
              !payload.noRedirect
                ? appEventActions.redirect({
                    navigate: ['/student/home'],
                  })
                : appEventActions.emptyAction()
            );
          }),
          catchError((err) => of(appEventActions.handleError(err)))
        );
      })
    )
  );

  updateIdCardForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(studentAdmissionActions.updateIdCardForm),
      switchMap((data) => {
        return this.studentAdmissionService
          .updateIdCardFrom(data.data, data.id)
          .pipe(
            switchMap(() => {
              let data =
                this.localStorageService.getItem<StudentDataInterface>(
                  CURRENT_USER_DATA
                );
              if (data) {
                data.is_id_card_filled = true;
                this.localStorageService.setItem(CURRENT_USER_DATA, data);
              }
              return of(
                authActions.setIdCardFilled(),
                appEventActions.addToast({
                  severity: 'success',
                  detail: 'ID card form submitted successfully',
                })
              );
            }),
            catchError((err) => of(appEventActions.handleError(err)))
          );
      })
    )
  );
}
