import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, type OnInit } from "@angular/core";
import { RouterLink } from "@angular/router";

@Component({
    selector: "sms-list-item",
    standalone: true,
    imports: [CommonModule, RouterLink],
    templateUrl: "./list-item.component.html",
    styleUrls: ["./list-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent implements OnInit {
    @Input() item: any;
    ngOnInit(): void {}
}
