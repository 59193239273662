import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { ApplicationConfig, enableProdMode, importProvidersFrom, isDevMode } from "@angular/core";
import { BrowserAnimationsModule, provideAnimations } from "@angular/platform-browser/animations";
import { provideRouter } from "@angular/router";
import { provideState, provideStore } from "@ngrx/store";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { environment } from "src/environments/environment";
import { AppRoutes, inMemoryScrollingFeature } from "./app.routing";

import { enquiryFeatureKey, enquiryReducer } from "./features/public/enquiry/store";
import { EnquiryEffects } from "./features/public/enquiry/store/enquiry.effect";

import { provideEffects } from "@ngrx/effects";
import { ApplyTokenInterceptor, MutationLoadingInterceptor } from "./core/interceptors";
import {
    AdmissionEffects,
    AppEventEffects,
    AttendanceEffects,
    BatchEffects,
    ClassroomEffects,
    DatagridEffects,
    FeesEffects,
    HomeworkEffects,
    PaymentEffects,
    StandardEffects,
    StudentEffects,
    SubjectEffects,
    TeacherEffects,
    UserEffects,
    admissionFeatureKey,
    admissionReducer,
    announcementFeatureKey,
    announcementReducer,
    appEventFeatureKey,
    appEventReducer,
    attendanceFeatureKey,
    attendanceReducer,
    batchFeatureKey,
    batchReducer,
    classroomFeatureKey,
    classroomReducer,
    datagridFeatureKey,
    datagridReducer,
    feesFeatureKey,
    feesReducer,
    homeworkFeatureKey,
    homeworkReducer,
    metaReducers,
    paymentFeatureKey,
    paymentReducer,
    standardFeatureKey,
    standardReducer,
    studentFeatureKey,
    studentReducer,
    teacherFeatureKey,
    teacherReducer,
    userFeatureKey,
    userReducer,
} from "./shared/store";

import { DatePipe } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { DialogService } from "primeng/dynamicdialog";
import {
    AdminAdmissionEffects,
    adminAdmissionFeatureKey,
    adminAdmissionReducer,
} from "./features/private/portals/admin/admission/store";
import {
    AcademicYearEffects,
    academicYearsFeatureKey,
    academicYearsReducer,
} from "./shared/store/settings/academic-year";
import { sectionFeatureKey, sectionReducer } from "./shared/store/settings/section/section.reducer";

import { provideServiceWorker } from "@angular/service-worker";
import { RippleModule } from "primeng/ripple";
import { AuthEffect, authFeatureKey, authReducer } from "./core/stores";
import {
    StudentAdmissionEffect,
    studentAdmissionFeatureKey,
    studentAdmissionReducer,
} from "./features/private/portals/student/store";
import { AnnouncementEffects } from "./shared/store/announcement/announcement.effect";
import { LovsEffects, lovsFeatureKey, lovsReducer } from "./shared/store/lovs";
import { SchoolConfigEffect, schoolConfigFeatureKey, schoolConfigReducer } from "./shared/store/school-config";
import {
    contentManagementEffects,
    contentManagementFeatureKey,
    contentManagementReducer,
} from "./shared/store/settings/content-management";
import { sectionEffects } from "./shared/store/settings/section/section.effect";

import { initializeApp, provideFirebaseApp } from "@angular/fire/app";
import { getAuth, provideAuth } from "@angular/fire/auth";
import { getFirestore, provideFirestore } from "@angular/fire/firestore";
import { getMessaging, provideMessaging } from "@angular/fire/messaging";

if (environment.production) {
    enableProdMode();
}

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(AppRoutes, inMemoryScrollingFeature),
        provideHttpClient(withInterceptorsFromDi()),
        importProvidersFrom([BrowserAnimationsModule, ToastModule, BrowserModule]),
        //firebase for push notifications
        provideFirebaseApp(() => initializeApp(environment.FIREBASE_CONFIG)),
        provideFirestore(() => getFirestore()),
        provideAuth(() => getAuth()),
        provideMessaging(() => getMessaging()),

        // other providers
        MessageService,
        DialogService,
        DatePipe,
        RippleModule,
        // store providers
        provideStore(undefined, { metaReducers }),
        // auth reducers
        provideState(authFeatureKey, authReducer),
        // others reducers
        provideState(appEventFeatureKey, appEventReducer),
        // school config reducers
        provideState(schoolConfigFeatureKey, schoolConfigReducer),
        // classroom reducers
        provideState(classroomFeatureKey, classroomReducer),
        // homework reducers
        provideState(homeworkFeatureKey, homeworkReducer),
        // attendance reducers
        provideState(attendanceFeatureKey, attendanceReducer),
        // teacher reducers
        provideState(teacherFeatureKey, teacherReducer),
        // announcement reducers
        provideState(announcementFeatureKey, announcementReducer),
        // enquiry reducers
        provideState(enquiryFeatureKey, enquiryReducer),
        // admission reducers
        provideState(admissionFeatureKey, admissionReducer),
        provideState(adminAdmissionFeatureKey, adminAdmissionReducer),
        // student reducers
        provideState(studentFeatureKey, studentReducer),
        provideState(studentAdmissionFeatureKey, studentAdmissionReducer),
        // settings reducers
        provideState(academicYearsFeatureKey, academicYearsReducer),
        provideState(lovsFeatureKey, lovsReducer),
        provideState(sectionFeatureKey, sectionReducer),
        provideState(standardFeatureKey, standardReducer),
        provideState(feesFeatureKey, feesReducer),
        provideState(batchFeatureKey, batchReducer),
        provideState(userFeatureKey, userReducer),
        provideState(contentManagementFeatureKey, contentManagementReducer),
        // //datagrid reducers
        provideState(datagridFeatureKey, datagridReducer),
        // payment reducers
        provideState(paymentFeatureKey, paymentReducer),
        // provide Effects
        provideEffects(
            // auth effects
            AuthEffect,
            // other effects
            AppEventEffects,
            // school config effects
            SchoolConfigEffect,
            // classroom effects
            ClassroomEffects,
            // homework effects
            HomeworkEffects,
            // attendance effects
            AttendanceEffects,
            // teacher effects
            TeacherEffects,
            // announcement effects
            AnnouncementEffects,
            // payment Effects
            PaymentEffects,
            // enquiry effects
            EnquiryEffects,
            // admission effects
            AdmissionEffects,
            AdminAdmissionEffects,
            // student effects
            StudentEffects,
            StudentAdmissionEffect,
            // settings effects
            LovsEffects,
            AcademicYearEffects,
            sectionEffects,
            StandardEffects,
            BatchEffects,
            FeesEffects,
            UserEffects,
            contentManagementEffects,
            SubjectEffects,
            //datagrid effects
            DatagridEffects,
        ),
        provideStoreDevtools({
            maxAge: 25,
            logOnly: !isDevMode(),
            autoPause: true,
            trace: false,
            traceLimit: 75,
        }),
        // interceptors
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MutationLoadingInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApplyTokenInterceptor,
            multi: true,
        },
        provideAnimations(),
        provideServiceWorker("ngsw-worker.js", {
            enabled: !isDevMode(),
            registrationStrategy: "registerWhenStable:30000",
        }),
    ],
};
