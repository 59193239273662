import { createFeature, createReducer, on } from '@ngrx/store';
import { PaymentStoreInterface } from '../../interfaces';
import { paymentActions } from './payment.action';

const initailState: PaymentStoreInterface = {
  selectedPaymentData: null,
  orderData: null,
};

const paymentFeature = createFeature({
  name: 'payment',
  reducer: createReducer(
    initailState,
    on(paymentActions.setOrderData, (state, action) => ({
      ...state,
      orderData: action.data,
    })),
    on(paymentActions.openPaymentBox, (state, action) => ({
      ...state,
      selectedPaymentData: action.data,
    })),
    on(paymentActions.closePaymentBox, (state) => ({
      ...state,
      selectedPaymentData: null,
      orderData: null,
    }))
  ),
});

export const {
  name: paymentFeatureKey,
  reducer: paymentReducer,
  selectSelectedPaymentData,
  selectOrderData,
} = paymentFeature;
