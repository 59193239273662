import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { SidebarModule } from "primeng/sidebar";
import { ButtonDirective, IconButtonDirective } from "src/app/core/directives";
import { selectLoggedIn } from "src/app/core/stores";
import { PwaService } from "src/app/shared/services";
import { AlertComponent } from "../../ui/alert/alert.component";
import { HeadingComponent } from "../../ui/heading/heading.component";

@Component({
    selector: "sms-pwa-instructions",
    standalone: true,
    imports: [CommonModule, SidebarModule, HeadingComponent, IconButtonDirective, ButtonDirective, AlertComponent],
    templateUrl: "./pwa-instructions.component.html",
    styleUrls: ["./pwa-instructions.component.scss"],
})
export class PwaInstructionsComponent implements OnInit {
    @Input() isLoggedIn: boolean;
    visible = false;
    showInstallPrompt = false;

    isLoggedIn$ = this.store.select(selectLoggedIn);
    constructor(
        private pwaService: PwaService,
        private store: Store,
    ) {}

    ngOnInit(): void {
        if (this.pwaService.isIos() && !this.pwaService.isInStandaloneMode()) {
            this.showInstallPrompt = true;
        }
    }
    showPoup() {
        this.visible = true;
    }

    hide(value: boolean) {
        if (!value) this.visible = false;
    }
}
