import { Directive, ElementRef, HostBinding, Input, Renderer2 } from "@angular/core";

const variantClassObject: Record<string, string> = {
    info: "text-info bg-info-light",
    warn: "text-warning bg-warning-light",
    success: "text-success bg-success-light",
    error: "text-error bg-error-light",
    prompt: "text-purple-500 bg-purple-50",
    muted: "text-gray-500 bg-gray-50",
};

const sizeClassObject: Record<string, string> = {
    medium: "px-[0.41rem] py-[0.1rem] text-sm",
    small: "px-[0.31rem] py-[1px] text-xs small-chip",
};

const baseClasses = " w-min rounded text-center flex justify-start items-center gap-1";

function getClasses(variant: string, hideBackground: boolean, size: string) {
    let classess = `${baseClasses} ${
        variantClassObject[variant] ? variantClassObject[variant] : variantClassObject["other"]
    }`;

    if (hideBackground) {
        classess += " bg-transparent";
    }

    classess += getSizeClassess(size);
    return classess;
}

function getSizeClassess(size: string) {
    return `${baseClasses} ${sizeClassObject[size] ? sizeClassObject[size] : sizeClassObject["other"]}`;
}
@Directive({
    selector: "[smsChip]",
    standalone: true,
})
export class ChipDirective {
    _variant: string = "Other";
    _hideBackground: boolean = false;
    _size: string = "medium";

    @HostBinding("class")
    classes = getClasses("Other", false, "medium");

    @Input()
    set variant(value: string) {
        this._variant = value;
        this.classes = getClasses(value, this._hideBackground, this._size);
    }

    @Input()
    set hideBackground(value: boolean) {
        this._hideBackground = value;
        this.classes = getClasses(this._variant, value, this._size);
    }

    @Input()
    set size(value: string) {
        this._size = value;
        this.classes = getClasses(this._variant, this._hideBackground, value);
    }

    constructor(
        private elementRef: ElementRef,
        private renderer: Renderer2,
    ) {}

    ngAfterViewInit() {
        this.renderer.addClass(this.elementRef.nativeElement, "chip-dot");
    }
}
