<ng-container>
  <sms-payment-warpper [orderData]="orderData" [redirect]="router.url + '?paymentSuccess=true'">
    <div class="grid sm:grid-cols-1 w-full gap-6">
      <div class="flex flex-col gap-3">
        <sms-heading>PAYMENT BREAKUP</sms-heading>
        <sms-alert>
          This is a breakdown of fees according to class for which you are
          taking admission
        </sms-alert>

        <div class="flex flex-col items-start self-stretch gap-2 px-2">
          <div class="flex justify-between items-center w-full">
            <p class="m-0 font-satoshi text-sm text-gray-800">
              SECURITY DEPOSIT (REFUNDABLE)
            </p>
            <p class="font-satoshi m-0 text-sm text-end text-gray-900 font-bold">
              &#8377; {{ admissionFeesData?.deposit_amount }}
            </p>
          </div>

          <div class="flex justify-between items-center w-full border-t border-0 border-dashed border-gray-200 pt-1">
            <p class="m-0 font-satoshi text-sm text-gray-800">Total Fees</p>
            <p class="font-satoshi m-0 text-sm text-end text-gray-900 font-bold">
              &#8377;
              {{
              admissionFeesData
              ? admissionFeesData!.total_fees +
              admissionFeesData!.deposit_amount
              : "-"
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-3">
        <sms-heading>INSTALLMENT BREAKUP</sms-heading>
        <sms-alert> Fees can be paid at once or in installment </sms-alert>
        <div class="flex flex-col items-start self-stretch gap-2 px-2">
          <div class="flex justify-between items-start w-full" *ngFor="let item of admissionFeesData?.instalments">
            <div>
              <p class="m-0 font-satoshi text-sm text-gray-800">
                {{ item.instalment_no | ordinalSuffix }} Instalment
                {{ item.instalment_no === 1 ? " + Security Deposit" : "" }}
              </p>
              <span class="text-primary text-xs font-satoshi">({{
                item.is_first_instalment
                ? "Pay after admission"
                : "Before " + getFormatedDate(item)
                }})</span>
            </div>
            <p class="font-satoshi m-0 text-sm text-end text-gray-900 font-bold">
              &#8377;
              {{
              item.instalment_no === 1
              ? item.instalment_amount +
              " + &#8377; " +
              admissionFeesData?.deposit_amount
              : item.instalment_amount
              }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex flex-col gap-3">
        <sms-heading>IMPORTANT NOTES</sms-heading>

        <!-- <ul class="px-5 my-0">
            <li class="text-sm font-satoshi text-gray-800 mb-2">
              Amount Of Books And Dress Will Be Updated On Particular Day.
            </li>
            <li class="text-sm font-satoshi text-gray-800 mb-2">
              Deposit Cheque Should Be Given Within 2 Month From The Date Of
              Admission On A/C Of Global Educational & Welfare Society.
            </li>
            <li class="text-sm font-satoshi text-gray-800 mb-2">
              Books And Dress Amount To Be Given To Vendor Respectively.
            </li>
            <li class="text-sm font-satoshi text-gray-800 mb-2">
              Absent On First Of School Or On Important Event Fine Amount Of Rs.
              200/- Will Be Applicable.
            </li>
          </ul> -->
        <quill-view-html *ngIf="contentData" [content]="contentData" theme="snow"></quill-view-html>


      </div>

      <sms-alert variant="warning" *ngIf="admissionFeesData?.warning">
        {{ admissionFeesData?.warning }}
      </sms-alert>

      <div class="flex flex-col gap-4 items-start self-stretch my-4">
        <button smsButton color="primary" type="button" (click)="onSave()" [loading]="isLoading$ | async">
          Submit & Pay
        </button>
        <button smsButton color="primary" variant="outlined" type="button" (click)="onBack()">
          Back
        </button>
        <button type="submit" #submitButton class="w-0 h-0 opacity-0"></button>
      </div>
    </div>
  </sms-payment-warpper>
</ng-container>