import { CommonModule } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { ToastModule } from "primeng/toast";
import { IconButtonDirective } from "src/app/core/directives";
import { selectLoggedIn } from "src/app/core/stores";
import { PushNotificationService, PwaService } from "../../services";
import { PwaInstructionsComponent } from "./pwa-instructions/pwa-instructions.component";

@Component({

    selector: "sms-app-event",
    standalone: true,
    imports: [CommonModule, ToastModule, IconButtonDirective, PwaInstructionsComponent],
    templateUrl: "./app-event.component.html",
    styleUrls: ["./app-event.component.scss"],
})
export class AppEventComponent implements OnInit {
    summaryObject: Record<string, string> = {
        error: "Error",
        warn: "Warning",
        success: "Success",
        info: "Info",
    };

    iconsObject: Record<string, string> = {
        error: "ph-x-circle",
        warn: "ph-warning",
        success: "ph-check-circle",
        info: "ph-info",
    };

    deferredPrompt: any;
    showInstallButton = false;

    isLoggedIn$ = this.store.select(selectLoggedIn);

    constructor(
        private pwaService: PwaService,
        private store: Store,
        private pushNotificationService: PushNotificationService
    ) {
        this.pwaService.handleDynamicManifest();
        this.pwaService.setUpPWA();
        this.pushNotificationService.register()

        window.addEventListener("beforeinstallprompt", (event) => {
            // Prevent the default behavior
            event.preventDefault();
            // Save the event for later use
            this.deferredPrompt = event;
            // Show the install button
            this.showInstallButton = true;
        });


    }

    ngOnInit(): void {
        this.pwaService.checkUpdate();
    }

    installPWA() {
        if (this.deferredPrompt) {
            // Show the install prompt
            this.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            this.deferredPrompt.userChoice.then((choiceResult: any) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("User accepted the A2HS prompt");
                } else {
                    console.log("User dismissed the A2HS prompt");
                }
                // Clear the deferredPrompt variable since it can't be used again
                this.deferredPrompt = null;
            });
        }
    }



}
