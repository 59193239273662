import { FormControl, ValidatorFn } from '@angular/forms';

export class FileValidator {
  static fileMaxSize(maxSize: number, inKb: boolean = false): ValidatorFn {
    const validatorFn = (file: File) => {
      let fileSizeMB = inKb ? file.size / 1024 : file.size / 1024 ** 2;
      if (file instanceof File && fileSizeMB > maxSize) {
        return {
          fileMaxSize: {
            requiredSize: `${maxSize}${inKb ? 'KB' : 'MB'}`,
            actualSize: `${fileSizeMB}${inKb ? 'KB' : 'MB'}`,
            inKb,
            file,
          },
        };
      }
      return null;
    };
    return FileValidator.fileValidation(validatorFn);
  }

  static fileMinSize(minSize: number, inKb: boolean = false): ValidatorFn {
    const validatorFn = (file: File) => {
      let fileSizeMB = inKb ? file.size / 1024 : file.size / 1024 ** 2;
      if (file instanceof File && fileSizeMB < minSize) {
        return {
          fileMinSize: {
            requiredSize: `${minSize}${inKb ? 'KB' : 'MB'}`,
            actualSize: `${fileSizeMB}${inKb ? 'KB' : 'MB'}`,
            inKb,
            file,
          },
        };
      }
      return null;
    };
    return FileValidator.fileValidation(validatorFn);
  }

  /**
   * extensions must not contain dot
   */
  static fileExtensions(allowedExtensions: Array<string>): ValidatorFn {
    const validatorFn = (file: File) => {
      if (allowedExtensions.length === 0) {
        return null;
      }

      if (file instanceof File) {
        const ext = FileValidator.getExtension(file.name);
        if (ext && allowedExtensions.indexOf(ext) === -1) {
          return {
            fileExtension: {
              allowedExtensions: allowedExtensions,
              actualExtension: file.type,
              file,
            },
          };
        }
      }
      return null;
    };
    return FileValidator.fileValidation(validatorFn);
  }

  private static getExtension(filename: string): null | string {
    if (filename.indexOf('.') === -1) {
      return null;
    }
    return `${filename.split('.').pop()}`;
  }

  private static fileValidation(
    validatorFn: (File: File) => null | object
  ): any {
    return (formControl: FormControl) => {
      if (!formControl.value) {
        return null;
      }

      const files: File[] = [];
      const isMultiple = Array.isArray(formControl.value);
      isMultiple
        ? formControl.value.forEach((file: File) => files.push(file))
        : files.push(formControl.value);

      for (const file of files) {
        return validatorFn(file);
      }

      return null;
    };
  }
}
