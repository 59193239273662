import { CommonModule } from "@angular/common";
import { Component, EventEmitter, Input, Output, type OnInit } from "@angular/core";
import { LabelComponent } from "../../label/label.component";

@Component({
    selector: "sms-switch",
    standalone: true,
    imports: [CommonModule, LabelComponent],
    templateUrl: "./switch.component.html",
    styleUrls: ["./switch.component.scss"],
})
export class SwitchComponent implements OnInit {
    @Input() checked: boolean = false;
    @Input() label: string;

    @Output() onChange: EventEmitter<boolean> = new EventEmitter();

    ngOnInit(): void {}

    onValueChange(e: any) {
        this.checked = e.target.checked;
        this.onChange.emit(this.checked);
    }
}
