import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { USER_TOKEN } from "src/app/shared/constants";
import { LocalStorageService } from "src/app/shared/services";
import { StudentDataInterface } from "../interfaces";

@Injectable()
export class ApplyTokenInterceptor implements HttpInterceptor {
    constructor(private localStorageService: LocalStorageService) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        const token = this.localStorageService.getItem<StudentDataInterface>(USER_TOKEN);

        if (token)
            request = request.clone({
                setHeaders: {
                    Authorization: `${token}`,
                },
            });

        return next.handle(request);
    }
}
