import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  setItem<T = any>(name: string, data: T) {
    localStorage.setItem(name, JSON.stringify(data));
  }

  getItem<T = any>(name: string, parsed = true): T | any | null {
    try {
      if (parsed) {
        return JSON.parse(localStorage.getItem(name)!);
      }
      return localStorage.getItem(name);
    } catch (error) {
      throw error;
    }
  }

  removeItem(name: string) {
    return localStorage.removeItem(name);
  }

  clear() {
    localStorage.clear();
  }
}
