import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BatchService } from '../../../services';
import { Injectable } from '@angular/core';
import { batchActions } from './batch.action';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { appEventActions } from '../../app-event';

@Injectable()
export class BatchEffects {
  constructor(private actions$: Actions, private batchService: BatchService) {}

  getBatchs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(batchActions.loadBatches),
      mergeMap(() =>
        this.batchService
          .getAllBatches()
          .pipe(map((data) => batchActions.setBatches({ data })))
      )
    )
  );

  getAdmissionBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(batchActions.getAdmissionBatch),
      switchMap((data) =>
        this.batchService.getAdmissionBatch(data.admissionId).pipe(
          map((data) => {
            return batchActions.setAdmissionBatch({ data });
          })
        )
      )
    )
  );

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(batchActions.create),
      switchMap((data) =>
        this.batchService.create(data.data).pipe(
          switchMap(() => {
            data.onClose(true);
            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Batch created successfully.',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(batchActions.update),
      switchMap((data) =>
        this.batchService.update(data.data, data.id).pipe(
          switchMap(() => {
            data.onClose(true);
            return of(
              appEventActions.addToast({
                severity: 'success',
                detail: 'Batch updated successfully.',
              })
            );
          }),
          catchError((err) => {
            return of(appEventActions.handleError(err));
          })
        )
      )
    );
  });
}
