import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {
  ChangeAdmissionStatus,
  PostScheduleInterviewInterface,
} from '../interfaces';
import { environment } from 'src/environments/environment';
import { EnquiryInterface } from 'src/app/shared/interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SaveAdminAdmissionService {
  constructor(protected httpClient: HttpClient) {}

  scheduleInterview(data: PostScheduleInterviewInterface) {
    return this.httpClient.post(
      `${environment.API_URL}/admission-interview/`,
      data
    );
  }

  scheduleBulkInterview(data: PostScheduleInterviewInterface) {
    return this.httpClient.post(
      `${environment.API_URL}/bulk-admission-interview/`,
      data
    );
  }

  changeAdmissionStatus(data: ChangeAdmissionStatus) {
    return this.httpClient.post(
      `${environment.API_URL}/admission-status/`,
      data
    );
  }

  updateEnquiry(
    data: EnquiryInterface,
    id: string
  ): Observable<EnquiryInterface> {
    return this.httpClient.put<EnquiryInterface>(
      environment.API_URL + `/enquiries/${id}/`,
      data
    );
  }
}
