import { createActionGroup, emptyProps, props } from "@ngrx/store";
import {
    DataGridDataKey,
    ResponseInterface,
    StudentBonafideInterface,
    StudentImportResponseInterface,
    StudentInterface,
    StudentLcInterface,
    StudentLeavingCertificateInterface,
    onClose,
} from "src/app/shared/interfaces";

export const studentActions = createActionGroup({
    source: "student",
    events: {
        "get student data": props<{ data: { student_id: string } }>(),
        "set student data": props<{ data: StudentInterface | any }>(),
        "load students": emptyProps(),
        "dectivate student": props<{ data: { student_id: string; datagridParams: DataGridDataKey } }>(),
        "set students": props<{ data: ResponseInterface<StudentInterface[]> }>(),
        "get student lc data": props<{ data: { student_id: string } }>(),
        "set student lc data": props<{ data: StudentLcInterface | null }>(),
        "download lc": props<{
            data: StudentLeavingCertificateInterface;
            onClose: onClose;
        }>(),
        "download bonafide": props<{
            data: StudentBonafideInterface;
            onClose: onClose;
        }>(),
        "upload students excel": props<{ data: FormData; onClose: onClose }>(),
        "on upload students success": props<{ data: StudentImportResponseInterface[] }>(),
        "download sample excel": emptyProps(),
        "set step count": props<{ count: number }>(),
        "remove student of import": props<{ data: string[] }>(),
        "upload student data": props<{ data: StudentInterface[] }>(),
        "update student": props<{ data: any }>(),
        "clear bulk upload state": emptyProps(),
    },
});
