import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HomeworkInterface } from "../../interfaces";

@Injectable({
    providedIn: "root",
})
export class HomeworkService {
    constructor(private httpClient: HttpClient) {}

    create(data: FormData): Observable<{ data: HomeworkInterface }> {
        return this.httpClient.post<{ data: HomeworkInterface }>(environment.API_URL + "/app/homeworks/create/", data);
    }

    update(data: FormData, id: string): Observable<{ data: HomeworkInterface }> {
        return this.httpClient.put<{ data: HomeworkInterface }>(environment.API_URL + `/app/homeworks/${id}/`, data);
    }

    submitAssignment(data: FormData): Observable<any> {
        return this.httpClient.post<any>(environment.API_URL + `/app/assignment/`, data);
    }

    getHomework(id: string): Observable<HomeworkInterface> {
        return this.httpClient.get<HomeworkInterface>(environment.API_URL + `/app/homeworks/${id}/`);
    }
}
