import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { schoolConfig } from 'src/app/core/config';
import { EnvPipe } from 'src/app/core/pipes';

@Component({
  selector: 'sms-payment-warpper',
  templateUrl: './payment-warpper.component.html',
  styleUrls: ['./payment-warpper.component.scss'],
  standalone: true,
  imports: [CommonModule, EnvPipe],
})
export class PaymentWarpperComponent implements OnInit {
  schoolConfig = schoolConfig;
  @Input() orderData: any = null;
  @Input() redirect: string;

  constructor() {}

  ngOnInit() {}
}
